import {Permissions} from '../../auth/models/User';
import permissionService from '../../auth/services/permissions.service';
import userStoreService from '../../auth/services/user-store.service';
import DashboardScreen from '../../dashboard/screens/DashboardScreen';

const routes = [
  {
    path: '/dashboard',
    component: DashboardScreen,
    redirect: '/',
    authCheck: () =>
      userStoreService.getUser() !== null &&
      permissionService.checkUserPermission(Permissions.company_dashboard_read),
  },
];

export default routes;
