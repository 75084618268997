class StoreService {
  put(key: string, value: any) {
    const data = typeof value === 'string' ? value : JSON.stringify(value);
    localStorage.setItem(key, data);
  }

  get(key: string) {
    const item = localStorage.getItem(key);
    return item && item.match(/^({|\[).*(}|\])$/) ? JSON.parse(item) : item;
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }
}

const storeService = new StoreService();
export default storeService;
