import {Activity} from './Activity';

export class SoundTherapy extends Activity {
  // id?: string;
  // name?: string;
  // description?: string;
  // type?: string;
  // image?: string;
  isHeadphoneNeeded = true;
  link?: string;
  file_path!: string;
  video_path!: string;
  feature_category_id?: string;
  duration_seconds!: number;
  has_voice!: boolean;
  is_fullscreen_background!: boolean;

  constructor(props: any) {
    super(props);
    Object.assign(this, props);
  }

  // getLink() {
  //   return `/app/sound-therapy/${this.id}`;
  // }
}

export class TherapyFilter {
  constructor(public name: string) {}
}
