import React, {useCallback, useEffect, useState} from 'react';
import {Challenge, ChallengeFilter, ChallengeForm, ChallengeType,} from '../../models/Challenge';
import {Department} from '../../../company/models/Department';
import {ChallengeGoal, ChallengeGoalFilter} from '../../models/ChallengeGoal';
import {ChallengeReward, ChallengeRewardFilter,} from '../../models/ChallengeReward';
import companyService from '../../../company/services/company.service';
import challengeService from '../../services/challenge.service';
import LoadingComponent from '../../../common/components/LoadingComponent/LoadingComponent';
import timeService from '../../../common/services/time.service';
import textService from '../../../common/services/text.service';
import {ChallengeRewardCondition, ChallengeRewardConditionFilter,} from '../../models/ChallengeRewardCondition';
import translationService from '../../../translation/services/translation.service';
import {TranslationCategories} from '../../../common/models/TranslationCategories';
import permissionService from '../../../auth/services/permissions.service';
import {Permissions} from '../../../auth/models/User';
import ButtonComponent from '../../../common/components/ButtonComponent';
import AuthInputField from '../../../auth/components/AuthInputField';
import SettingsIcon from '../../../../assets/iconset/settings-icon.svg';
import ClipboardIcon from '../../../../assets/iconset/clipboard-icon.svg';
import {ReactComponent as EditIcon} from '../../../../assets/iconset/edit-icon.svg';
import {ReactComponent as DeleteIcon} from '../../../../assets/iconset/delete-icon.svg';
import CommodeIcon from '../../../..//assets/iconset/commode-icon.svg';
import DropdownComponent from '../../../common/components/DropdownComponent';
import DatePicker from 'react-date-picker';
import TextAreaComponent from '../../../common/components/TextAreaComponent';
import ConfirmComponent from "../../../common/components/ConfirmComponent/ConfirmComponent";

const CreateChallengeFormComponent = () => {
  const [id, setId] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingChallenges, setLoadingChallenges] = useState<boolean>(true);
  const [confirmData, setConfirmData] = useState<{header: string, description: string, onConfirm: (confirm: boolean) => any}>();
  const initializedForm : ChallengeForm = {
    company_id: '',
    department_ids: [],
    challenge_reward_condition_id: '',
    challenge_goal_id: '',
    challenge_reward_id: '',
    name: '',
    description: '',
    type: '',
    is_template: false,
    duration: 0
  };
  const [form, setForm] = useState<ChallengeForm>(initializedForm);
  const [departments, setDepartments] = useState<Department[]>();
  const [goals, setGoals] = useState<ChallengeGoal[]>([]);
  const [rewards, setRewards] = useState<ChallengeReward[]>([]);
  const [conditions, setConditions] = useState<ChallengeRewardCondition[]>([]);
  const [challenges, setChallenges] = useState<Challenge[]>([]);
  const [errors, setErrors] = useState<{
    name: string;
    description: string;
    image: string;
    type: string;
    departments: string;
    goal: string;
    reward: string;
    rewardCondition: string;
    startDate: string;
    endDate: string;
    duration: string;
  }>();
  const canUpdate = permissionService.checkUserPermission(
    Permissions.company_challenge_update,
  );
  const typeList = Object.entries(ChallengeType).map((type) => {
    return{label: type[0], value: type[1]}
  });
  const goalList = goals.map(goal => {
    return{label: goal.getName(), value: goal.id}
  });
  const rewardList = rewards.map(reward => {
    return{label: reward.name, value: reward.id}
  });
  const rewardConditionList = conditions.map(condition => {
    return{label: condition.getName(), value: condition.id}
  });


  const loadDepartments = useCallback(() => {
    companyService.getDepartments().then(deps => {
      setDepartments(deps);
    });
  }, []);

  const loadGoals = useCallback(() => {
    const goalsFilter = new ChallengeGoalFilter();
    challengeService.getGoals(goalsFilter).then(setGoals);
  }, []);

  const loadRewards = useCallback(() => {
    const rewardsFilter = new ChallengeRewardFilter();
    challengeService.getRewards(rewardsFilter).then(setRewards);
  }, []);

  const loadChallenges = useCallback(() => {
    setLoadingChallenges(true);
    const challengeFilter = new ChallengeFilter({
      ended_at: new Date().toISOString(),
      is_successfully_completed: false,
    });
    challengeService.getChallenges(challengeFilter).then((res: Challenge[]) => {
      const loadedChallenges = res.filter(x => {
        const endDate = new Date(x.ended_at).toISOString();
        return endDate >= new Date().toISOString() && x.is_successfully_completed === false;
      });
      setChallenges(loadedChallenges);
    }).finally(() => {
      setLoadingChallenges(false);
    });
  }, []);
  const loadRewardConditions = useCallback(() => {
    const conditionFilter = new ChallengeRewardConditionFilter();
    challengeService.getRewardConditions(conditionFilter).then(setConditions);
  }, []);

  const loadData = useCallback(() => {
    loadDepartments();
    loadGoals();
    loadRewards();
    loadChallenges();
    loadRewardConditions();
  }, [
    loadGoals,
    loadDepartments,
    loadRewards,
    loadChallenges,
    loadRewardConditions
  ]);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (id) {
      setLoading(true);
      challengeService.getChallenge(id).then(challenge => {
        setErrors(undefined);
        challenge.is_template = false;
        setForm(new ChallengeForm(challenge));
        setLoading(false);
      });
    }
  }, [id]);

  const changeValue = (callback: (form: ChallengeForm) => void) => {
    const f = new ChallengeForm(form);
    callback(f);
    if (f.started_at && f.ended_at) {
      f.duration = timeService.getDiffDays(f.started_at, f.ended_at);
    }
    setForm(f);
  };

  const changeFormValue = (field: string, event: any) => {
    const value =
      field === 'started_at' || field ==='ended_at' ? event
      : field === 'image' ? event.target.files[0]
      : event.target.value;

    setForm({
      ...form,
      [field]: value,
    });
  };

  const saveChallenge = () => {
    setLoading(true);
    const validation = challengeService.validateChallengeForm(form);
    console.log('Form', form, 'Validation', validation);

    if (validation.isValid) {
      (id
        ? challengeService.updateChallenge(id, form)
        : challengeService.createChallenge(form)
      )
        .then(() => {
          setId(undefined);
          setErrors(undefined);
          setForm(new ChallengeForm(initializedForm));
          loadData();
        })
        .catch(error => {
          setErrors(error);
          console.log('Could not save condition', id, form, error);
        }).finally( () => setLoading(false));
    } else {
      setErrors(validation.errors);
    }
  };

  const deleteChallenge = (id: string) => {
    setConfirmData({
      header: translationService.translate(TranslationCategories.Challenges, "Delete challenge"),
      description: translationService.translate(TranslationCategories.Challenges, "Are you sure you want to delete challenge?"),
      onConfirm: (confirm: boolean) => {
        if (confirm) {
          challengeService.deleteChallenge(id).then(() => {
            loadData();
          }).finally(() => {
            setConfirmData(undefined);
          });
        } else {
          setConfirmData(undefined);
        }
      }
    })
  };

  return (loading ? (
      <div className='h-screen w-full flex justify-center'>
        <LoadingComponent text={''} fullscreen={false} smooth={true} />
      </div>
    ) : (
      <div className='w-full flex flex-col items-center gap-8 md:flex-row md:items-start md:gap-16'>
        <div className={!canUpdate ? 'hidden' : 'w-full max-w-[642px] bg-white rounded-2xl p-3 md:p-7 shadow-xl'}>
          {!id ? (
            <p className='text-2xl font-semibold pb-3'>
              {translationService.translate(
                TranslationCategories.Challenges,
                'Create new Challenge',
              )}
            </p>
          ) : (
            <p className='text-2xl font-semibold pb-3'>
              {translationService.translate(
                TranslationCategories.Challenges,
                'Update Challenge',
              )}
            </p>
          )}
          <p className='text-sm font-semibold pb-2'>
            {translationService.translate(
              TranslationCategories.Challenges,
              'Challenge name',
            )}
          </p>
          <AuthInputField
            icon={SettingsIcon}
            textInput={translationService.translate(
              TranslationCategories.Challenges,
              'Challenge name',
            )}
            textContentType={'text'}
            error={errors?.name}
            value={form.name}
            onChange={value => changeFormValue('name', value)}
          />
          <p className='text-sm font-semibold'>
            {translationService.translate(
              TranslationCategories.Challenges,
              'Challenge description',
            )}
          </p>
          <TextAreaComponent
            icon={ClipboardIcon}
            textInput={translationService.translate(
              TranslationCategories.Challenges,
              'Description',
            )}
            error={errors?.description}
            value={form.description}
            onChange={value => changeFormValue('description', value)}
          />
          <div className='w-full pb-3'>
            <p className='text-sm font-semibold pb-2'>
              {translationService.translate(
                TranslationCategories.Challenges,
                'Add a picture',
              )}
            </p>
            <div className='w-full h-[100px] md:h-[150px] flex items-center justify-center bg-gainsboro-100 rounded-xl'>
              <label className="block">
                <input
                  data-testid='input-image'
                  onChange={(value: any) => changeFormValue('image', value)}
                  type='file'
                  className='block w-full text-sm text-slate-500
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-full file:border-0
                    file:text-sm file:font-semibold
                    file:bg-white file:text-deepGreen-50
                    hover:file:bg-deepGreen-50
                    hover:file:text-white'
                />
              </label>
              {errors?.image && (
                <p className='text-sm text-error'>
                  {translationService.translate(
                    TranslationCategories.Errors,
                    errors?.image,
                  )}
                </p>
              )}
            </div>
          </div>
          <DropdownComponent
            options={typeList}
            defaultOption={translationService.translate(
              TranslationCategories.Challenges,
              'Select a type of challenge',
            )}
            onSelect={value => changeFormValue('type', value)}
            selectTitle={translationService.translate(
              TranslationCategories.Challenges,
              'Type of challenge',
            )}
            icon={CommodeIcon}
            error={errors?.type && translationService.translate(
              TranslationCategories.Errors,
              errors?.type,
            )}
          />
          {form.type === ChallengeType.Department && (
            <div className='pb-3'>
              <p className='text-sm font-semibold pb-2'>
                {translationService.translate(
                  TranslationCategories.Errors,
                  'Select Department',
                )}
              </p>
              <select
                className="form-input select"
                onChange={e =>
                  changeValue(f => {
                    const ids = [];
                    for (
                      var i = 0, l = e.target.options.length;
                      i < l;
                      i++
                    ) {
                      if (
                        e.target.options[i].selected &&
                        e.target.options[i].value
                      ) {
                        ids.push(e.target.options[i].value);
                      }
                    }
                    f.department_ids = ids;
                  })
                }
                value={form.department_ids}
                multiple={true}>
                <option value="">Select departments</option>
                {departments?.map(a => (
                  <option
                    key={a.id}
                    value={a.id}
                    selected={form.department_ids.includes(a.id)}>
                    {a.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          <DropdownComponent
            options={goalList}
            defaultOption={translationService.translate(
              TranslationCategories.Challenges,
              'Select a goal',
            )}
            onSelect={value => changeFormValue('challenge_goal_id', value)}
            selectTitle={translationService.translate(
              TranslationCategories.Challenges,
              'Goal',
            )}
            icon={CommodeIcon}
            error={errors?.goal && translationService.translate(
              TranslationCategories.Errors,
              errors?.goal,
            )}
          />
          <DropdownComponent
            options={rewardList}
            defaultOption={translationService.translate(
              TranslationCategories.Challenges,
              'Select a reward',
            )}
            onSelect={value => changeFormValue('challenge_reward_id', value)}
            selectTitle={translationService.translate(
              TranslationCategories.Challenges,
              'Reward',
            )}
            icon={CommodeIcon}
            error={errors?.reward && translationService.translate(
              TranslationCategories.Errors,
              errors?.reward,
            )}
          />
          <DropdownComponent
            options={rewardConditionList}
            defaultOption={translationService.translate(
              TranslationCategories.Challenges,
              'Select a reward condition',
            )}
            onSelect={value => changeFormValue('challenge_reward_condition_id', value)}
            selectTitle={translationService.translate(
              TranslationCategories.Challenges,
              'Select Reward Condition',
            )}
            icon={CommodeIcon}
            error={errors?.rewardCondition && translationService.translate(
              TranslationCategories.Errors,
              errors?.rewardCondition,
            )}
          />
          <p className='text-sm font-semibold pb-2'>
            {translationService.translate(
              TranslationCategories.Challenges,
              'Start date',
            )}
          </p>
          <DatePicker
            minDate={new Date()}
            name="started_at"
            value={form.started_at}
            onChange={(e: any) => changeValue(f => (f.started_at = e))}
          />
          {errors?.startDate && (
            <p className='text-sm text-error'>
              {translationService.translate(
                TranslationCategories.Errors,
                errors?.startDate,
              )}
            </p>
          )}
          <p className='text-sm font-semibold pb-2'>
            {translationService.translate(
              TranslationCategories.Challenges,
              'End date',
            )}
          </p>
          <div className='pb-3'>
            <DatePicker
              minDate={new Date()}
              name="ended_at"
              value={form.ended_at}
              onChange={(e: any) => changeValue(f => (f.ended_at = e))}
            />
          </div>
          {errors?.endDate && (
            <p className='text-sm text-error'>
              {translationService.translate(
                TranslationCategories.Errors,
                errors?.endDate,
              )}
            </p>
          )}
          <div className='pt-3 w-full flex justify-center'>
            <ButtonComponent
              title={translationService.translate(
                TranslationCategories.Challenges,
                'Save challenge',
              )}
              onClick={e => saveChallenge()}
            />
          </div>
        </div>
        <div className='w-full h-96 bg-white p-3 md:p-6 rounded-xl shadow-xl md:order-1 overflow-auto'>
          <p className='p-3 text-2xl font-semibold'>
            {translationService.translate(
              TranslationCategories.Challenges,
              'Challenges',
            )}
          </p>
          {loadingChallenges ? (
            <LoadingComponent />
          ) : (
            <table className='w-full mt-3'>
              <thead>
                <tr>
                  <th className='text-left text-sm font-semibold tracking-wide p-3'>
                    {translationService.translate(
                      TranslationCategories.Challenges,
                      'Name',
                    )}
                  </th>
                  <th className='text-left text-sm font-semibold tracking-wide p-3 '>
                    {translationService.translate(
                      TranslationCategories.Challenges,
                      'Goal type',
                    )}
                  </th>
                  <th className='text-left text-sm font-semibold tracking-wide p-3 '>
                    {translationService.translate(
                      TranslationCategories.Challenges,
                      'Reward',
                    )}
                  </th>
                  <th className='text-left text-sm font-semibold tracking-wide p-3'>
                    {translationService.translate(
                      TranslationCategories.Challenges,
                      'Participants by',
                    )}
                  </th>
                  <th className='text-left text-sm font-semibold tracking-wide p-3 '>
                    {translationService.translate(
                      TranslationCategories.Challenges,
                      'Start date',
                    )}
                  </th>
                  <th className='text-left text-sm font-semibold tracking-wide p-3 '>
                    {translationService.translate(
                      TranslationCategories.Challenges,
                      'End date',
                    )}
                  </th>
                  <th className={!canUpdate ? 'hidden' : 'p-3'}/>
                  <th className={!canUpdate ? 'hidden' : 'p-3'}/>
                </tr>
              </thead>
              <tbody>
                {challenges?.map((challenge, idx) => (
                  <tr className={idx%2 ===0 ? 'bg-white' : 'bg-deepGreen-100 bg-opacity-5'} key={idx}>
                    <td className='w-full text-sm p-3'>{challenge.name}</td>
                    <td className='w-full text-sm p-3'>{challenge.goal?.type}</td>
                    <td className='w-full text-sm p-3'>{challenge.reward?.name}</td>
                    <td className='w-full text-sm p-3'>{challenge.type && textService.capitalizeFirstLetter(challenge.type)}</td>
                    <td className='w-full text-sm p-3'>
                      {challenge.started_at ? timeService.formatDateToString(challenge.started_at) : null}
                    </td>
                    <td className='w-full text-sm p-3'>
                      {challenge.ended_at ? timeService.formatDateToString(challenge.ended_at) : 'No updated'}
                    </td>
                    <td className={!canUpdate ? 'hidden' : 'w-full'}>
                      <EditIcon height={20} onClick={() => setId(challenge.id)}/>
                    </td>
                    <td className={!canUpdate ? 'hidden' : 'w-full'}>
                      <DeleteIcon height={20} onClick={() => deleteChallenge(challenge.id)} data-testid={`delete-challenge-${challenge.id}`}/>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        {confirmData && <ConfirmComponent {...confirmData} />}
      </div>
    )
  );
};

export default CreateChallengeFormComponent;
