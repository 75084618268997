import {Assessment} from './Assessment';
import {SoundTherapy} from './SoundTherapy';
import {Activity, ActivityType} from './Activity';
import {Exercise} from './Exercise';

export class ActivityFactory {
  static initObject(props: any): Activity {
    let activity = null;
    switch (props.type) {
      case ActivityType.Assessment:
        activity = new Assessment(props);
        break;
      case ActivityType.SoundTherapy:
        activity = new SoundTherapy(props);
        break;
      case ActivityType.Exercise:
        activity = new Exercise(props);
        break;
      default:
        console.log(`Unknown type activity: ${props.type}`);
        activity = new Activity(props);
    }
    return activity;
  }
}
