import React, { useState } from 'react'
import { ResetForm } from '../../models/ResetForm';
import ButtonComponent from '../../../common/components/ButtonComponent';
import { TranslationCategories } from '../../../common/models/TranslationCategories';
import translationService from '../../../translation/services/translation.service';
import AuthInputField from '../../components/AuthInputField';
import WeVoiceLogo from '../../../../assets/minimalist-logo-wevoice.png';
import EmailIcon from '../../../../assets/iconset/email-icon.svg';
import { Link, useNavigate } from 'react-router-dom';
import authService from '../../services/auth.service';

const ResetPasswordScreen = () => {
    const [form, setForm] = useState(new ResetForm(''));
    const [resetted, setResetted] = useState<boolean>(false);
    const [errors, setErrors] = useState<{[name: string]: string}>({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const changeValue = (field: string, event: any) => {
        const value = event.target.value;
        setForm({
          ...form,
          [field]: value,
        });
      };

    const reset = () => {
        setLoading(true);
        authService
        .resetPassword(form)
        .then(() => {
            setLoading(false);
            setResetted(true);
        })
        .catch(exception => {
            setLoading(false);
            exception.errors
            ? setErrors(exception.errors) 
            : setErrors({
                email: 
                    'Something went wrong!'
                });
        });
    };

    return (
        <div className="bg-sunriseYellow-100 h-screen w-screen flex justify-center items-center">
            <div className='md:w-[642px] flex flex-col justify-center items-center'>
                <img src={WeVoiceLogo} alt='WeVoice logo' width={200} height={200}/>
                <h1 className='text-2xl font-semibold text-center mb-6'>
                {translationService.translate(
                    TranslationCategories.Login,
                    'Reinventing Workplace Wellness.'
                )}
                </h1>
                <div className='bg-white p-4 w-full rounded-2xl mb-3'>
                    <h2 className='text-lg font-bold mb-3'>
                        {translationService.translate(
                        TranslationCategories.ResetPassword,
                        'Restore Password'
                        )}
                    </h2>
                    
                    {!resetted ? (
                        <>
                            <p className='mb-3'>
                                {translationService.translate(
                                    TranslationCategories.ResetPassword,
                                    'Please enter your email and we will send you instruction.',
                                )}
                            </p>
                            <AuthInputField
                                icon={EmailIcon}
                                textInput={'Email'}
                                textContentType={'emailAddress'}
                                error={errors.email}
                                value={form.email}
                                onChange={value => changeValue('email', value)}
                            />
                            <div className='w-full flex flex-col items-center pt-2'>
                                <ButtonComponent title={
                                    translationService.translate(
                                        TranslationCategories.ResetPassword,
                                        'Restore Password',
                                    )}
                                    onClick={reset}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <p className='mb-3'>
                                {translationService.translate(
                                    TranslationCategories.ResetPassword,
                                    'We have sent you email with further instructions!',
                                )}
                            </p>
                            <Link to="/auth/login">
                                <p className='text-sm underline w-full text-center'>
                                    {translationService.translate(
                                        TranslationCategories.ResetPassword,
                                        'Remembered? Go to Login!',
                                    )}
                                </p>
                            </Link>
                            
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ResetPasswordScreen