import React, {useState} from 'react';
import translationService from '../../translation/services/translation.service';
import {TranslationCategories} from '../../common/models/TranslationCategories';
import {DepartmentForm} from '../models/Department';
import companyService from '../services/company.service';
import PopupComponent from '../../common/components/PopupComponent/PopupComponent';
import AuthInputField from '../../auth/components/AuthInputField';
import UserIcon from '../../../assets/iconset/user-icon.svg';
import ButtonComponent from '../../common/components/ButtonComponent';
import DropdownComponent from '../../common/components/DropdownComponent';

const stateOptions = [
  {label: 'Active', value: 1},
  {label: 'Inactive', value: 0},
];
interface CreateDepartmentComponentProps {
  onCreate: () => void;
}
const CreateDepartmentComponent = (props: CreateDepartmentComponentProps) => {
  const [depForm, setDepForm] = useState(new DepartmentForm());
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [errors, setErrors] = useState<{name: string; description: string}>();

  const createDepartment = (e: any) => {
    e.preventDefault();
    const validation = companyService.validateDepartmentForm(depForm);
    console.log(validation);
    if (validation.isValid) {
      companyService
        .createDepartment(depForm)
        .then(res => {
          console.log('created_dep:', res);
          setShowPopup(true);
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      setErrors(validation.errors);
    }
  };

  const changeValue = (field: string, event: any) => {
    const value = event.target.value;
    setDepForm({
      ...depForm,
      [field]: value,
    });
  };

  return (
    <div className='p-4 rounded-2xl shadow-xl bg-white w-full'>
      {showPopup && (
        <PopupComponent
          header={translationService.translate(
            TranslationCategories.Errors,
            'Information'
          )}
          description={
            translationService.translate(
              TranslationCategories.Popup,
              'The department {department} has been successfully created.',
              {
                department: depForm.name
              }
            )}
          onClose={() => props.onCreate()}
          buttonText={translationService.translate(
            TranslationCategories.Popup,
            'Accept'
          )}
        />
      )}
      <div className='flex flex-col items-center w-full'>
        <p className='w-full text-xl font-semibold text-black pb-2 text-start'>
          {translationService.translate(
            TranslationCategories.Dashboard,
            'Add new department',
          )}
        </p>
        <AuthInputField
          icon={UserIcon}
          textInput={translationService.translate(
            TranslationCategories.Dashboard,
            'Department name',
          )}
          textContentType={'text'}
          error={errors?.name}
          value={depForm.name}
          onChange={value => changeValue('name', value)}
        />
        <AuthInputField
          icon={UserIcon}
          textInput={translationService.translate(
            TranslationCategories.Dashboard,
            'Department description',
          )}
          textContentType={'text'}
          error={errors?.description}
          value={depForm.description}
          onChange={value => changeValue('description', value)}
        />
        <DropdownComponent
          options={stateOptions}
          onSelect={value => changeValue('is_active', value)}
          selectTitle={translationService.translate(
            TranslationCategories.Dashboard,
            'State'
          )}
          icon={UserIcon}
        />
        <div className='pt-3'>
          <ButtonComponent
            title={translationService.translate(
              TranslationCategories.Dashboard,
              'Create department',
            )}
            onClick={e => createDepartment(e)}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateDepartmentComponent;
