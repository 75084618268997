import {AuthProvider} from './AuthProvider';

export class RegistrationForm {
  provider: AuthProvider = AuthProvider.Email;
  data!: EmailRegistrationData;
  referral?: string;
  is_guest?: boolean = false;

  constructor(props: any = {}) {
    const values = {...props};
    this.data = new EmailRegistrationData();
    Object.assign(this, values);
  }
}

export class EmailRegistrationData {
  fullname?: string;
  email?: string;
  password?: string;
  is_terms_accepted?: boolean;
  is_news_subscribed?: boolean;
  is_ml_accepted?: boolean;
  constructor(props: any = {}) {
    const data = {...props};
    Object.assign(this, data);
  }
}

export class RegistrationResponse {}
