import React from 'react'
import translationService from '../../translation/services/translation.service';
import { TranslationCategories } from '../models/TranslationCategories';

interface TextAreaComponentProps {
    icon: any;
    textInput: string;
    error?: any;
    value: string;
    onChange: (value: any) => void;
}
const TextAreaComponent = (props: TextAreaComponentProps) => {
    return (
        <div className='w-full mb-3'>
            <div className='w-full bg-paleGrey rounded-xl min-h-[100px] my-3 py-2 px-3 flex flex-row items-start'>
                {props.icon && <img src={props.icon} height={18} width={18}/>}
                <textarea
                    className='w-full bg-paleGrey rounded-xl pl-2 min-h-[100px] border-none focus:border-none'
                    placeholder={props.textInput}
                    value={props.value}
                    onChange={value => props.onChange(value)}>

                </textarea>
            </div>
            {props.error && (
                <p className='text-error'>
                    {translationService.translate(
                        TranslationCategories.Errors,
                        props.error,
                    )}
                </p>
            )}
        </div>
    )
}

export default TextAreaComponent