import {ExerciseComponentProps} from "../models/Props";
import {useEffect, useState} from "react";
import BreathingBackground from '../../../assets/app/breathing-background.png';
import {BreathingTypes, ExerciseCommand, ExerciseCommandType} from "../models/Exercise";
import translationService from "../../translation/services/translation.service";
import {TranslationCategories} from "../../common/models/TranslationCategories";
import timeService from "../../common/services/time.service";
import ButtonComponent from "../../common/components/ButtonComponent";

const BreathingExerciseComponent = (props: ExerciseComponentProps) => {
  const commands = props.activity.commands;
  const [size, setSize] = useState(50);
  const [text, setText] = useState('');
  const [commandData, setCommandData] = useState({
    idx: 0,
    repeatIdx: 0,
    counter: 0,
  });
  const [breathinClicks, setBreathingClicks] = useState<any[]>([
    {button: 'the_beginning', time: Date.now()},
  ]);
  const addClick = (button: any) => {
    const result = [...breathinClicks];
    result.push({button: button, time: Date.now()});
    setBreathingClicks(result);
  };

  useEffect(() => {
      // without pattern for some time
      if (props.activity.parameters && !props.activity.parameters?.with_sb) {
        const timer = setTimeout(() => {
          props.onEnd && props.onEnd({results: breathinClicks});
        }, props.activity.parameters?.duration_ms_without_sb || 1000);
        return () => {
          clearInterval(timer);
        };
      }
    },
    []
  );

  useEffect(() => {
    if (commands.length - 1 < commandData.idx) {
      props.onEnd && props.onEnd({results: breathinClicks});
      return;
    }

    const command = commands[commandData.idx];

    if (command.type === ExerciseCommandType.RepeatStart) {
      setCommandData({
        idx: commandData.idx + 1,
        counter: 0,
        repeatIdx: command.repeat || 5,
      });
      return;
    }
    if (command.type === ExerciseCommandType.RepeatEnd) {
      if (commandData.repeatIdx > 1) {
        setCommandData({
          idx: commandData.idx - commandData.counter,
          counter: 0,
          repeatIdx: commandData.repeatIdx - 1,
        });
      } else {
        setCommandData({
          idx: commandData.idx + 1,
          counter: 0,
          repeatIdx: 0,
        });
      }
      return;
    }

    const animations: any = {
      [ExerciseCommandType.BreathIn]: 100,
      [ExerciseCommandType.BreathOut]: 50,
      [ExerciseCommandType.AfterBreathOutWait]: 50,
      [ExerciseCommandType.AfterBreathInWait]: 100,
    };
    setText(command.instruction || '');
    if (command.type in animations) {
      setSize(animations[command.type]);
    }

    setTimeout(() => {
      setCommandData({
        idx: commandData.idx + 1,
        counter: commandData.counter + 1,
        repeatIdx: commandData.repeatIdx,
      });
    }, (command.delayMs ? +command.delayMs : 0));
  }, [commandData]);

  const breathingType = BreathingTypes.BCT_Button;//props.activity.parameters?.bct_control_type;

  return (
    <div>
    <div className='flex justify-center'>
      <div className='flex-col text-center'>
        <div>
          {translationService.translate(
            TranslationCategories.ProgramsBackend,
            text,
          )}
        </div>
        <div className='relative'>
          {commands.length > commandData.idx && (<div
            className={`absolute border rounded w-full h-full transition-all rounded-full`}
            style={
              {
                background: 'rgba(40, 90, 102, 0.5)',
                maxHeight: size + "%",
                maxWidth: size + "%",
                left: (100 - size) / 2 + "%",
                top: (100 - size) / 2 + "%",
                transitionDuration: commands[commandData.idx].delayMs+'ms'
              }
            }>
          </div>)}
          <img src={BreathingBackground} />
        </div>
      </div>
    </div>
      {breathingType === BreathingTypes.BCT_Button && (
        <div className='flex flex-row justify-center mt-2'>
          <div className='mr-1'>
            <ButtonComponent
              title={'+ 1'}
              onClick={() => {
                addClick('breath');
              }}
            />
          </div>
          <div className='mr-1'>
            <ButtonComponent
              title={'9'}
              onClick={() => {
                addClick('loop');
              }}
            />
          </div>
          <div className='mr-1'>
            <ButtonComponent
              title={'Reset'}
              onClick={() => {
                addClick('reset');
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default BreathingExerciseComponent;
