export class ProgramFilter {
  name?: string;
  has_access: boolean | undefined;
  has_completed: boolean | undefined;
  can_add: boolean | undefined;
  is_released?: boolean;
  is_test?: boolean;
  is_public?: boolean;

  constructor(props?: any) {
    Object.assign(this, props);
  }
}

export class ProgramSession {
  id!: string;
  journey_id!: string;
  name!: string;
  description!: string;
  image_path!: string;
  duration_minutes?: number;
  journey?: Program;

  constructor(props?: any) {
    Object.assign(this, props);
  }
}

export class Program {
  id!: string;
  name!: string;
  description!: string;
  url!: string;
  image!: string;
  video!: any;
  image_path!: string;
  video_path?: string;
  is_active!: boolean;
  sessions: ProgramSession[] = [];
  sessions_count?: number;
  with_certificate?: boolean

  constructor(props?: any) {
    Object.assign(this, props);
  }
}
