import React, {useState} from 'react';
import authService from '../../services/auth.service';
import {
  RegistrationForm,
  RegistrationResponse,
} from '../../models/RegistrationForm';
import translationService from '../../../translation/services/translation.service';
import {TranslationCategories} from '../../../common/models/TranslationCategories';
import AuthInputField from '../../components/AuthInputField';
import ButtonComponent from '../../../common/components/ButtonComponent';
import {Link, useNavigate} from 'react-router-dom';
import WeVoiceLogo from '../../../../assets/minimalist-logo-wevoice.png';
import EmailIcon from '../../../../assets/iconset/email-icon.svg';
import PasswordIcon from '../../../../assets/iconset/password-icon.svg';
import UserIcon from '../../../../assets/iconset/user-icon.svg';
import RefIcon from '../../../../assets/iconset/communication-numbers-icon.svg';
import CheckboxComponent from '../../../common/components/CheckboxComponent';
import userStoreService from "../../services/user-store.service";

const promiseHelper: ((arg: any) => [any, any])[] = [
  value => [value, null],
  reason => [null, reason],
];

const RegisterScreen = () => {
  const [form, setForm] = useState<RegistrationForm>(new RegistrationForm());
  const [errors, setErrors] = useState<{
    fullname: string;
    email: string;
    password: string;
    referral: string;
    is_terms_accepted: string;
  }>();
  const navigate = useNavigate();

  const changeValue = (callback: (form: RegistrationForm) => void) => {
    const f = new RegistrationForm(form);
    callback(f);
    setForm(f);

    console.log('Current form:', form)
  };

  const register = async (form: RegistrationForm) => {
    authService
      .register(form)
      .then(() => {
        const user = userStoreService.getUser();
        if (user) {
          navigate(user.getHomeUrl(), {replace: true});
        }
      })
      .catch(exception => {
        console.log('Error', exception);
        if (exception.errors) {
          setErrors(exception.errors);
        }
      })
  };

  const submitForm = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    setErrors(undefined);

    const validation = authService.validateRegistrationForm(form);
    console.log('Validations', validation)
    if (validation.isValid) {
      register(form);
    } else {
      setErrors(validation.errors);
    }
  };

  return (
    <div className="bg-sunriseYellow-100 h-screen w-screen flex justify-center items-center">
      <div className='md:w-[642px] flex flex-col justify-center items-center px-3'>
        <img src={WeVoiceLogo} alt='WeVoice logo' width={200} height={200}/>
        <h1 className='text-2xl font-semibold text-center mb-6'>
          {translationService.translate(
            TranslationCategories.Login,
            'Reinventing Workplace Wellness.'
          )}
        </h1>
        <div className='bg-white p-4 w-full rounded-2xl mb-3'>
          <h2 className='text-lg font-bold mb-3'>
            {translationService.translate(
              TranslationCategories.Registration,
              'Register'
            )}
          </h2>
          <AuthInputField
            icon={UserIcon}
            textInput={translationService.translate(
              TranslationCategories.Registration,
              'Full Name',
            )}
            textContentType={'text'}
            error={errors?.fullname && translationService.translate(
              TranslationCategories.Errors,
              errors?.fullname,
            )}
            value={form.data.fullname ?? ''}
            onChange={e => changeValue(f => (f.data.fullname = e.target.value))}
          />
          <AuthInputField
            icon={EmailIcon}
            textInput={translationService.translate(
              TranslationCategories.Registration,
              'Email',
            )}
            textContentType={'emailAddress'}
            error={errors?.email && translationService.translate(
              TranslationCategories.Errors,
              errors?.email,
            )}
            value={form.data.email ?? ''}
            onChange={e => changeValue(f => (f.data.email = e.target.value))}
          />
          <AuthInputField
            icon={PasswordIcon}
            textInput={translationService.translate(
              TranslationCategories.Registration,
              'Password',
            )}
            textContentType={'password'}
            error={errors?.password && translationService.translate(
              TranslationCategories.Errors,
              errors?.password,
            )}
            value={form.data.password ?? ''}
            onChange={e => changeValue(f => (f.data.password = e.target.value))}
          />
          <AuthInputField
            icon={RefIcon}
            textInput={translationService.translate(
              TranslationCategories.Registration,
              'Referral Code',
            )}
            textContentType={'text'}
            error={errors?.referral && translationService.translate(
              TranslationCategories.Errors,
              errors?.referral,
            )}
            value={form.referral ?? ''}
            onChange={e => changeValue(f => (f.referral = e.target.value))}
          />
          <CheckboxComponent
            textLabel={translationService.translate(
              TranslationCategories.Registration,
              'I agree with'
            )}
            checkBoxName={'privacy'}
            textLink={translationService.translate(
              TranslationCategories.Registration,
              'Privacy Policy and Terms of use'
            )}
            linkUrl={'https://www.wevoice.ai/politica-de-privacidad'}
            checked={form.data.is_terms_accepted ?? false}
            defaultChecked={false}
            onClick={checkStatus => changeValue(f => (f.data.is_terms_accepted = checkStatus))}
          />
          <CheckboxComponent
            textLabel={translationService.translate(
              TranslationCategories.Registration,
              'I allow to use my data anonymously to improve the service'
            )}
            checked={form.data.is_ml_accepted ?? false}
            defaultChecked={false}
            onClick={checkStatus => changeValue(f => (f.data.is_ml_accepted = checkStatus))}
            checkBoxName={'ml'}
          />
          <CheckboxComponent
            textLabel={translationService.translate(
              TranslationCategories.Registration,
              'I agree to receive new updates about product and services'
            )}
            checked={form.data.is_terms_accepted ?? false}
            defaultChecked={false}
            onClick={checkStatus => changeValue(f => (f.data.is_news_subscribed = checkStatus))}
            checkBoxName={'updates'}
          />
          {errors?.is_terms_accepted && (
            <p className='text-error'>
              {translationService.translate(
                  TranslationCategories.Errors,
                  errors.is_terms_accepted,
              )}
            </p>
          )}
          <div className='w-full flex flex-col items-center pt-2'>
            <ButtonComponent
              title={translationService.translate(
                TranslationCategories.Registration,
                'Register',
              )}
              onClick={(e) => submitForm(e)}
            />
            <p className='my-3 underline text-sm'>
              <Link to="/auth/login">
                {translationService.translate(
                  TranslationCategories.Login,
                  'Already have an account? Log in',
                )}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterScreen;
