import React from 'react';
import {ActivityType} from '../models/Activity';
import ExerciseComponent from './ExerciseComponent';
import {ActivityComponentProps} from '../models/Props';
import {Exercise} from '../models/Exercise';
import AssessmentComponent from './AssessmentComponent/AssessmentComponent';

const ActivityComponent = (props: ActivityComponentProps) => {
  let Component: any = null;
  let componentProps: ActivityComponentProps = props;
  switch (props.activity.type) {
    case ActivityType.Assessment:
      Component = AssessmentComponent;
      componentProps = {...props, activity: props.activity as Exercise};
      break;
    case ActivityType.Exercise:
      Component = ExerciseComponent;
      componentProps = {...props, activity: props.activity as Exercise};
      break;
    default:
      console.log(`Dont know how to render ${props.activity.type}`);
  }
  return <Component {...componentProps} />;
};

export default ActivityComponent;
