import React, { useState } from 'react';
import HeaderBackground from '../../../../assets/ilustrations/header-background.jpg';
import MenuTabs from '../../../../routes/menuTabs';
import {ReactComponent as CloseIcon} from '../../../../assets/iconset/close-icon.svg';
import {ReactComponent as MenuIcon} from '../../../../assets/iconset/settings-icon.svg';
import { Link } from 'react-router-dom';
import FeedbackComponent from '../../../feedback/components/FeedbackComponent';
import textService from '../../services/text.service';
import { TranslationCategories } from '../../models/TranslationCategories';
import translationService from '../../../translation/services/translation.service';

const HeaderComponent = () => {
  const [showMenu, setShowMenu] = useState(false);
  const appleUrl = 'https://apps.apple.com/es/app/wevoice-ai-sleep-stress-focus/id1577589862';
  const googleUrl = 'https://play.google.com/store/apps/details?id=com.wevoicemobile';

  return (
    <div className={'w-screen'}>
      <FeedbackComponent />
      <div className="absolute h-28 w-screen flex items-center justify-between px-8 lg:hidden">
        <div className='flex flex-row gap-3'>
          <a href={appleUrl} target='_blank' className='z-50 w-20'>
            <img src={require('../../../../assets/common/apple-app.png')} alt="Apple App"/>
          </a>
          <a href={googleUrl} target='_blank' className='z-50 w-20'>
            <img src={require('../../../../assets/common/google-app.png')} alt="Google App"/>
          </a>
        </div>
        <div className='bg-white h-10 w-10 rounded-full shadow-xl flex items-center justify-center'>
          {showMenu ? (
            <CloseIcon width={15} height={15} onClick={() => setShowMenu(false)}/>
            ) : (
              <MenuIcon width={20} height={20} onClick={() => setShowMenu(true)}/>
            )}
        </div>
      </div>
      <img src={HeaderBackground} className='h-28 w-full'/>
      <div className='hidden lg:flex absolute lg:top-7 lg:right-7 lg:flex-row lg:gap-10'>
        <a href={appleUrl} target='_blank' className='z-50 w-32'>
          <img src={require('../../../../assets/common/apple-app.png')} alt="Apple App"/>
        </a>
        <a href={googleUrl} target='_blank' className='z-50 w-32'>
          <img src={require('../../../../assets/common/google-app.png')} alt="Google App"/>
        </a>
      </div>
      {showMenu && (
        <div className='w-screen bg-white flex flex-col rounded-b-xl py-1 lg:hidden'>
          {MenuTabs.map((nav, index) => {
            const Icon = nav.icon;
            return(
              <Link
                key={index}
                className={location.pathname.startsWith(nav.href) ? 'flex items-center p-3 bg-paleGrey' : 'flex items-center p-4 hover:bg-paleGrey'}
                to={nav.href} onClick={() => setShowMenu(false)}
              >
                <Icon width={20} height={20}/>                 
                <p className={'pl-2 font-semibold'}>
                    {translationService.translate(
                      TranslationCategories.Menu,
                      nav.ui
                    )}
                </p>
              </Link>
            )
          })}
        </div>
      )}
    </div>
  );
};

export default HeaderComponent;