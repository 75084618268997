import translationService from '../../translation/services/translation.service';
import {Emotion} from '../models/Emotion';
import {TranslationCategories} from "../../common/models/TranslationCategories";

const getEmotion = (emo: Emotion) => {
  const coords = translationService.translate(
    TranslationCategories.Emotions,
    'coordinates',
  );

  const sorted = coords
    .map((coord: any) => {
      const len = Math.sqrt(
        Math.pow(emo.valence - coord.valence, 2) +
          Math.pow(emo.arousal - coord.arousal, 2),
      );
      return {...coord, length: len};
    })
    .sort((a: any, b: any) => (a.length <= b.length ? -1 : 1));
  return sorted[0];
};

export default {
  getEmotionName: (emo: Emotion) => {
    return getEmotion(emo).name;
  },
  getEmotionEmoji: (emo: Emotion) => {
    return getEmotion(emo).emoji;
  },
  getEmotionLocal: (emo: Emotion) => {
    return getEmotion(emo);
  },
};
