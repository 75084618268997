import React, {useRef, useState} from 'react';
import {Emotion} from "../../models/Emotion";
import translationService from "../../../translation/services/translation.service";
import {TranslationCategories} from "../../../common/models/TranslationCategories";
import emotionService from "../../services/emotion.service";

export interface EmotionComponentProps {
  onChoose: (emotion: Emotion) => void;
}

//https://www.researchgate.net/figure/Two-dimensional-Valence-Arousal-affective-space-of-emotions-32-Different-regions_fig1_263858663
//https://sbgames.org/sbgames2018/files/papers/ComputacaoFull/188391.pdf
// https://www.pinterest.co.uk/pin/211458144989830581/
const EmotionComponent = (props: EmotionComponentProps) => {
  const containerEl = useRef<any>(null);

  const [emotion, setEmotion] = useState<Emotion>();
  const [isDragging, setIsDragging] = useState(false);

  const coords = translationService.translate(
    TranslationCategories.Emotions,
    'coordinates',
  );

  const changeEmo = (x: number, y: number) => {
    const valence = -1 + 2 * x;
    const arousal = 1 - 2 * y; //((size.height - y) * 2) / size.height - 1;
    const emo = {arousal, valence};
    setEmotion(emo);
    props.onChoose(emo);
  };

  const moveEmo = (e: any) => {
    if (!containerEl.current) {
      return null;
    }
    const rect = containerEl.current.getBoundingClientRect();
    const x = e.clientX - rect.left; //x position within the element.
    const xRelative = Math.round(x / rect.width * 100) / 100;
    const y = e.clientY - rect.top;  //y position within the element.
    const yRelative = Math.round(y / rect.height * 100) / 100;
    changeEmo(xRelative, yRelative);
  };
  const dragEmo = (e: any) => {
    if (!isDragging) {
      return;
    }
    moveEmo(e);
  }

  const emoX = emotion ? ((1 + emotion.valence) / 2 * 100) : 50;
  const emoY = emotion ? ((1 - emotion.arousal) / 2 * 100) : 50;

  return (
    <div className='flex-1 flex flex-col h-full'
         onClick={moveEmo}
         onMouseDown={() => setIsDragging(true)}
         onMouseLeave={() => setIsDragging(false)}
         onMouseMove={dragEmo}
    >
      <div className='flex justify-center pb-2'>
        {emotionService.getEmotionName(emotion || {arousal: 0, valence: 0})}{' '}
        {emotionService.getEmotionEmoji(
          emotion || {arousal: 0, valence: 0},
        )}
      </div>

      <div className='flex justify-center font-bold'>
        <div>
          {translationService.translate(
            TranslationCategories.Emotions,
            'High',
          )}
        </div>
      </div>

      <div className='flex-grow-1 flex-1 relative select-none' ref={containerEl}>
        {/*{Emos}*/}
        {coords.map((coord: any, index: number) => (
          <span className={`absolute`} key={index} style={{...coord.position}}>
            {coord.name}
          </span>
        ))}

        {/*{selected}*/}
        <div className='absolute' style={{top: emoY+'%', left: emoX+'%'}}>
          {emotionService.getEmotionEmoji(
            emotion || {arousal: 0, valence: 0},
          )}
        </div>

        {/*{X Axe}*/}
        <div className='top-1/2 absolute left-full font-bold'>
            {translationService.translate(
              TranslationCategories.Emotions,
              'Positive',
            )}
        </div>
        <div className='top-1/2 absolute w-full border'></div>
        <div className='left-1/2 absolute h-full border'></div>
        <div className='top-1/2 absolute font-bold'>
          {translationService.translate(
            TranslationCategories.Emotions,
            'Negative',
          )}
        </div>
      </div>

      <div className='flex justify-center font-bold'>
        <span>
          {translationService.translate(
            TranslationCategories.Emotions,
            'Low',
          )}
        </span>
      </div>
    </div>
  );
};

export default EmotionComponent;
