import React, {useEffect, useState} from 'react';
//import SoundPlayerComponent from '../../activities/components/SoundPlayerComponent';
//import {SoundTherapy} from '../../models/SoundTherapy';
import ActivityComponent from '../ActivityComponent';
import {ActivityType} from '../../models/Activity';
/* import trackerService from '../../tracker/services/tracker.service';
import {TrackerEventType} from '../../tracker/models/TrackerEventType'; */
import programService from '../../../programs/services/ProgramService';
import {UserSessionActivity} from '../../../programs/models/UserProgram';
import AssessmentResultComponent from '../AssessmentResultComponent/AssessmentResultComponent';
import {SoundTherapy} from '../../models/SoundTherapy';
import SoundPlayerComponent from '../SoundPlayerComponent/SoundPlayerComponent';
import {UserSession} from '../../../programs/models/UserProgram';
import {TrackerEventType} from "../../../tracking/models/TrackerEventType";
import trackerService from "../../../tracking/services/tracker.service";

interface ProgramSessionComponentProps {
  id: string;
  onActivityEnd?: (
    userSession: UserSession,
    userSessionActivity: UserSessionActivity,
  ) => void;
  onResultActivity?: () => void
}
const ProgramSessionComponent = (props: ProgramSessionComponentProps) => {
  const {id: sessionId, onActivityEnd, onResultActivity} = props;

  const [session, setSession] = useState<UserSession>();
  const [started, setStarted] = useState(false);
  const [showResultActivityId, setShowResultActivityId] = useState<string>();

  useEffect(() => {
    programService.getUserSession(sessionId).then(sess => {
      setSession(sess);

      console.groupCollapsed('ProgramSessionComponent: get UserSession');
      console.log(sess);
      console.groupEnd();
      trackerService.triggerEvent(TrackerEventType.SessionStarted, {
        session_id: sess.id,
        session_name: sess.session?.name || 'activity',
        program_id: sess.session?.journey_id || 'no journey',
        program_name: sess.session?.journey?.name || 'no journey'
      });
    });
  }, [sessionId]);

  const mainActivity = session?.getMainActivity();
  const backgroundActivity = session?.getBackgroundActivity();

  // Sound Only or Sequence of sounds -> works as usual, play sound while there are sessions
  // Activity with background with continue background -> Show activity, on endActivity end mainactivity, continue sound activity
  // Activity with background without continue background -> Show activity, on endActivity end mainactivity, end sound activity, onEnd sound -> repeat?
  const completeActivity = (activity: UserSessionActivity, result?: any) => {
    programService.completeActivity(activity.id, result).then(sess => {
      trackerService.triggerEvent(TrackerEventType.ActivityEnded, {
        activity_id: activity.id,
        activity_name: activity.activity.name,
        session_id: activity.session?.id,
        activity_type: activity.activity.type,
      });
      if (activity.activity.type === ActivityType.SoundTherapy) {
        //const sound = sess.activities.find(a => a.id === activity.id);
      }
      const startedActivities = sess.getStartedActivities();

      setSession(sess);

      if (startedActivities && startedActivities.length > 0) {
        trackerService.triggerEvent(TrackerEventType.ActivityStart, {
          activity_id: startedActivities[0].id,
          activity_name: startedActivities[0].activity.name,
          session_id: startedActivities[0].session?.id,
          activity_type: startedActivities[0].activity.type,
          user_activity_id: activity.id,
        });
      } else {
        trackerService.triggerEvent(TrackerEventType.SessionEnded, {
          session_id: activity.session?.id,
          user_activity_id: activity.id,
          session_name: sess.session?.name || 'session',
          program_id: sess.session?.journey_id || 'no journey',
          program_name: sess.session?.journey?.name || 'no journey',
        });
      }

      console.groupCollapsed('ProgramSessionComponent: Activity completed');
      console.log('UserSession', sess);
      console.log('Started Activities', startedActivities);
      console.groupEnd();

      onActivityEnd?.(sess, activity);

      if (activity.activity.type === ActivityType.Assessment) {
        setShowResultActivityId(activity.id);
      }
    });
  };

  const startSound = () => {
    setStarted(true);

    console.log('ProgramSessionComponent: start Activity show children');
  };

  const endSound = (decline: boolean, duration: number) => {
    console.log('ProgramSessionComponent: End Sound');
    const activity = backgroundActivity ?? mainActivity;

    if (activity) {
      // on decline need to close session, not mainActivity
      completeActivity(activity, {decline, duration});
    }
  };

  const endActivity = (data?: any) => {
    console.groupCollapsed('ProgramSessionComponent: End Activity');
    console.log(mainActivity);
    console.groupEnd();

    if (mainActivity) {
      completeActivity(mainActivity, data ?? {});
    }
  };

  /* const categories = translationService.getCategory(
    TranslationCategories.Category,
  );
  let userBenefit: any = null;
  if (mainActivity && mainActivity.goal_request) {
    Object.entries(categories).find((items: any) => {
      return items[1].subCategories.find((benefit: any) => {
        userBenefit = mainActivity?.goal_request.benefits.includes(+benefit.id)
          ? benefit
          : null;
        return userBenefit;
      });
    });
  } */

  const hasSound =
    (mainActivity &&
      mainActivity.activity.type === ActivityType.SoundTherapy) ||
    backgroundActivity;
  return (
    <div>
      {hasSound && (
        <SoundPlayerComponent
          sound={
            (backgroundActivity ? backgroundActivity : mainActivity)
              ?.activity as SoundTherapy
          }
          onEnd={endSound}
          onStart={startSound}
          background={backgroundActivity?.is_background && !session?.session}
          title={mainActivity?.activity.name}
        >
          {mainActivity && backgroundActivity && started ? (
            <ActivityComponent
              key={mainActivity.id}
              activity={mainActivity.activity}
              onEnd={endActivity}
            />
          ) : null}
        </SoundPlayerComponent>
      )}

      {/* If no sound activities */}
      {!hasSound && mainActivity && !showResultActivityId && (
        <ActivityComponent
          key={mainActivity.id}
          activity={mainActivity.activity}
          onEnd={endActivity}
        />
      )}
      {showResultActivityId && (
        <AssessmentResultComponent
          id={showResultActivityId}
          onEnd={() => {
            setShowResultActivityId(undefined);
            onResultActivity?.()
          }}
        />
      )}
    </div>
  );
};

export default ProgramSessionComponent;
