export class ActivityResultAnswer {
  assessment_question_id?: string;
  discussion_question_id?: string;
  answer!: string;
  answer_id?: string;

  constructor(props: any) {
    Object.assign(this, props);
  }
}

export class ActivityResultForm {
  constructor(public answers: ActivityResultAnswer[]) {}

  static fromObject(props: any) {
    console.groupCollapsed('ActivityResultForm: fromObject');
    console.log(props);
    console.groupEnd();

    //only assessment supported
    const result: ActivityResultAnswer[] = [];
    Object.keys(props).forEach(key => {
      const value = props[key];
      result.push(
        new ActivityResultAnswer({
          answer: value,
          assessment_question_id: key,
        }),
      );
    });

    console.groupCollapsed('ActivityResultForm: fromObject');
    console.log(result);
    console.groupEnd();

    return new ActivityResultForm(result);
  }
}
