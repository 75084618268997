import './RouteListComponent.scss';

import {Navigate, Route, Routes} from 'react-router-dom';
import userStoreService from '../../../auth/services/user-store.service';
import NotFound from '../NotFoundComponent/NotFoundComponent';

function RouteListComponent(props: {routes: any[]}) {
  const routeComponents = props.routes.map((route, i) => (
    <Route
      key={i}
      path={route.path}
      element={
        route.authCheck?.() === false ? (
          <Navigate to={typeof route.redirect === 'function' ? route.redirect() : route.redirect} replace={true} />
        ) : (
          <route.component />
        )
      }
    />
  ));

  const user = userStoreService.getUser();
  const indexUrl = user !== null ? user.getHomeUrl() : 'auth/login';
  return (
    <div className="router-component">
      <Routes>
        {routeComponents}

        <Route
          path="/"
          element={
            <Navigate to={indexUrl} />
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default RouteListComponent;
