import {Permissions} from '../../auth/models/User';
import permissionService from '../../auth/services/permissions.service';
import userStoreService from '../../auth/services/user-store.service';
import ChallengesScreen from '../screens/ChallengesScreen';

const routes = [
  {
    path: '/challenges',
    component: ChallengesScreen,
    redirect: '/',
    authCheck: () =>
      userStoreService.getUser() !== null &&
      permissionService.checkUserPermission(
        Permissions.company_challenge_create,
      ),
  },
  {
    path: '/challenges/:param',
    component: ChallengesScreen,
    redirect: '/',
    authCheck: () =>
      userStoreService.getUser() !== null &&
      permissionService.checkUserPermission(
        Permissions.company_challenge_goals_read,
      ) &&
      permissionService.checkUserPermission(
        Permissions.company_challenge_rewards_read,
      ),
  },
];

export default routes;
