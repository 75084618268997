import React, {useRef, useState} from 'react';
import {InputComponentProps} from '../../models/InputComponentProps';
import translationService from "../../../translation/services/translation.service";
import {TranslationCategories} from "../../../common/models/TranslationCategories";

const CheckboxComponent = (props: InputComponentProps) => {
  const {question, onAnswer} = props;
  const [valid, setValid] = useState<boolean[]>();

  const [answers, setAnswers] = useState(new Set());

  const hasOption = (option: any): boolean =>  {
    return answers.has(option.text);
  }

  const onAnswerClick = (option: any) => {
    const newValues = new Set(answers.values());
    if (hasOption(option)) {
      newValues.delete(option.text);
    } else {
      newValues.add(option.text);
    }
    setAnswers(newValues);
  };

  const validate = (): boolean => {
    const itemResults = question.options.map((item) => {
      const hasItem = hasOption(item);
      const hasWrong = item.wrong_answer_explanation && item.wrong_answer_explanation.length > 0 && hasItem;
      const missesRight = item.is_valid_answer && !hasItem;
      const valid = !hasWrong && !missesRight;
      console.log('Item', hasItem, hasWrong, missesRight, valid);
      return valid;
    });
    console.log('Result', itemResults);
    setValid(itemResults);
    return !itemResults.some(item => !item)
  }

  const onClickNext = () => {
    if (validate()) {
      setValid(undefined);
      const data = Array.from(answers.values());
      onAnswer(question.id, data.join(",")); //maybe move to ids... have doubts for now too far fro understanding all possibilities
      setAnswers(new Set());
    } else {
      console.log('NOT VALID');
    }
  };

  return (
    <div className='flex flex-col items-center'>
      <h3 className='w-full text-center text-md md:text-xl'>
        {question.question}
      </h3>
      <div className='w-full pt-3 flex flex-col'>
        {question.options.map((option, idx) => (
          <div
            key={idx}
            className={'px-5 py-2 m-2 font-semibold'+`${!valid || valid[idx] ? '' : ' bg-error rounded-xl'}`}
          >
            <input className='mr-2' type={"checkbox"} id={`q${question.id}-o${idx}`} onChange={() => onAnswerClick(option)} checked={hasOption(option)} value={option.text} />
            <label
              className='text-lg'
              htmlFor={`q${question.id}-o${idx}`}>
                {translationService.translate(TranslationCategories.ProgramsBackend, option.text)}
            </label>
          </div>
        ))}
      </div>
      <div className='mt-3'>
        <button
          className='bg-deepGreen-100 px-5 py-2 rounded-xl text-white font-semibold'
          type="button"
          onClick={onClickNext}>
          {translationService.translate(
            TranslationCategories.Sessions,
            'Next',
          )}
        </button>
      </div>
    </div>
  );
};

export default CheckboxComponent;
