import React, {useEffect, useRef} from 'react'
import './CertificationComponent.scss'
import LogoWeVoice from '../../../../assets/common/logo-wevoice-horizontal.png';
import {Program} from '../../models/Program'
import { UserProgram } from '../../models/UserProgram';
import timeService from '../../../common/services/time.service';
import {TranslationCategories} from '../../../common/models/TranslationCategories';
import translationService from '../../../translation/services/translation.service';
import userStoreService from '../../../auth/services/user-store.service'
import certificationService from '../../services/certificationService';
import programUtils from '../../services/ProgramUtils';

interface CertificationProps {
  program: Program;
  userProgram: UserProgram;
  onCreate: () => void;
}
const CertificationComponent = (props: CertificationProps) => {
  const user = userStoreService.getUser();
  const ref = useRef(null);
  const image = require(`../../../../assets/certifications/${props.program.id}.jpg`);

  const createCertification = async () => {
    const pdf = certificationService.downloadCertification(ref.current);
    pdf.size !== 0 && props.onCreate();

  }
  useEffect(()=>{
    createCertification();
  }, [])

  return (
    <div ref={ref} id='certificate' className="certification-container">
      <div className="certification-box">
        <div className="certification-content">
          <div className="certification-content_header">
            <img src={LogoWeVoice} alt="logo wevoice" />
            <p className="certification-content_header-ref">
              {translationService.translate(
                TranslationCategories.Programs,
                'Reference: {ref}',{ref: props.userProgram?.id}
              )}
            </p>
          </div>
          <div className="certification-content_details">
            <div className="certification-content_details-left">
              <h1>{translationService.translate(
                TranslationCategories.Programs,
                'CERTIFICATE OF COMPLETION'
              )}</h1>
              <h2>{props.program.name}</h2>
              <p style={{marginBottom: 100}}>
                {translationService.translate(
                  TranslationCategories.Programs,
                  '{nSessions} sessions, {duration} minutes in total.',
                  {
                    nSessions: props.program.sessions.length.toString(),
                    duration: programUtils.calculateProgramEstimationTime(props.program).toString()
                  }
                )}
              </p>
              <h3>{user?.fullname}</h3>
              {props.userProgram.completed_at && <p>
                {translationService.translate(
                  TranslationCategories.Programs,
                  'Completion date: {date}',
                  {
                    date: timeService.formatDateToString(props.userProgram.completed_at)
                  }
                )}
              </p>}
            </div>
            <div className="certification-content_details-right">
              <img
                className='certification-content_details-right_image'
                src={image || props.program.image_path}
                draggable={false}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CertificationComponent