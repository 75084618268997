import {Activity, ActivityType} from '../../activities/models/Activity';
import {ActivityFactory} from '../../activities/models/ActivityFactory';
import {Program, ProgramSession} from './Program';

export class UserProgram {
  id!: string;

  scheduled_at!: Date;
  started_at!: Date | null;
  completed_at!: Date | null;
  activities: UserSessionActivity[] = [];
  sessions: UserSession[];
  journey: Program;

  constructor(props: any) {
    ['scheduled_at', 'started_at', 'completed_at'].forEach(field => {
      if (props[field] !== null) {
        props[field] = new Date(props[field]);
      }
    });
    Object.assign(this, props);
    this.sessions = (props.sessions ?? []).map((s: any) => new UserSession(s));
    this.journey = new Program(props.journey);
    // this.activities = this.activities.map(activity => new PlanActivity(activity));
  }

  isStarted() {
    return this.started_at != null;
  }

  isCompleted() {
    return this.completed_at != null;
  }

  hasSessions() {
    return this.sessions != null && this.sessions.length > 0;
  }

  /**
   * Get the next UserSession to do.
   * If there isn't any return null
   */
  getNextUserSession(): UserSession | null {
    const nextUserSession =
      this.sessions.find(userSession => !userSession.isCompleted()) ?? null;

    return nextUserSession;
  }

  /**
   * Get the next ProgramSession to do.
   * If there isn't any return null.
   * (Depends on 'getNextUserSession()')
   */
  getNextProgramSession(): ProgramSession | null {
    const nextUserSession = this.getNextUserSession();

    if (nextUserSession == null) return null;

    const nextProgramSession = nextUserSession?.session!;

    return nextProgramSession;
  }

  /**
   * Get the UserSession object related to the given ProgramSession.
   * If no 'session' property if found on the UserSession
   * then it will throw an Error to warn about the UserSession not being valid
   */
  getUserSessionFromProgramSession(
    programSession: ProgramSession | null | undefined,
  ): UserSession | null {
    if (programSession == null) return null;

    const userSession = this.sessions.find(userSession => {
      if (!userSession.session) {
        console.error('UserSession', userSession);
        throw new Error(
          `\n'session' is not defined in 'UserSession'.\n Maybe the UserSession is not valid\n`,
        );
      }

      return userSession.session.id === programSession.id;
    });

    if (userSession == null) {
      return null;
    }

    return userSession;
  }
}

export class UserSession {
  id!: string;
  scheduled_at!: string;
  completed_at!: string;
  started_at!: string;
  with_checkup?: boolean;
  activities!: UserSessionActivity[];
  session?: ProgramSession;

  constructor(props: any) {
    Object.assign(this, props);
    this.activities = (this.activities ?? []).map(
      a => new UserSessionActivity(a)
    );
    this.session = props.session
      ? new ProgramSession(props.session)
      : undefined;
  }

  isStarted() {
    return this.started_at != null;
  }

  isCompleted() {
    return this.completed_at != null;
  }

  hasActivities() {
    return this.activities != null && this.activities.length > 0;
  }

  getStartedActivities() {
    return this.activities.filter(
      (a) => a.isStarted() && !a.isCompleted()
    );
  }

  getNextUserSessionActivity() {
    return this.getStartedActivities()[0];
  }

  getIndexUserSessionActivityFromId(userSessionActivityId: string | null | undefined) {
    if (userSessionActivityId == null) {
      return -1
    }

    return this.activities.findIndex(userActivity => userActivity.id === userSessionActivityId)
  }

  getMainActivity() {
    return this.getStartedActivities().find(a => !a.is_background);
  }

  getBackgroundActivity() {
    return this.getStartedActivities().find(a => a.is_background);
  }

  getActivityByType(type: ActivityType) {
    return this.activities.filter(a => a.activity.type === type);
  }
}

export class UserSessionActivity {
  id!: string;
  scheduled_at!: Date | null;
  started_at!: Date | null;
  completed_at!: Date | null;
  activity!: Activity;
  session?: UserSession;
  goal_request?: any;
  duration!: number;
  is_background?: boolean;
  is_continue_after_main?: boolean;

  constructor(props: any) {
    ['scheduled_at', 'started_at', 'completed_at'].forEach(field => {
      if (props[field] !== null) {
        props[field] = new Date(props[field]);
      }
    });
    Object.assign(this, props);
    this.activity = ActivityFactory.initObject(this.activity);
  }

  isStarted() {
    return this.started_at != null;
  }

  isCompleted() {
    return this.completed_at != null;
  }
}
