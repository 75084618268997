import React, {useCallback, useEffect, useState} from 'react';
import translationService from '../../../translation/services/translation.service';
import {TranslationCategories} from '../../../common/models/TranslationCategories';
import LoadingComponent from '../../../common/components/LoadingComponent/LoadingComponent';
import {Department} from '../../../company/models/Department';
import companyService from '../../../company/services/company.service';
import timeService from '../../../common/services/time.service';
import {DepartmentStatFilter, SummaryFilter} from "../../models/Statsfilter";
import {CoursesSummary, DepartmentStatsResult} from "../../models/Stats";
import StatsDataItemComponent from "../../components/StatsDataPointComponent/StatsDataItemComponent";

export interface DepartmentStatRowProps {
  department: Department;
  stats?: DepartmentStatsResult;
  idx: number;
}

const DepartmentStatRow = (props: DepartmentStatRowProps) => {
  const dep = props.department;
  return (
    <tr className={props.idx % 2 ===0 ? 'bg-white' : 'bg-deepGreen-100 bg-opacity-5'} key={dep.id} data-testid={`department-stat-${dep.id}`}>
      <td className='py-2 items-center whitespace-nowrap'>
        <div className='flex w-8 h-8 rounded-lg items-center justify-center bg-deepGreen-100'>
          <p className='text-sm text-white'>{dep.name.slice(0,2)}</p>
        </div>
      </td>
      <td className='py-2 items-center whitespace-nowrap'>
        <div className='pl-2'>
          <p className='text-sm font-semibold'>{dep.name}</p>
          <p className='text-xs text-steel'>
            {translationService.translate(
              TranslationCategories.Dashboard,
              'Since {date}',
              {
                date: dep.created_at
                  ? timeService.formatDateToString(dep.created_at)
                  : 'undefined'
              }
            )}
          </p>
        </div>
      </td>
      <td className='py-2 items-center whitespace-nowrap'>
        <div className='pl-2'>
          <p className='text-sm font-semibold'>{props.stats?.program_total || 0}</p>
        </div>
      </td>
      {/* <td className='py-2 items-center whitespace-nowrap'>
        <div className='pl-2'>
          <p className='text-sm font-semibold'>{props.stats?.people_total || 0}</p>
        </div>
      </td> */}
      <td className='py-2 items-center whitespace-nowrap'>
        <div className='pl-2'>
          <p className='text-sm font-semibold'>{props.stats?.user_program_total || 0}</p>
        </div>
      </td>
      <td className='py-2 items-center whitespace-nowrap'>
        <div className='pl-2'>
          <p className='text-sm font-semibold'>{props.stats?.user_program_not_started || 0}</p>
        </div>
      </td>
      <td className='py-2 flex items-center whitespace-nowrap'>
        <div className='pl-2'>
          <p className='text-sm font-semibold'>{props.stats?.user_program_in_progress || 0}</p>
        </div>
      </td>
      <td className='py-2 items-center whitespace-nowrap'>
        <div className='pl-2'>
          <p className='text-sm font-semibold'>{props.stats?.user_program_completed || 0}</p>
        </div>
      </td>
    </tr>
  );
};

function ProgramsDashboardScreen() {
  const [loading, setLoading] = useState<boolean>(false);
  const [departments, setDepartments] = useState<Department[]>();
  const [summary, setSummary] = useState<CoursesSummary>();
  const [departmentResults, setDepartmentResults] = useState<DepartmentStatsResult[]>();

  const loadDepartments = useCallback(() => {
    companyService.getDepartments().then(deps => {
      setDepartments(deps);
      setLoading(false);
    });
  }, []);

  const loadSummary = useCallback(() => {
    const filter = {} as SummaryFilter;
    companyService.getCoursesSummary(filter).then(res => {
      setSummary(res);
      setLoading(false);
    });
  }, []);

  const loadDepartmentsResult = useCallback(() => {
    const filter = {} as DepartmentStatFilter;
    companyService.getCourseStatsForDepartment(filter).then(res => {
      setDepartmentResults(res);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    loadDepartments();
  }, [loadDepartments]);

  useEffect(() => {
    loadSummary();
  }, [loadSummary]);

  useEffect(() => {
    loadDepartmentsResult();
  }, []);

  const getDepartmentStats = (department_id: string) => {
    return departmentResults?.find(d => d.department_id === department_id);
  };

  return (loading ? (
    <div className='h-screen w-full flex justify-center'>
      <LoadingComponent text={''} fullscreen={false} smooth={true}/>
    </div>
  ) : (
    <div className='lg:items-start pt-8 md:pt-12 px-2'>
      <h2 className='text-3xl font-semibold mb-5 md:mb-10'>
        {translationService.translate(
          TranslationCategories.ProgramDashboard,
          'Programs Dashboard',
        )}
      </h2>

      {summary && (
        <div className='rounded-xl shadow-xl bg-white mb-5 p-3'>
          <h3 className='text-2xl font-semibold mb-2'>
            {translationService.translate(
              TranslationCategories.ProgramDashboard,
              'Summary',
            )}
          </h3>

          <div className='flex flex-row overflow-auto'>
            <div style={{flex: '1'}}>
              <StatsDataItemComponent
                value={summary.program_total}
                name={translationService.translate(TranslationCategories.ProgramDashboard, "Program #")}
                description={translationService.translate(TranslationCategories.ProgramDashboard, "Total active programs")} />
            </div>
            <div style={{flex: '1'}}>
              <StatsDataItemComponent
                value={summary.people_total}
                name={translationService.translate(TranslationCategories.ProgramDashboard, "People #")}
                description={translationService.translate(TranslationCategories.ProgramDashboard, "Total active people")} />
            </div>
            {/* <div style={{flex: '1'}}>
              <StatsDataItemComponent
                value={summary.user_program_total}
                name={translationService.translate(TranslationCategories.ProgramDashboard, "Participate #")}
                description={translationService.translate(TranslationCategories.ProgramDashboard, "Total participations")} />
            </div> */}
            <div style={{flex: '1'}}>
              <StatsDataItemComponent
                value={summary.user_program_completed}
                name={translationService.translate(TranslationCategories.ProgramDashboard, "Certificates")}
                description={translationService.translate(TranslationCategories.ProgramDashboard, "Completed participations")} />
            </div>
            <div style={{flex: '1'}}>
              <StatsDataItemComponent
                value={summary.user_program_in_progress}
                name={translationService.translate(TranslationCategories.ProgramDashboard, "In Progress")}
                description={translationService.translate(TranslationCategories.ProgramDashboard, "In progress participation")} />
            </div>
            <div style={{flex: '1'}}>
              <StatsDataItemComponent
                value={summary.user_program_not_started}
                name={translationService.translate(TranslationCategories.ProgramDashboard, "Not started")}
                description={translationService.translate(TranslationCategories.ProgramDashboard, "Not started participation")} />
            </div>
            <div style={{flex: '1'}}>
              <StatsDataItemComponent
                value = {timeService.getFormattedTimeBySeconds(summary.total_duration_estimation).substring(0,5)}
                name={translationService.translate(TranslationCategories.ProgramDashboard, "Estimated")}
                description={translationService.translate(TranslationCategories.ProgramDashboard, "Estimated time")} />
            </div>
            <div style={{flex: '1'}}>
              <StatsDataItemComponent
                value={timeService.getFormattedTimeBySeconds(summary.duration_completed_estimation).substring(0,5)}
                name={translationService.translate(TranslationCategories.ProgramDashboard, "Completed")}
                description={translationService.translate(TranslationCategories.ProgramDashboard, "Completed time")} />
            </div>
          </div>
        </div>
      )}

      <div className='w-full bg-white p-3 md:p-6 rounded-xl shadow-xl md:order-1 overflow-auto'>
          <table className='w-full'>
            <thead className=''>
              <tr>
                <td className='text-left tracking-wide pb-3 opacity-30' colSpan={2}>
                  {translationService.translate(TranslationCategories.ProgramDashboard, 'Department')}
                </td>
                <td className='text-left tracking-wide pb-3 opacity-30'>
                  {translationService.translate(TranslationCategories.ProgramDashboard, 'Program')}
                </td>
                <td className='text-left tracking-wide pb-3 opacity-30'>
                  {translationService.translate(TranslationCategories.ProgramDashboard, 'People')}
                </td>
                {/* <td className='text-left tracking-wide pb-3 opacity-30'>
                  {translationService.translate(TranslationCategories.ProgramDashboard, 'Participants')}
                </td> */}
                <td className='text-left tracking-wide pb-3 opacity-30'>
                  {translationService.translate(TranslationCategories.ProgramDashboard, 'Not started')}
                </td>
                <td className='text-left tracking-wide pb-3 opacity-30'>
                  {translationService.translate(TranslationCategories.ProgramDashboard, 'In Progress')}
                </td>
                <td className='text-left tracking-wide pb-3 opacity-30'>
                  {translationService.translate(TranslationCategories.ProgramDashboard, 'Completed')}
                </td>
              </tr>
            </thead>
            <tbody>
              {departments?.map((dep, idx) => (
                <DepartmentStatRow department={dep} stats={getDepartmentStats(dep.id)} idx={idx} key={idx} />
              ))}
            </tbody>
          </table>
        </div>
    </div>
  ));
}

export default ProgramsDashboardScreen;
