import {CheckupForm} from '../models/CheckupForm';
import fetchService from "../../common/services/fetch.service";

class CheckupService {
  checkup(form: CheckupForm) {
    return fetchService.fetchApi<any>('/api/checkup/emotions', {
      method: 'POST',
      body: JSON.stringify(form),
    });
  }
}

const checkupService = new CheckupService();
export default checkupService;
