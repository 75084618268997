import React, {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import programService from '../../services/ProgramService';
import {UserSession, UserSessionActivity} from '../../models/UserProgram';
import {promiseThenHelper} from '../../../common/helpers/promise.helper';
import errorService from '../../../common/services/error.service';
import NotFound from '../../../common/components/NotFoundComponent/NotFoundComponent';
import PopupComponent from '../../../common/components/PopupComponent/PopupComponent';
import translationService from '../../../translation/services/translation.service';
import {TranslationCategories} from '../../../common/models/TranslationCategories';
import {ActivityType} from '../../../activities/models/Activity';
import {ReactComponent as CheckIcon} from '../../../../assets/iconset/check-circle-icon.svg'
import ProgramSessionComponent from '../../../activities/components/ProgramSessionComponent/ProgramSessionComponent';

function SessionScreen(props: any) {
  const {id} = useParams();

  const navigate = useNavigate();

  const [userSession, setUserSession] = useState<UserSession>();
  const nextUserActivity = userSession?.getNextUserSessionActivity();
  const nextActivityIndex =
    userSession?.isCompleted() === true
      ? userSession.activities.length - 1
      : userSession?.getIndexUserSessionActivityFromId(nextUserActivity?.id) ??
        0;

  const [isUserSessionFetched, setIsUserSessionFetched] =
    useState<boolean>(false);

  const [modalSessionEndVisibility, setModalSessionEndVisibility] =
    useState(false);

  useEffect(() => {
    if (!id) return;

    const fetchData = () => {
      return programService
        .getUserSession(id)
        .then(...promiseThenHelper) as Promise<
        [UserSession, null] | [null, object]
      >;
    };

    const handleData = async () => {
      const [fetchedUserSession, fetchError] = await fetchData();
      setIsUserSessionFetched(true);

      if (fetchError) {
        console.log(fetchError);

        errorService.addError(fetchError);
        return;
      }
      setUserSession(fetchedUserSession);
    };

    handleData();
  }, [id]);

  if (!id || !userSession) {
    return isUserSessionFetched ? <NotFound /> : null;
  }

  const activitiesLength = userSession.activities.length;

  const activities =
    userSession.activities.map((activity, index) => {

      return (
        <div
          key={index}
          className={activity.id === userSession.getNextUserSessionActivity()?.id ?
            'flex justify-between items-center p-2 my-2 rounded-xl bg-success' :
            'flex justify-between items-center p-2 my-2'}>
          <p className='text-md md:text-md'>
            {index + 1}. {activity.activity.name}
          </p>
          <CheckIcon
            height={20}
            width={20}
            fill={activity?.isCompleted() === true ? 'green' : 'black'}
          />
        </div>
      );
    }) ?? [];

  const exitLessonOnClick = () => {
    const programId = userSession.session?.journey_id;
    navigate(`/programs/${programId}`);
  };

  const onSessionEnd = () => {
    setModalSessionEndVisibility(true);
  };

  const onActivityEnd = (
    userSession: UserSession,
    userSessionActivity: UserSessionActivity,
  ) => {
    setUserSession(userSession);
    if (
      userSession.isCompleted() &&
      userSessionActivity.activity.type !== ActivityType.Assessment
    ) {
      onSessionEnd();
    }
  };

  const onResultActivity = () => {
    if (userSession.isCompleted()) {
      onSessionEnd();
    }
  };

  const onModalSessionEndClose = () => {
    setModalSessionEndVisibility(false);
    navigate(-1);
  };

  return (
    <div className='w-full h-full flex flex-col items-center'>
      <div className='flex w-full pr-3 justify-end'>
        <div 
          className='p-2 mt-8 border-2 border-deepGreen-50 rounded-xl shadow-md'
          onClick={exitLessonOnClick}>
          <p className='text-sm'>
            {translationService.translate(
              TranslationCategories.Sessions,
              'Exit session',
            )}
          </p>
        </div>
      </div>
      
      <div className='w-full h-full flex flex-col md:flex-row items-start justify-center'>
        <div className='p-3 w-full md:w-3/5 lg:w-4/6'>
          <ProgramSessionComponent
            id={userSession.id}
            onActivityEnd={onActivityEnd}
            onResultActivity={onResultActivity}
          />
        </div>
        <div className='w-full md:w-2/5 lg:w-2/6'>
          <div className='w-full p-3 border-b-2 border-midnight6'>
            <p className='text-xl font-semibold md:text-xl pt-3'>
              {userSession.session?.name}
            </p>
            <p className="text-md pt-3">
              {userSession.session?.description}
            </p>
          </div>
          <div className='p-3 w-full'>
            <p className='font-semibold text-lg'>
              {nextActivityIndex + 1} / {activitiesLength}
            </p>
            <div className='pt-3'>
              {activities}
            </div>
          </div>
        </div>
      </div>
      {modalSessionEndVisibility && (
        <PopupComponent
          header={translationService.translate(
            TranslationCategories.Sessions,
            'Congratulations!',
          )}
          description={translationService.translate(
            TranslationCategories.Sessions,
            'You have successfully completed the session.',
          )}
          onClose={onModalSessionEndClose}
        />
      )}
    </div>
  );
}

export default SessionScreen;
