import fetchService from '../../common/services/fetch.service';

class DashboardService {
  getDashboardLink() {
    return fetchService.fetchApi<{link: string}>(
      `/api/company/graphs/dashboard_link`,
      {
        method: 'GET',
      },
    );
  }
}

const dashboardService = new DashboardService();
export default dashboardService;
