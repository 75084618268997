import {TrackerEventType} from '../models/TrackerEventType';
import TagManager from "react-gtm-module";

class TrackerService {
  private defaultValues: {[key: string]: string} = {};

  constructor() {
    this.init();
  }

  async init() {
    const gtmId = process.env.REACT_APP_GTM_ID;
    if (gtmId) {
      const tagManagerArgs = {
        gtmId: gtmId
      }
      console.log('INIT Tag Manager', tagManagerArgs);
      TagManager.initialize(tagManagerArgs)
    }
  }

  triggerEvent(event: TrackerEventType, data: any) {
    const eventData = Object.assign({}, this.defaultValues, data);
    console.log('Track event', event, event.toString(), eventData);
    if (process.env.REACT_APP_GTM_ID) {
      eventData['event'] = event;
      ((window as any).dataLayer || []).push(eventData);
    }
  }

  setUserProperties(data: {[key: string]: string}) {
    this.triggerEvent(TrackerEventType.SetUserProperty, data);
  }

  setDefaultValue(name: string, value: string) {
    this.defaultValues[name] = value;
  }

  removeDefaultValue(name: string) {
    delete this.defaultValues[name];
  }

  clearDefaultValues() {
    this.defaultValues = {};
  }
}

const trackerService = new TrackerService();
export default trackerService;
