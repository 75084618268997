const routes = [
    {
        title: 'Report a problem',
        link: 'https://airtable.com/shrANLeW2fBvep6QV'
    },
    {
        title: 'Request feature',
        link: 'https://airtable.com/shrKxLtv5MDj1LQGh'
    },
    {
        title: 'Give feedback',
        link: 'https://airtable.com/shrX5I9BEg7CbCIkn'
    }
]

export default routes;