import {ExerciseType} from './Exercise';

export enum ActivityType {
  Assessment = 'assessment',
  SoundTherapy = 'sound-therapy',
  Exercise = 'exercise',
}

export enum SpecialActivityNames {
  VoiceTest = 'Voice-Test',
}

export class Activity {
  id!: string;
  name!: string;
  image!: string;
  image_path!: string;
  description!: string;
  type!: ActivityType;
  benefits: ActivityBenefit[] = [];
  is_active!: boolean;
  is_released!: boolean;
  is_premium!: boolean;
  feature_category_id?: string;
  color?: string;
  exercise_type!: ExerciseType;
  time_estimation?: number;

  parameters?: any;

  constructor(props: any) {
    Object.assign(this, props);
  }

  getLink() {
    return `/app/activity/${this.id}`;
  }
}

export class ActivityBenefit {
  id!: number;
  name!: string;
  icon!: string;
  icon_path!: string;
  image!: string;
  image_path!: string;

  constructor(props: any) {
    return Object.assign(this, props);
  }
}

export class CompletedAssessment {
  activity_id!: string;
  completed_at!: Date;
  name!: string;
  id!: string;

  constructor(props: any) {
    Object.assign(this, props);
    this.completed_at = new Date(props.completed_at);
  }
}
