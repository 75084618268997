import React, {useRef, useState} from 'react';
import {InputComponentProps} from '../../models/InputComponentProps';
import translationService from "../../../translation/services/translation.service";
import {TranslationCategories} from "../../../common/models/TranslationCategories";

const RadioButtonComponent = (props: InputComponentProps) => {
  const {question, onAnswer} = props;
  const [error, setError] = useState<string>();

  const questionsAnswered = useRef(new Set());

  const onAnswerClick = (option: any) => {
    if (questionsAnswered.current.has(question.id)) return;

    //validation block, review later for more advanced or xcare one
    if (option.wrong_answer_explanation && option.wrong_answer_explanation.length > 0) {
      setError(option.wrong_answer_explanation);
      return;
    } else {
      setError(undefined);
      questionsAnswered.current.add(question.id);
      onAnswer(question.id, option.text); //maybe move to ids... have doubts for now too far fro understanding all possibilities
    }
  };

  return (
    <div className='flex flex-col items-center'>
      <h3 className='w-full text-center text-md md:text-xl'>
        {question.question}
      </h3>
      <p className='text-error'>
        {error && translationService.translate(
          TranslationCategories.Errors,
          error,
        )}
      </p>
      <div className='w-full pt-3 flex flex-col items-center'>
        {question.options.map((option, idx) => (
          <div
            key={idx}
            onClick={() => {
              onAnswerClick(option);
            }}
            className='px-5 py-2 m-2 rounded-lg bg-deepGreen-100 w-[200px] font-semibold text-white text-center'
          >
            {option.text}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RadioButtonComponent;
