import fetchService from '../../common/services/fetch.service';
import {Company, CompanyForm} from '../models/Company';
import {
  Department,
  DepartmentForm,
  Employee,
  EmployeeForm,
} from '../models/Department';
import {CoursesSummary, CourseStatsResult, DepartmentStatsResult} from "../../programs/models/Stats";
import {CourseStatFilter, DepartmentStatFilter, SummaryFilter} from "../../programs/models/Statsfilter";

class CompanyService {
  updateCompany(id: string, form: CompanyForm): Promise<Company> {
    return fetchService
      .fetchApi<Company>(`/api/company/structure`, {
        method: 'PATCH',
        body: JSON.stringify(form),
      })
      .then(data => {
        return new Company(data);
      });
  }

  getCompany(): Promise<Company> {
    return fetchService
      .fetchApi<Company>(`/api/company/structure`, {
        method: 'GET',
      })
      .then(data => {
        return new Company(data);
      });
  }

  createDepartment(form: DepartmentForm): Promise<Department> {
    return fetchService
      .fetchApi<Department>(`/api/company/structure/department`, {
        method: 'POST',
        body: JSON.stringify(form),
      })
      .then(res => {
        return new Department(res);
      });
  }

  getDepartments(): Promise<Department[]> {
    return fetchService
      .fetchApi<Department[]>(`/api/company/structure/departments`, {
        method: 'GET',
      })
      .then(deps => {
        return deps;
      });
  }

  updateDepartment(
    company_id: string,
    department_id: string,
    form: DepartmentForm,
  ): Promise<Department> {
    return fetchService.fetchApi<Department>(
      `/api/company/structure/department/${department_id}`,
      {
        method: 'PATCH',
        body: JSON.stringify(form),
      },
    );
  }

  createEmployee(form: EmployeeForm): Promise<Employee> {
    return fetchService
      .fetchApi<Employee>(`/api/company/structure/employee`, {
        method: 'POST',
        body: JSON.stringify(form),
      })
      .then(emp => new Employee(emp));
  }

  getEmployees(): Promise<Employee[]> {
    return fetchService
      .fetchApi<Employee[]>(`/api/company/structure/employees`, {
        method: 'GET',
      })
      .then(employees => employees.map(emp => new Employee(emp)));
  }

  validateDepartmentForm(form: DepartmentForm) {
    const validations = {name: '', description: ''};
    let isValid = true;
    if (form.name.length <= 2) {
      validations.name = 'The name of the department is too short.';
      isValid = false;
    }
    if (form.description.trim().length === 0) {
      validations.description = 'Description is required.';
      isValid = false;
    }
    return {isValid: isValid, errors: validations};
  }

  validateAddEmployeeForm(form: EmployeeForm) {
    const validations = {
      firstname: '',
      lastname: '',
      email: '',
      department_id: '',
      role: '',
    };
    let isValid = true;
    if (form.firstname.trim().length === 0) {
      validations.firstname = 'First name is required.';
      isValid = false;
    }
    if (form.firstname.trim().length === 0) {
      validations.lastname = 'Last name is required.';
      isValid = false;
    }
    if (
      form.email.trim().length === 0 ||
      (form.email && !/\S+@\S+\.\S+/.test(form.email))
    ) {
      validations.email = 'Email format must be as example@mail.com';
      isValid = false;
    }
    if (form.department_id.length <= 1) {
      validations.department_id = 'It is necessary to select a department';
      isValid = false;
    }
    if (form.role.length <= 1) {
      validations.role = 'It is necessary to select a role';
      isValid = false;
    }
    return {isValid: isValid, errors: validations};
  }

  getCoursesSummary(filter?: SummaryFilter) {
    const url = fetchService.getURLWithParamsFromObject(
      '/api/company/courses/summary',
      {...filter},
    );
    return fetchService
      .fetchApi<CoursesSummary>(url, {
        method: 'GET',
      })
      .then(res => {
        return res as CoursesSummary;
      });
  }

  getCourseStatsForDepartment(filter: DepartmentStatFilter) {
    const url = fetchService.getURLWithParamsFromObject(
      '/api/company/courses/departments',
      {...filter},
    );
    return fetchService
      .fetchApi<DepartmentStatsResult[]>(url, {
        method: 'GET',
      })
      .then(res => {
        return res as DepartmentStatsResult[];
      });
  }

  getCourseStatsForCourses(filter: CourseStatFilter) {
    const url = fetchService.getURLWithParamsFromObject(
      '/api/company/courses/courses',
      {...filter},
    );
    return fetchService
      .fetchApi<CourseStatsResult[]>(url, {
        method: 'GET',
      })
      .then(res => {
        return res as CourseStatsResult[];
      });
  }
}

const companyService = new CompanyService();
export default companyService;
