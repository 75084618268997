import timeService from "./time.service";

class FormService {

  convertObjectIntoFormData(form: any) {
    const formData = new FormData();
    Object.keys(form).forEach(key => {

      if(key.includes('_at')){
        const dateString = timeService.getJsonDateFormat(form[key]);
        formData.append(key, dateString);
      }
      else if(Array.isArray(form[key])){
        for (var i = 0; i < form[key].length; i++) {
          formData.append(`${key}[]`, form[key][i]);
        };
      }
      else{
        formData.append(key, form[key]);
      }

    });
    return formData;
  }
}
const formService = new FormService();
export default formService;