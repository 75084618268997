import React, {useState} from 'react';
import {InputComponentProps} from '../../models/InputComponentProps';
import translationService from "../../../translation/services/translation.service";
import {TranslationCategories} from "../../../common/models/TranslationCategories";

const RadioComponent = (props: InputComponentProps) => {
  const {question, onAnswer} = props;
  const [error, setError] = useState<string>();
  const [answer, setAnswer] = useState<any>();


  const onAnswerClick = (option: any) => {
    setAnswer(option);
  };

  const onClickNext = () => {
    //validation block, review later for more advanced or xcare one
    if (answer.wrong_answer_explanation && answer.wrong_answer_explanation.length > 0) {
      setError(answer.wrong_answer_explanation);
      return;
    } else {
      onAnswer(question.id, answer.text); //maybe move to ids... have doubts for now too far fro understanding all possibilities
      setError(undefined);
      setAnswer(undefined);
    }
  };

  return (
    <div className='flex flex-col items-center'>
      <h3 className='w-full text-center text-md md:text-xl'>
        {question.question}
      </h3>
      <p className='text-error'>
        {error && translationService.translate(
          TranslationCategories.Errors,
          error,
        )}
      </p>
      <div className='w-full pt-6 flex flex-col'>
        {question.options.map((option, idx) => (
          <div
            key={idx}
            className='font-semibold py-2'
          >
            <input className='mr-3' type={"radio"} id={`q${question.id}-o${idx}`} onChange={() => onAnswerClick(option)} checked={!!answer && option.text === answer.text} value={option.text} />
            <label
              className='text-xl'
              htmlFor={`q${question.id}-o${idx}`}>
              {translationService.translate(TranslationCategories.ProgramsBackend, option.text)}
            </label>
          </div>
        ))}
      </div>
      <div className='mt-3'>
        <button
          className='bg-deepGreen-100 px-5 py-2 rounded-xl text-white font-semibold'
          type="button"
          onClick={onClickNext}>
          {translationService.translate(
            TranslationCategories.Sessions,
            'Next',
          )}
        </button>
      </div>
    </div>
  );
};

export default RadioComponent;
