import React, {useEffect, useState} from 'react';
import {
  ActivityResult,
  AssessmentResult,
  AssessmentResultLevel,
  AssessResult,
} from '../../models/AssessmentResult';
/* import translationService from '../../core/services/translation.service';
import {TranslationCategories} from '../../core/models/TranslationCategories'; */
import LoadingComponent from '../../../common/components/LoadingComponent/LoadingComponent';
/* import trackerService from '../../tracker/services/tracker.service';
import {TrackerEventType} from '../../tracker/models/TrackerEventType'; */
import programService from '../../../programs/services/ProgramService';
import translationService from '../../../translation/services/translation.service';
import { TranslationCategories } from '../../../common/models/TranslationCategories';
import { ActivityType } from '../../models/Activity';

interface RowComponentProps {
  levelMin: number;
  levelMax: number;
  levelDescription: string;
  color: string;
}
const ResultRowComponent = (props: RowComponentProps) => {
  return (
    <div className='flex items-center py-2'>
      <div className='rounded-full h-4 w-4' style={{backgroundColor: props.color}} />
      <h3 className='text-md md:text-xl lg:text-2xl pl-4'>
        {props.levelMin} - {props.levelMax}: {props.levelDescription}
      </h3>
    </div>
  );
};

interface AssessmentResultComponentProps {
  id: string;
  onEnd: () => void;
}
const AssessmentResultComponent = (props: AssessmentResultComponentProps) => {
  const [results, setResults] = useState<ActivityResult | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    programService.getActivityResultsNew(props.id).then(res => {
        setResults(res);
        setLoading(false);
    });
  }, [props.id]);

  const colors: string[] = ['hsl(100,50%, 49%)'];
  const colorNeeded =
    results && results?.activity.type === ActivityType.Assessment
      ? (results?.results as AssessResult[])[0].levels?.length || 0
      : 3; // number of piechar data
  const diff = results ? 100 / colorNeeded : 100;
  for (var i = 0; i < colorNeeded; ++i) {
    const h = 100 - (i + 1) * diff;
    colors.push('hsl(' + h + ',50%, 49%)');
  }

  return (
    <>
      {loading && (
        <LoadingComponent
          fullscreen={false}
          text={'Loading...'}
          smooth={true}
        />
      )}

      {!loading && (results?.results as AssessResult[])[0].levels !== null ? (
        <div className='w-full flex flex-col items-center justify-center'>
          <h1 className='text-xl md:text-3xl font-semibold'>
            {translationService.translate(
              TranslationCategories.AssessmentResult,
              'Your result',
            )}
          </h1>
          <h2 className='text-3xl md:text-5xl font-semibold py-3'>
            {(results?.results as AssessResult[])[0].value} / {(results?.results as AssessResult[])[0].max}
          </h2>
          <div className='w-auto pb-5 md:py-8'>
            {((results?.results as AssessResult[])[0].levels || []).map(
              (level:any, index:number) => (
                <ResultRowComponent
                  key={index}
                  levelMin={level.min}
                  levelMax={level.max}
                  levelDescription={level.name}
                  color={colors[index]}
                />
              ),
            )}
          </div>
          <button
            className='px-5 py-2 bg-deepGreen-100 rounded-2xl text-white shadow-lg font-semibold md:text-lg'
            type="submit"
            onClick={() => props.onEnd()}>
              {translationService.translate(
              TranslationCategories.AssessmentResult,
              'Continue',
              ) }
          </button>
        </div>
      ) : props.onEnd()}
    </>
  );
};

export default AssessmentResultComponent;
