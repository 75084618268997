import React, {useEffect, useRef, useState} from 'react';
/* import './App.scss'; */
// import WeVoiceRouter from './components/WeVoiceRouter/WeVoiceRouter';
import LayoutComponent from './modules/common/components/LayoutComponent/LayoutComponent';
import routes from './routes';
import {BrowserRouter, useLocation} from 'react-router-dom';
import translationService from './modules/translation/services/translation.service';
import trackerService from "./modules/tracking/services/tracker.service";
import authService from './modules/auth/services/auth.service';
import userStoreService from "./modules/auth/services/user-store.service";

const ScrollToTop = () => {
  let isInitialMount = useRef(true);
  const location = useLocation();
  useEffect(() => {
    if (!location.hash && !isInitialMount.current) {
      window.scrollTo(0, 0);
    }
    isInitialMount.current = false;
  }, [location]);
  return null;
};

const App = () => {
  const [inited, setInited] = useState(false);

  useEffect(() => {
    const init = async () => {
      // …do multiple sync or async tasks
      return Promise.all([
        translationService.init(),
        trackerService.init(),
      ]);
    };

    init()
      .catch(e => {
        console.log('INIT ERROR', e);
      })
      .finally(async () => {
        setInited(true);
      });
  }, [setInited]);

  useEffect(() => {
    const user = userStoreService.getUser();
    if (user) {
      authService.refreshUser();
    }
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      {inited && <LayoutComponent routes={routes} />}
    </BrowserRouter>
  );
};

export default App;
