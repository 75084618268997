import userStoreService from '../../auth/services/user-store.service';
import errorService from './error.service';

//Maybe need more flexible, but now should work without any configs
const API_URL = process.env.REACT_APP_API_URL;
const AI_URL = process.env.REACT_APP_AI_URL;

class FetchService {
  private bearer: string | null;

  constructor() {
    this.bearer = userStoreService.getToken();
  }

  private fetch(
    url: string,
    parameters: RequestInit,
    returnFullResponse = false,
    withAuth = true,
  ) {
    return fetch(url, {
      ...parameters,
      headers: this.getHeaders(parameters.headers, withAuth),
      mode: 'cors',
    }).then(response =>
      !returnFullResponse
        ? this.getDataWithStatus(response)
        : this.getWithStatus(response),
    );
  }

  fetchApi<T>(
    url: string,
    parameters: RequestInit,
    returnFullResponse = false,
  ): Promise<T> {
    return this.fetch(API_URL + url, parameters);
  }

  fetchAi<T>(url: string, parameters: RequestInit): Promise<T> {
    return this.fetch(AI_URL + url, parameters, false, false);
  }

  private getHeaders(initHeaders: any, withAuth: boolean): HeadersInit {
    const headers = initHeaders || {'Content-Type': 'application/json'};
    if (this.bearer && withAuth) {
      headers.Authorization = `bearer ${this.bearer}`;
    }
    return headers;
  }

  private getDataWithStatus(response: Response) {
    return response.json().then(data => {
      return this.getWithStatus(response, data);
    });
  }

  private getWithStatus(response: Response, returnData: any = null) {
    if (returnData.hasOwnProperty('errors')) {
      errorService.addError(returnData.errors);
    }
    if (response.status === 401) {
      this.clearAuthorization();
      window.location.href = '/auth/login';
    }

    if (response.status >= 400 && response.status <= 600) {
      return Promise.reject(returnData || response);
    }
    return Promise.resolve(returnData || response);
  }

  setAuthorization(token: string) {
    userStoreService.setToken(token);
    this.bearer = token;
  }

  clearAuthorization() {
    userStoreService.setToken(null);
    this.bearer = null;
  }

  getAuthorization() {
    return this.bearer;
  }

  getURLWithParamsFromObject(url: string, paramsObject: object) {
    const filteredObjectParams = Object.fromEntries(
      Object.entries(paramsObject).filter(
        ([key, value]) => value !== undefined,
      ),
    );

    const searchParams = new URLSearchParams(filteredObjectParams);

    const urlResult = `${url}?${searchParams}`;

    return urlResult;
  }
}

const fetchService = new FetchService();
export default fetchService;
