import {ChallengeReward} from './ChallengeReward';
import {ChallengeGoal} from './ChallengeGoal';
import {ChallengeRewardCondition} from './ChallengeRewardCondition';

export class Challenge {
  id!: string;
  challenge_reward_id!: string;
  challenge_goal_id!: string;
  challenge_reward_condition_id!: string;
  name!: string;
  description!: string;
  image!: string;
  is_template!: boolean;
  started_at?: Date;
  ended_at!: Date;
  duration!: number;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
  is_successfully_completed?: boolean;

  reward?: ChallengeReward;
  goal?: ChallengeGoal;
  reward_condition?: ChallengeRewardCondition;
  type?: string;

  constructor(props: any = {}) {
    Object.assign(this, props);

    ['started_at', 'ended_at', 'created_at', 'updated_at'].forEach(field => {
      if (props[field] !== null) {
        props[field] = new Date(props[field]);
      }
    });
  }
}

export class ChallengeFilter {
  id?: string;
  started_at?: Date;
  ended_at?: Date;
  is_successfully_completed?: boolean;

  constructor(props: any = {}) {
    Object.assign(this, props);
  }
}

export enum ChallengeType {
  //Individual = 'individual',
  Department = 'department',
  Company = 'company',
}

export class ChallengeForm {
  company_id?: string;
  department_ids: string[] = [];
  challenge_reward_id!: string;
  challenge_goal_id!: string;
  challenge_reward_condition_id!: string;
  name!: string;
  description!: string;
  image?: File;
  type!: string;
  is_template?: boolean = false;
  started_at?: Date;
  ended_at?: Date;
  duration!: number;

  constructor(props: any = {}) {
    const data = {...props};

    ['started_at', 'ended_at'].forEach(field => {
      if (data.hasOwnProperty(field) && data[field]) {
        data[field] = new Date(data[field]);
      }
    });

    if (data.departments) {
      data.department_ids = data.departments.map((d: any) => d.department_id);
    }

    Object.assign(this, data);
  }
}
