import {
  promiseThenHelper,
  promiseThenHelperValueOnly,
} from '../../common/helpers/promise.helper';
import {Program} from '../models/Program';
import {UserProgram} from '../models/UserProgram';
import programService from './ProgramService';

class ProgramUtils {
  fetchProgramAndUserProgram(programId: string) {
    return Promise.all([
      programService.getProgram(programId),
      programService
        .getUserProgram(programId)
        .then(...promiseThenHelperValueOnly),
    ]).then(...promiseThenHelper) as Promise<
      [[Program, UserProgram | null], null] | [null, object]
    >;
  }

  calculateProgramEstimationTime(program: Program){
    return program.sessions.reduce(
      (accumulator, session) => {
        const currentValue = session.duration_minutes || 0;
        return accumulator + currentValue;
      }, 0)
  }
}

const programUtils = new ProgramUtils();
export default programUtils;
