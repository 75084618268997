import React, {useCallback, useEffect, useState} from 'react';
import translationService from '../../translation/services/translation.service';
import {TranslationCategories} from '../../common/models/TranslationCategories';
import {Department, EmployeeForm} from '../models/Department';
import companyService from '../services/company.service';
import PopupComponent from '../../common/components/PopupComponent/PopupComponent';
import {UserRole} from '../../employees/models/employee';
import userStoreService from '../../auth/services/user-store.service';
import AuthInputField from '../../auth/components/AuthInputField';
import UserIcon from '../../../assets/iconset/user-icon.svg';
import EmailIcon from '../../../assets/iconset/email-icon.svg';
import DropdownComponent from '../../common/components/DropdownComponent';
import DepartmentsIcon from '../../../assets/iconset/flower-icon.svg';
import ButtonComponent from '../../common/components/ButtonComponent';

interface AddEmpoyeeComponentProps {
  departments: Department[];
  onInvite: () => void;
}
const AddEmpoyeeComponent = (props: AddEmpoyeeComponentProps) => {
  const [empForm, setEmpForm] = useState(new EmployeeForm());
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [roles, setRoles] = useState<{label: string, value: string}[]>();
  const [errors, setErrors] = useState<{
    firstname: string;
    lastname: string;
    email: string;
    department_id: string;
    role: string;
  }>();

  const departmentList : {label:string, value:string}[] = props.departments.map((department, index) => {
    return {label: department.name, value: department.id}
  });

  const loadRoles = useCallback(() => {
    const userRole = userStoreService.getUser()?.role;
    const availableRoles =
      userRole &&
      Object.values(UserRole).splice(
        Object.values(UserRole).indexOf(userRole) -
          Object.values(UserRole).length,
    );
    const rolesList = availableRoles ? availableRoles.map((rol: UserRole, index) => {
      return {label: rol, value: rol}
    }) : [{label: 'No data', value: 'no data'}];
    setRoles(rolesList);
  }, []);

  useEffect(() => {
    loadRoles();
  }, [loadRoles]);

  const addEmployee = (e: any) => {
    console.log(empForm);
    e.preventDefault();
    const validation = companyService.validateAddEmployeeForm(empForm);
    if (validation.isValid) {
      companyService
        .createEmployee(empForm)
        .then(res => {
          console.log('emp_created:', res);
          setShowPopup(true);
        })
        .catch(error => {
          setErrors(error.errors);
        });
    } else {
      setErrors(validation.errors);
    }
  };

  const changeValue = (field: string, event: any) => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    setEmpForm({
      ...empForm,
      [field]: value,
    });
  };

  return (
    <div className='p-4 rounded-2xl shadow-xl bg-white w-full'>
      {showPopup && (
        <PopupComponent
          header={translationService.translate(
            TranslationCategories.Errors,
            'Information'
          )}
          description={
            translationService.translate(
              TranslationCategories.Popup,
              'The invitation has been sent successfully.'
          )}
          onClose={() => props.onInvite()}
          buttonText={'Accept'}
        />
      )}
      <div className='flex flex-col items-center w-full'>
        <p className='w-full text-xl font-semibold text-black pb-2 text-start'>
          {translationService.translate(
            TranslationCategories.Dashboard,
            'Add an employee to a department',
          )}
        </p>
        <AuthInputField
          icon={UserIcon}
          textInput={translationService.translate(
            TranslationCategories.Dashboard,
            'Employee name',
          )}
          textContentType={'text'}
          error={errors?.firstname}
          value={empForm.firstname}
          onChange={value => changeValue('firstname', value)}
        />
        <AuthInputField
          icon={UserIcon}
          textInput={translationService.translate(
            TranslationCategories.Dashboard,
            'Employee last name',
          )}
          textContentType={'text'}
          error={errors?.lastname}
          value={empForm.lastname}
          onChange={value => changeValue('lastname', value)}
        />
        <AuthInputField
          icon={EmailIcon}
          textInput={'Email'}
          textContentType={'emailAddress'}
          error={errors?.email}
          value={empForm.email}
          onChange={value => changeValue('email', value)}
        />
        <DropdownComponent
          selectTitle={translationService.translate(
            TranslationCategories.Dashboard,
            'Department',
          )}
          defaultOption={translationService.translate(
            TranslationCategories.Dashboard,
            'Please select Department',
          )}
          options={departmentList}
          icon={DepartmentsIcon}
          onSelect={value => changeValue('department_id', value)}
        />
        {roles && (
          <DropdownComponent
            selectTitle={translationService.translate(
              TranslationCategories.Dashboard,
              'Role',
            )}
            defaultOption={translationService.translate(
              TranslationCategories.Dashboard,
              'Please select Role',
            )}
            options={roles}
            icon={DepartmentsIcon}
            onSelect={value => changeValue('role', value)}
          />
        )}
        <div className='pt-3'>
          <ButtonComponent
            title={translationService.translate(
              TranslationCategories.Dashboard,
              'Add employee',
            )}
            onClick={e => addEmployee(e)}
          />
        </div>
        <p
          style={{
            color: 'gray',
            fontSize: 12,
            fontStyle: 'italic',
            marginTop: 10,
          }}>
          *
          {translationService.translate(
            TranslationCategories.Dashboard,
            'Employee will receive an email with instructions to join WeVoice experience.',
          )}
          .
        </p>
      </div>
    </div>
  );
};
export default AddEmpoyeeComponent;
