import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

class CertificationService{

  downloadCertification(input: any){
    const certificationWidth: number = 1414;
    const certificationHeight: number = 2000;

    const pdf = new jsPDF('l', 'px', [certificationWidth, certificationHeight]);
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();

    html2canvas(input)
    .then((canvas) => {
      const imgData = canvas.toDataURL('image/png');

      const widthRatio = pageWidth / canvas.width;
      const heightRatio = pageHeight / canvas.height;
      const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

      const canvasWidth = canvas.width * ratio;
      const canvasHeight = canvas.height * ratio;

      const marginX = (pageWidth - canvasWidth) / 2;
      const marginY = (pageHeight - canvasHeight) / 2;

      pdf.addImage(imgData, 'PNG', marginX, marginY, canvasWidth, canvasHeight);
      pdf.save("certificado.pdf");
    });

    return {data: pdf, size: pdf.output('datauristring').length}
  }


}

const certificationService = new CertificationService();
export default certificationService;