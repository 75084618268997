import { ActivityResult, AssessmentResult } from '../../activities/models/AssessmentResult';
import Pagination from '../../common/models/Pagination';
import fetchService from '../../common/services/fetch.service';
import {Program, ProgramFilter} from '../models/Program';
import {UserProgram, UserSession} from '../models/UserProgram';
import {UserProgramFilter} from '../models/UserProgramFilter';

class ProgramService {
  // PROGRAMS

  getPrograms(filter: ProgramFilter, pagination: Pagination | null = null) {
    const url = fetchService.getURLWithParamsFromObject(
      '/api/journey/journeys',
      {...filter, ...pagination},
    );

    return fetchService
      .fetchApi<Program[]>(url, {
        method: 'GET',
      })
      .then(result => {
        return result.map(data => new Program(data));
      });
  }

  getProgram(id: string) {
    return fetchService
      .fetchApi<Program>(`/api/journey/journey/${id}`, {
        method: 'GET',
      })
      .then(result => {
        return new Program(result);
      });
  }

  getUserPrograms(
    filter: UserProgramFilter,
    pagination: Pagination | null = null,
  ) {
    const url = fetchService.getURLWithParamsFromObject(
      '/api/journey/journeys/my',
      {...filter, ...pagination},
    );

    return fetchService
      .fetchApi<Program[]>(url, {
        method: 'GET',
      })
      .then(result => {
        return result.map(data => new UserProgram(data));
      });
  }

  getUserProgram(programId: string) {
    const today = new Date().getTime() / 1000;
    return fetchService
      .fetchApi<UserProgram>(`/api/journey/journeys/my/${programId}?${today}`, {
        method: 'GET',
      })
      .then(result => {
        return new UserProgram(result);
      });
  }

  addProgram(programId: string) {
    return fetchService
      .fetchApi<UserProgram>(`/api/journey/${programId}/add`, {
        method: 'POST',
      })
      .then(result => {
        return new UserProgram(result);
      });
  }

  removeProgram(programId: string) {
    return fetchService.fetchApi<UserProgram>(
      `/api/journey/${programId}/remove`,
      {
        method: 'POST',
      },
    );
  }

  // SESSIONS

  startProgramSession(userProgramId: string) {
    return fetchService
      .fetchApi<UserSession>(`/api/journey/${userProgramId}/start`, {
        method: 'POST',
      })
      .then(result => {
        console.groupCollapsed('ProgramService: Start ProgramSession Response');
        console.log(result);
        console.groupEnd();

        return new UserSession(result);
      });
  }

  getUserSession(userSessionId: string) {
    const url = `/api/journey/session/${userSessionId}`;

    return fetchService
      .fetchApi<UserSession[]>(url, {
        method: 'GET',
      })
      .then(result => {
        return new UserSession(result);
      });
  }

  // ACTIVITIES

  tryActivity(id: string) {
    return fetchService
      .fetchApi<UserSession>(`/api/journey/try/activity/${id}`, {
        method: 'POST',
      })
      .then(data => {
        console.groupCollapsed('ProgramService: Try Activity');
        console.log(data);
        console.groupEnd();

        return new UserSession(data);
      });
  }

  completeActivity(id: string, result: any): Promise<UserSession> {
    return fetchService
      .fetchApi<UserSession>(`/api/journey/activity/${id}/complete`, {
        method: 'POST',
        body: JSON.stringify(result),
      })
      .then(s => new UserSession(s));
  }

  getActivityResults(id: string): Promise<AssessmentResult> {
    return fetchService
      .fetchApi<AssessmentResult>(`/api/journey/activity/${id}/results`, {
        method: 'GET',
      })
      .then(data => new AssessmentResult(data));
  }
  getActivityResultsNew(id: string): Promise<ActivityResult> {
    return fetchService
      .fetchApi<ActivityResult>(`/api/journey/activity/${id}/results/new`, {
        method: 'GET',
      })
      .then(data => new ActivityResult(data));
  }
}

const programService = new ProgramService();
export default programService;
