import {Activity} from './Activity';

export enum ExerciseCommandType {
  Wait = 'wait',
  Instruct = 'instruct',
  Approve = 'approve',
  RepeatStart = 'repeat_start',
  RepeatEnd = 'repeat_end',
  BreathIn = 'breath_in',
  BreathOut = 'breath_out',
  AfterBreathInWait = 'hold_after_breath_in',
  AfterBreathOutWait = 'hold_after_breath_out',
}

export class ExerciseCommand {
  type!: ExerciseCommandType;
  delayMs?: number;
  instruction?: string;
  repeat?: number;
  image?: string;
  text?: string;

  constructor(props: any) {
    Object.assign(this, props);
    if (this.delayMs) {
      this.delayMs = +this.delayMs;
    }
  }
}

export enum ExerciseType {
  Guided = 'guided',
  Breathing = 'breathing',
  Reflection = 'reflection',
  Education = 'education',
  EmotionalTracking = 'emotional_tracking',
}

export class Exercise extends Activity {
  commands: ExerciseCommand[] = [];

  constructor(props: any) {
    super(props);
    Object.assign(this, props);
    this.commands = (props.commands || []).map(
      (c: any) => new ExerciseCommand(c),
    );
  }
}

export enum BreathingTypes {
  BCT_Button = 'bct_button',
  BCT_Swipe = 'bct_swipe',
  BCT_Tap = 'bct_tap',
}
