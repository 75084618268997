import React, {useCallback, useEffect, useRef, useState} from 'react';
import './DashboardScreen.scss';
import dashboardService from '../services/DashboardService';

const DashboardScreen = () => {
  const [dashboardLink, setDashboardLink] = useState<{link: string}>();
  const [iframe, setIframe] = useState<HTMLIFrameElement | null>(null);

  const loadDashboardLink = useCallback(() => {
    dashboardService.getDashboardLink().then(setDashboardLink);
  }, []);

  useEffect(() => {
    loadDashboardLink();
  }, [loadDashboardLink]);

  useEffect(() => {
    handleIframeContent();
  }, [iframe]);

  const handleIframeContent = async () => {
    if (iframe == null) {
      return;
    }

    await waitForDocumentReady(iframe);

    if (iframe.contentDocument == null) {
      return;
    }

    const style = iframe.contentDocument.createElement('style');
    style.id = 'injected-style';

    style.innerHTML = `
    #reactRoot > .grafana-app header,
    #reactRoot > .grafana-app > :first-child > .sidemenu {
      display: none !important;
    }
    `;

    iframe.contentDocument.head.append(style);

    function waitForDocumentReady(
      document: HTMLDocument | HTMLIFrameElement,
      options: {waitAgainForLoad?: number} = {},
    ) {
      const {waitAgainForLoad = null} = options;

      return new Promise((resolve, reject) => {
        let doc: HTMLDocument = document as HTMLDocument;

        if (document instanceof HTMLIFrameElement) {
          if (document.contentDocument == null) {
            reject(new Error(`Cannot access 'iframe.contentDocument'`));
            return;
          }

          doc = document.contentDocument;
        } else if (!(document instanceof Document)) {
          reject(
            new TypeError(
              `param must be either a Document or a HTMLIFrameElement`,
            ),
          );
          return;
        }
        if (
          doc.readyState === 'complete' &&
          doc.location != null &&
          doc.location.origin !== 'null'
        ) {
          resolve(document);

          return;
        }

        if (document instanceof HTMLIFrameElement) {
          document.addEventListener(
            'load',
            event => {
              if (typeof waitAgainForLoad === 'number') {
                setTimeout(() => resolve(document), waitAgainForLoad);

                document.addEventListener(
                  'load',
                  event => {
                    resolve(document);
                  },
                  {once: true},
                );
                return;
              }

              resolve(document);
            },
            {once: true},
          );

          return;
        }

        const listenerCallback = () => {
          if (doc.readyState !== 'complete') return;

          doc.removeEventListener('readystatechange', listenerCallback);

          doc.addEventListener(
            'DOMContentLoaded',
            () => {
              resolve(document);
            },
            {once: true},
          );
        };

        doc.addEventListener('readystatechange', listenerCallback);
      });
    }
  };

  console.log('Link', dashboardLink);
  return (
    <div className="dashboard_container">
      {dashboardLink && (
        <iframe
          ref={iframe => setIframe(iframe)}
          src={dashboardLink.link}
          width="100%"
          height="100%"
          frameBorder="0"
        ></iframe>
      )}
    </div>
  );
};

export default DashboardScreen;
