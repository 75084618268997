import {ExerciseComponentProps} from "../models/Props";
import React, {useState} from "react";
import {Emotion} from "../models/Emotion";
import {CheckupForm} from "../models/CheckupForm";
import checkupService from "../services/checkup.service";
import EmotionComponent from "./EmotionComponent/EmotionComponent";
import translationService from "../../translation/services/translation.service";
import {TranslationCategories} from "../../common/models/TranslationCategories";
import ButtonComponent from "../../common/components/ButtonComponent";
import {TrackerEventType} from "../../tracking/models/TrackerEventType";
import trackerService from "../../tracking/services/tracker.service";

const EmotionalTrackingExerciseComponent = (props: ExerciseComponentProps) => {
  console.log('EmotionalTrackingExerciseComponent');
  const [emo, setEmo] = useState<Emotion>();
  const [loading, setLoading] = useState(false);

  const onEmotionChange = (emotion: Emotion) => {
    setEmo(emotion);
  };
  const saveEmo = () => {
    if (emo) {
      const form = new CheckupForm();
      form.emotion = emo;
      setLoading(true);
      checkupService.checkup(form).then(() => {
        trackerService.triggerEvent(TrackerEventType.EmoCheckup, {
          arousal: form.emotion?.arousal,
          valence: form.emotion?.valence,
        });
        setLoading(false);
        props.onEnd(emo);
      });
    } else {
      props.onEnd();
    }
  };

  return (
    <div className='w-[400px] h-[400px]'>
      <EmotionComponent onChoose={onEmotionChange} />
      <ButtonComponent
        title={translationService.translate(
          TranslationCategories.Emotions,
          'Continue',
        )}
        onClick={(e) => saveEmo()}
      />
    </div>
  );
};

export default EmotionalTrackingExerciseComponent;
