import permissionService from "../modules/auth/services/permissions.service";
import {Permissions} from '../modules/auth/models/User';
import {ReactComponent as DashboardIcon} from '../assets/iconset/stats-icon.svg';
import {ReactComponent as ChallengesIcon} from '../assets/iconset/settings-icon.svg';
import {ReactComponent as BuildingIcon} from '../assets/iconset/building-icon.svg';
import {ReactComponent as DepartmentsIcon} from '../assets/iconset/flower-icon.svg';
import {ReactComponent as ClipboardIcon} from '../assets/iconset/clipboard-icon.svg';

const MenuTabs = [
    /* {
        href: '/dashboard',
        ui: 'Dashboard',
        permission: () => permissionService.checkUserPermission(
            Permissions.company_dashboard_read,
        ),
        icon: DashboardIcon,
    }, */
    {
        href: '/departments',
        ui: 'Department Manager',
        permission: () => permissionService.checkUserPermission(
            Permissions.company_create_departments,
        ),
        icon: DepartmentsIcon,
    },
    {
        href: '/challenges/new',
        ui: 'Challenges',
        permission: () => permissionService.checkUserPermission(
            Permissions.company_challenge_create,
        ),
        icon: ChallengesIcon,
    },
    {
        href: '/programs',
        ui: 'Company Programs',
        permission: () => true,
        icon: BuildingIcon,
    },
    {
        href: '/dashboard/programs',
        ui: 'Programs Dashboard',
        permission: () => permissionService.checkUserPermission(
            Permissions.company_dashboard_read,
        ),
        icon: ClipboardIcon,
    },
];

export default MenuTabs;
