import React, {useState} from 'react';
import './LoginScreen.scss';
import authService from '../../services/auth.service';
import {Link, useNavigate} from 'react-router-dom';
import {EmailLoginData, LoginForm} from '../../models/LoginForm';
import {AuthProvider} from '../../models/AuthProvider';
import translationService from '../../../translation/services/translation.service';
import {TranslationCategories} from '../../../common/models/TranslationCategories';
import WeVoiceLogo from '../../../../assets/minimalist-logo-wevoice.png';
import AuthInputField from '../../components/AuthInputField';
import EmailIcon from '../../../../assets/iconset/email-icon.svg';
import PasswordIcon from '../../../../assets/iconset/password-icon.svg';
import ButtonComponent from '../../../common/components/ButtonComponent';
import userStoreService from "../../services/user-store.service";

function LoginScreen() {
  const [formState, setFormState] = useState(new EmailLoginData('', ''));
  const [errors, setErrors] = useState<{[name: string]: string[]}>({});
  const navigate = useNavigate();

  const changeValue = (field: string, event: any) => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;
    setFormState({
      ...formState,
      [field]: value,
    });
  };

  const login = (form: LoginForm) => {
    authService
      .login(form)
      .then(result => {
        const user = userStoreService.getUser();
        if (user) {
          navigate(user.getHomeUrl(), {replace: true});
        }
      })
      .catch(exception => {
        console.log('Error', exception);
        if (exception.errors) {
          setErrors(exception.errors);
        }
      });
  };

  const submitForm = (event: any) => {
    event.preventDefault();
    const data = formState as EmailLoginData;
    login(new LoginForm(AuthProvider.Email, data));
  }

  return (
    <div className="bg-sunriseYellow-100 h-screen w-screen flex justify-center items-center">
      <div className='md:w-[642px] flex flex-col justify-center items-center'>
        <img src={WeVoiceLogo} alt='WeVoice logo' width={200} height={200}/>
        <h1 className='text-2xl font-semibold text-center mb-6'>
          {translationService.translate(
            TranslationCategories.Login,
            'Reinventing Workplace Wellness'
          )}
        </h1>
        <div className='bg-white p-4 w-full rounded-2xl mb-3'>
          <h2 className='text-lg font-bold mb-3'>
            {translationService.translate(
              TranslationCategories.Login,
              'Log in'
            )}
          </h2>
          <AuthInputField
            icon={EmailIcon}
            textInput={'Email'}
            textContentType={'emailAddress'}
            error={errors.email}
            value={formState.email}
            onChange={value => changeValue('email', value)}
          />
          <AuthInputField
            icon={PasswordIcon}
            textInput={translationService.translate(
              TranslationCategories.Login,
              'Password'
            )}
            textContentType={'password'}
            error={errors.password}
            value={formState.password}
            onChange={value => changeValue('password', value)}
          />
          <div className='w-full flex flex-col items-center pt-2'>
            <ButtonComponent
              title={translationService.translate(
                TranslationCategories.Login,
                  'Log in',
                )}
              onClick={(e) => submitForm(e)}
            />
            <p className='my-3 underline text-sm'>
              <Link to="/auth/reset">
                {translationService.translate(
                  TranslationCategories.Login,
                  'Forgot Password?',
                )}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginScreen;
