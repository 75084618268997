import React from 'react';
import textService from '../services/text.service';

interface DropdownComponentProps{
    selectTitle: string;
    defaultOption?: string;
    options: {label: string, value: any}[];
    icon: any;
    onSelect: (value: any) => void;
    error?: string | undefined;
}

const DropdownComponent = (props: DropdownComponentProps) => {

    const onOptionChangeHandler = (event: any) => {
        props.onSelect(event);
    }

    return (
        <div className='w-full mb-3'>
            <div className='flex flex-col items-start w-full'>
                <p className='text-sm font-semibold pb-2'>
                    {props.selectTitle}:
                </p>
                <div className='flex items-center bg-paleGrey rounded-xl py-2 px-3'>
                    <img src={props.icon} height={18} width={18}/>
                    <select onChange={onOptionChangeHandler} className='flex items-center bg-paleGrey rounded-xl text-sm italic pl-2'>
                        {props.defaultOption && <option>{props.defaultOption}</option>}
                        {props.options.map((option, index) => (
                            <option key={index} value={option.value}>
                                {textService.capitalizeFirstLetter(option.label)}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            {props.error ? (
                <p className='text-sm text-error'>{props.error}</p>
            ):null}
            
        </div>
    )
}

export default DropdownComponent