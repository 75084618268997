import {useEffect, useState} from 'react';
import {Program, ProgramSession} from '../../models/Program';
import programService from '../../services/ProgramService';
import Img from '../../../../components/Img/Img';
import {useNavigate, useParams} from 'react-router-dom';
import NotFound from '../../../common/components/NotFoundComponent/NotFoundComponent';
import errorService from '../../../common/services/error.service';
import {promiseThenHelper} from '../../../common/helpers/promise.helper';
import {UserProgram, UserSession} from '../../models/UserProgram';
import {TranslationCategories} from '../../../common/models/TranslationCategories';
import translationService from '../../../translation/services/translation.service';
import LoadingComponent from '../../../common/components/LoadingComponent/LoadingComponent';
import programUtils from '../../services/ProgramUtils';
import CertificationComponent from '../../components/CertificationComponent/CertificationComponent';
import {ReactComponent as BackIcon} from '../../../../assets/iconset/arrow-icon.svg'
import {ReactComponent as CheckIcon} from '../../../../assets/iconset/check-circle-icon.svg'

function ProgramScreen() {
  const [program, setProgram] = useState<Program>();
  const [userProgram, setUserProgram] = useState<UserProgram>();
  const [selectedSession, setSelectedSession] = useState<ProgramSession>();
  const selectedUserSession =
    userProgram?.getUserSessionFromProgramSession(selectedSession);
  const currentProgramSession = userProgram?.getNextProgramSession();
  const currentUserSession = userProgram?.getNextUserSession();
  const [isProgramFetched, setIsProgramFetched] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const {id} = useParams();
  const navigate = useNavigate();
  const [getCertification, setGetCertification] = useState<boolean>(false);
  const [hideSessions, setHideSessions] = useState<boolean>(false);
  const screenWidth = window.innerWidth;
  const coverImage = program?.with_certificate 
    ? require(`../../../../assets/certifications/${program.id}.jpg`) 
    : require('../../../../assets/common/placeholder.png');

  // Fetch program
  useEffect(() => {
    if (!id) return;

    const getAndSetProgram = async () => {
      const [fetchedData, fetchError] =
        await programUtils.fetchProgramAndUserProgram(id);

      setIsProgramFetched(true);

      if (fetchError) {
        errorService.addError(fetchError);
        return;
      }

      const [fetchedProgram, fetchedUserProgram] = fetchedData;

      setProgram(fetchedProgram);

      let selectedSession: ProgramSession = fetchedProgram.sessions[0];

      if (fetchedUserProgram) {
        setUserProgram(fetchedUserProgram);

        // If there isn't a next Session to do
        // then select the 1st Session
        selectedSession =
          fetchedUserProgram.getNextProgramSession() ?? selectedSession;
      }

      setSelectedSession(selectedSession);
    };

    getAndSetProgram();
  }, [id]);

  if (!id || !program) {
    return isProgramFetched ? 
      <NotFound /> :
      <div className='h-full w-full flex items-center justify-center'>
        <LoadingComponent />
      </div>;
  }

  // This line should never happen in normal conditions
  if (!selectedSession) {
    return (
      <NotFound
        message={[
          'No ProgramSession found to be selected',
          '[Program.sessions] may be empty',
        ]}
      />
    );
  }

  const sessionOnClick = (
    session: ProgramSession,
    userSession: UserSession | null,
    index: number,
  ) => {
    screenWidth < 768 ? setHideSessions(true) : setHideSessions(false);
    setSelectedSession(session);
  };

  const sessions = program.sessions.map((session, index) => {
    const userSession = userProgram?.sessions[index] ?? null;
    return (
      <div
        key={index}
        className='w-full p-3 border-b-2 border-midnight6 flex justify-between hover:bg-midnight6 hover:rounded-2xl items-center'
        onClick={() => sessionOnClick(session, userSession, index)}>
        <div className='flex flex-col justify-center'>
          <p className='text-lg md:text-sm font-semibold'>
            {session.name}
          </p>
          {session.duration_minutes ? (
            <p className='text-sm'>
              {translationService.translate(
                TranslationCategories.Programs,
                '{duration} minutes',
                {
                  duration: session.duration_minutes.toString()
                }
              )}
            </p>
          ): null}
        </div>
        <CheckIcon
          height={20}
          width={20}
          fill={userSession?.isCompleted() === true ? 'green' : 'black'}
        />
      </div>
    );
  });

  const startOrContinueLessonOnClick = async () => {
    if (!userProgram || currentUserSession?.isCompleted()) return;

    const [userSession, fetchError] = (await programService
      .startProgramSession(userProgram.id)
      .then(...promiseThenHelper)) as [UserSession, null] | [null, object];

    if (fetchError) {
      errorService.addError(fetchError);
      return;
    }
    navigate(`/programs/session/${userSession.id}`);
  };

  const joinProgramOnClick = async () => {
    setLoading(true);
    const userProgram = await programService.addProgram(program.id);
    setUserProgram(userProgram);
    const firstSession = program.sessions[0];
    if (firstSession) {
      setSelectedSession(firstSession);
    }
    setLoading(false);
  };

  const downloadCertification = () => {
    setGetCertification(true);
    setLoading(true);
  }

  return (
    <div className='flex flex-col items-start md:items-center lg:items-start pb-3 md:flex-row md:border-l-2 md:border-t-2'>
      <div className={hideSessions ?
        'bg-white rounded-br-2xl p-3 w-full md:w-72 shadow-md' :
        'flex flex-col items-center md:items-start bg-white rounded-br-2xl p-3 w-full md:w-72 lg:max-w-[350px] shadow-md'}>
        <div className={
          hideSessions ?
          'w-auto flex items-start justify-start' : 
          'pb-3 w-full flex items-center justify-start'}>
          <div
            className='p-2 flex items-center border-b-2 border-midnight6'
            onClick={() => setHideSessions(!hideSessions)}>
            <BackIcon height={20} width={20} className={hideSessions ? 'rotate-180' : 'rotate-0'}/>
            <p className='pl-2 text-sm md:text-lg md:w-32'>
              {translationService.translate(
                TranslationCategories.Programs,
                hideSessions ? 'Show sessions' : 'Hide sessions',
              )}
            </p>
          </div>
        </div>
        <div className={
          hideSessions ?
          'hidden' : 'flex flex-col'
        }>
          <Img
            draggable={false}
            src={coverImage}
            placeholder={coverImage}
            className='rounded-3xl max-h-64'
          />
          <div className='w-full pt-3'>
            <p className='text-md font-bold'>{program.name}</p>
            <p className='text-md pt-2'>{program.description}</p>
            {userProgram?.isCompleted() ? (
              <div className='py-3'>
                <p className='text-center text-sm mb-2'>
                  {translationService.translate(
                    TranslationCategories.Programs,
                    'You already completed the Program. Start next Program.',
                  )}
                </p>
                {userProgram?.journey.with_certificate ? (
                  <p className='text-darkBlue underline cursor-pointer text-sm text-center'
                    onClick={downloadCertification}>
                      {translationService.translate(
                        TranslationCategories.Programs,
                        'Click here to download your certification.',
                      )}
                  </p>
                ) : (
                  <p className='text-darkBlue text-sm text-center'>
                      {translationService.translate(
                        TranslationCategories.Programs,
                        'This course does not have certification.',
                      )}
                  </p>
                )}
              </div> 
              ) : !userProgram ? (
                <div className='flex justify-center pt-3'>
                  <button
                    className='bg-deepGreen-100 px-5 py-2 rounded-2xl text-white text-md font-semibold'
                    onClick={joinProgramOnClick}>
                      {translationService.translate(
                        TranslationCategories.Programs,
                        'Join Program',
                      )}
                  </button>
                </div>
              ) : null}
            <p className="text-lg font-semibold underline pt-4">
              {translationService.translate(
                TranslationCategories.Programs,
                'Sessions',
              )}
            </p>
          </div>
          <div className='w-full'>
            {sessions}
          </div>
        </div>
      </div>
      { selectedSession ? (
        <div className='w-full h-full pt-12 md:pt-0 flex flex-col items-center justify-center md:px-8'>
          <p className='text-center text-xl font-semibold'>
            {selectedSession.name}
          </p>
          <p className='pt-3 text-center text-md md:text-lg'>
            {selectedSession.description}
          </p>
          {selectedSession.duration_minutes && (
            <p className='pt-3 text-center text-sm md:text-xl'>
              {translationService.translate(
                TranslationCategories.Programs,
                '{duration} minutes',
                {
                  duration: selectedSession.duration_minutes.toString()
                }
              )}
            </p>
          )}
          {selectedUserSession?.isCompleted() ? (
            <p className='text-center text-xs pt-3 md:text-lg'>
              {translationService.translate(
                TranslationCategories.Programs,
                'Cannot start completed session. Go to the next one.',
              )}
            </p>
          ) : selectedSession.id !== currentProgramSession?.id && userProgram ? (
            <p className='text-center text-xs pt-3 md:text-lg'>
              {translationService.translate(
                TranslationCategories.Programs,
                'You must complete the previous sessions to start this one.',
              )}
            </p>
          ) : userProgram ? (
            <p
              className="text-center text-lg underline cursor-pointer pt-3"
              onClick={startOrContinueLessonOnClick}>
              {translationService.translate(
                TranslationCategories.Programs,
                selectedUserSession?.isStarted()
                  ? 'Continue Session'
                  : 'Start Session',
              )}
            </p>
          ) : null}
        </div>
      ) : (
        null
      )}
      {getCertification && userProgram?.isCompleted &&
        <CertificationComponent
          program={program}
          userProgram={userProgram}
          onCreate={() => {
            setGetCertification(false);
            setLoading(false)
          }}
        />
      }
    </div>
  );
}

export default ProgramScreen;
