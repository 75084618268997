import React, { useState } from 'react';
import PopupComponent from '../../common/components/PopupComponent/PopupComponent';
import {ReactComponent as FeedbackIcon} from '../../../assets/iconset/feedback-icon.svg';
import feedbackOptions from '../routes/routes';
import translationService from '../../translation/services/translation.service';
import { TranslationCategories } from '../../common/models/TranslationCategories';

const FeedbackComponent = () => {

  const [showPopUp, setShowPopUp] = useState<boolean>(false);
  return (
    <>
      <div className='hidden lg:block w-full h-fit'>
        <div className='absolute bottom-6 right-6 h-fit w-fit z-50 p-3 rounded-xl bg-white shadow-gray-300 shadow-lg flex flex-row items-center justify-center border' onClick={() => setShowPopUp(true)}>
          <p className='text-sm font-semibold'>
            {translationService.translate(
              TranslationCategories.Feedback,
              'Help us improve our services',
            )}
          </p>
          <FeedbackIcon height={24} />
        </div>
      </div>
      <div className='w-full h-9 lg:hidden p-3 flex flex-row items-center justify-end bg-halloweenOrange-50' onClick={() => setShowPopUp(true)}>
        <p className='text-sm font-medium mr-3 underline'>
          {translationService.translate(
            TranslationCategories.Feedback,
            'Help us improve our services',
          )}
        </p>
        <FeedbackIcon width={20}/>
      </div>
      {showPopUp && (
          <PopupComponent
            header={'Your feedback helps us to improve'}
            description={'We are grateful that you take the time to help us improve, please feel free to tell us anything.'}
            onClose={() => setShowPopUp(false)}>
              <div>
                <div className='flex flex-col items-center justify-center'>
                  {feedbackOptions.map((option, idx) => (
                    <button className='p-2 border border-deepGreen-100 active:border-deepGreen-50 rounded-xl flex items-center justify-center w-48 my-2'
                      onClick={() => window.open(option.link,"_blank")}>
                      <p className='text-deepGreen-100 text-sm'>
                        {translationService.translate(
                          TranslationCategories.Feedback,
                          option.title,
                        )}
                      </p>
                    </button>
                  ))}
                </div>
              </div>
          </PopupComponent>
        )}
    </>
  )
}

export default FeedbackComponent