import './NotFoundComponent.scss';

interface NotFoundProps {
  message?: string | string[] | null;
}

function NotFound(props: NotFoundProps) {
  const {message = null} = props;

  let uiMessage: React.ReactNode = 'NOT FOUND';

  if (message) {
    if (Array.isArray(message)) {
      uiMessage = message.map((m, index) => <div key={index}>{m}</div>);
    } else {
      uiMessage = message;
    }
  }

  return <div className="not-found-message">{uiMessage}</div>;
}

export default NotFound;
