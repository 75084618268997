export enum TranslationCategories {
  Common = 'common',
  Login = 'login',
  Registration = 'registration',
  Dashboard = 'dashboard',
  UserManagement = 'user_management',
  Challenges = 'challenges',
  Programs = 'programs',
  ProgramDashboard = 'program_dashboard',
  Sessions = 'sessions',
  Errors = 'errors',
  Menu = 'menu',
  ProgramsBackend = 'programs_backend',
  Popup = 'popup',
  AssessmentResult = "assessment_result",
  ResetPassword = 'reset_password',
  Emotions = 'emotions',
  Feedback = 'feedback'
}
