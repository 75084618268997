import authRoutes from '../modules/auth/routes';
import companyRoutes from '../modules/company/routes';
import challengesRoutes from '../modules/challenges/routes';
import programsRoutes from '../modules/programs/routes';
import dashboardRoutes from '../modules/dashboard/routes';

const routes = [
  ...dashboardRoutes,
  ...challengesRoutes,
  ...companyRoutes,
  ...authRoutes,
  ...programsRoutes,
];

export default routes;
