import React from 'react';
import translationService from '../../../translation/services/translation.service';
import {TranslationCategories} from '../../models/TranslationCategories';

interface PopupCompnentProps {
  header: string;
  description: string;
  buttonText?: string;
  onClose?: () => void;
  children?: any;
}
const PopupComponent = (props: PopupCompnentProps) => {
  return (
    <div className='w-screen h-screen p-3 bg-gray-400 bg-opacity-70 fixed top-0 left-0 flex items-center justify-center z-50'>
      <div className='flex flex-col items-center justify-center p-8 bg-white rounded-xl max-w-5xl'>
        <h3 className='text-md font-semibold'>
          {translationService.translate(
          TranslationCategories.Popup,
          props.header,
        )}
        </h3>
        <p className='py-3 text-center mb-3'>
          {translationService.translate(
            TranslationCategories.Popup,
            props.description,
          )}
        </p>
        {props.children}
        {props.onClose ? (
          <button
            className='bg-deepGreen-100 px-5 py-2 text-sm text-white rounded-xl mt-5'
            onClick={props.onClose}>
            {translationService.translate(
              TranslationCategories.Popup,
              props.buttonText || 'Close',
            )}
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default PopupComponent;
