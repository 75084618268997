import React from 'react';
import LoadingComponent from './LoadingComponent/LoadingComponent';

interface ButtonComponentProps {
    title: string;
    onClick: (e: any) => void;
    loading?: boolean;
    disabled?: boolean;
}
const ButtonComponent = (props: ButtonComponentProps) => {

  return (
    <button
      disabled={props.disabled}
      onClick={e => props.onClick(e)}
      className={'rounded-xl flex items-center justify-center bg-deepGreen-100 py-3 px-8 active:bg-deepGreen-50'}>
      {props.loading ? (
        <LoadingComponent />
      ) : (
        <p className='text-white font-semibold'>
          {props.title}
        </p>
      )}
    </button>
  )
}

export default ButtonComponent