import {useEffect, useState} from 'react';
import RouteListComponent from '../RouteListComponent/RouteListComponent';
import HeaderComponent from '../HeaderComponent/HeaderComponent';
import ErrorComponent from '../ErrorComponent/ErrorComponent';
import Sidebar from '../Sidebar/Sidebar';
import userStoreService from '../../../auth/services/user-store.service';

function LayoutComponent(props: any) {
  const isUserLogged = () => userStoreService.getUser() !== null;
  const [navState, setNavState] = useState({isHidden: !isUserLogged()});

  useEffect(() => {
    const removeListener = userStoreService.onUserChange(user =>
      setNavState({isHidden: !isUserLogged()}),
    );

    return () => removeListener();
  });

  return (
    <div className="w-screen min-h-screen bg-background">
      {!navState.isHidden && <HeaderComponent />}
      <div className='h-full w-full flex lg:fixed lg:top-0'>
        {!navState.isHidden && <Sidebar/>}
        <div className={!navState.isHidden ? 'lg:pt-28 w-full h-full overflow-hidden md:px-3' : 'w-full h-full overflow-hidden'}>
          <div className='w-full h-full'>
            <main className='h-full'>
              <RouteListComponent routes={props.routes} />
            </main>
            <ErrorComponent />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LayoutComponent;
