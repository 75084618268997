import {CFormTextarea} from '@coreui/react';
import React, {useRef, useState} from 'react';
import {InputComponentProps} from '../models/InputComponentProps';
import ButtonComponent from '../../common/components/ButtonComponent';
import translationService from '../../translation/services/translation.service';
import { TranslationCategories } from '../../common/models/TranslationCategories';

const TextComponent = (props: InputComponentProps) => {
  const [text, setText] = useState('');
  const [errors, setErrors] = useState<string | null>(null);
  const refInput = useRef<HTMLTextAreaElement>(null);

  const onAnswer = () => {
    if (text === '') {
      setErrors('Please fill in data!');
    } else {
      props.onAnswer(props.question.id, text);
      setText('');
      if (refInput.current) {
        refInput.current.value = '';
      }
    }
  };

  const onFormInputChange: React.ChangeEventHandler<
    HTMLTextAreaElement
  > = event => {
    setText(event.target.value);
  };

  return (
    <div>
      <p>{props.question.question}</p>

      <div className='w-full bg-white rounded-xl min-h-[200px] my-3'>
        <CFormTextarea
          ref={refInput}
          id="input"
          className='w-full h-[200px] p-3'
          placeholder="Escribe un comentario..."
          name="input"
          onChange={onFormInputChange}
        />
      </div>
      <p className='text-lg text-error'>
        {translationService.translate(
          TranslationCategories.Errors,
          errors || '',
        ) ?? ' '}
      </p>

      <div className='w-full flex justify-center mt-3'>
        <ButtonComponent
          title={translationService.translate(TranslationCategories.Sessions, 'Next')}
          onClick={onAnswer}
        />
      </div>
    </div>
  );
};

export default TextComponent;
