import {
  Challenge,
  ChallengeFilter,
  ChallengeForm,
  ChallengeType,
} from '../models/Challenge';
import fetchService from '../../common/services/fetch.service';
import {
  ChallengeGoal,
  ChallengeGoalFilter,
  ChallengeGoalForm,
} from '../models/ChallengeGoal';
import {
  ChallengeReward,
  ChallengeRewardFilter,
  ChallengeRewardForm,
} from '../models/ChallengeReward';
import {
  ChallengeRewardCondition,
  ChallengeRewardConditionFilter,
  ChallengeRewardConditionForm,
} from '../models/ChallengeRewardCondition';
import formService from '../../common/services/form.service';

class ChallengeService {
  getChallenges(filter: ChallengeFilter): Promise<Challenge[]> {
    return fetchService
      .fetchApi<Challenge[]>('/api/company/challenges', {
        method: 'GET',
      })
      .then(data => {
        return data.map(d => new Challenge(d));
      });
  }

  getChallenge(id: string): Promise<Challenge> {
    return fetchService
      .fetchApi<Challenge>(`/api/company/challenge/${id}`, {
        method: 'GET',
      })
      .then(data => {
        return new Challenge(data);
      });
  }

  createChallenge(form: ChallengeForm): Promise<Challenge> {
    return fetchService.fetchApi<Challenge>(`/api/company/challenge`, {
      method: 'POST',
      body: formService.convertObjectIntoFormData(form),
      headers:{
      }
    });
  }

  updateChallenge(id: string, form: ChallengeForm): Promise<Challenge> {
    return fetchService.fetchApi<Challenge>(`/api/company/challenge/${id}`, {
      method: 'PATCH',
      body: formService.convertObjectIntoFormData(form),
      headers:{}
    });
  }

  deleteChallenge(id: string): Promise<any> {
    return fetchService.fetchApi<Challenge>(`/api/company/challenge/${id}`, {
      method: 'DELETE',
    });
  }

  getGoals(filter: ChallengeGoalFilter): Promise<ChallengeGoal[]> {
    return fetchService
      .fetchApi<ChallengeGoal[]>('/api/company/challenges/goals', {
        method: 'GET',
      })
      .then(data => {
        return data.map(d => new ChallengeGoal(d));
      });
  }

  getGoal(id: string): Promise<ChallengeGoal> {
    return fetchService.fetchApi<ChallengeGoal>(
      `/api/company/challenges/goal/${id}`,
      {
        method: 'GET',
      },
    );
  }

  createGoal(form: ChallengeGoalForm): Promise<ChallengeGoal> {
    return fetchService.fetchApi<ChallengeGoal>(
      `/api/company/challenges/goal`,
      {
        method: 'POST',
        body: JSON.stringify(form),
      },
    );
  }

  updateGoal(id: string, form: ChallengeGoalForm): Promise<ChallengeGoal> {
    return fetchService.fetchApi<ChallengeGoal>(
      `/api/company/challenges/goal/${id}`,
      {
        method: 'PATCH',
        body: JSON.stringify(form),
      },
    );
  }

  getRewards(filter: ChallengeRewardFilter): Promise<ChallengeReward[]> {
    return fetchService
      .fetchApi<ChallengeReward[]>('/api/company/challenges/rewards', {
        method: 'GET',
      })
      .then(data => {
        return data.map(d => new ChallengeReward(d));
      });
  }

  getReward(id: string): Promise<ChallengeReward> {
    return fetchService.fetchApi<ChallengeReward>(
      `/api/company/challenges/reward/${id}`,
      {
        method: 'GET',
      },
    );
  }

  createReward(form: ChallengeRewardForm): Promise<ChallengeReward> {
    return fetchService.fetchApi<ChallengeReward>(
      `/api/company/challenges/reward`,
      {
        method: 'POST',
        body: formService.convertObjectIntoFormData(form),
        headers:{}
      },
    );
  }

  updateReward(
    id: string,
    form: ChallengeRewardForm,
  ): Promise<ChallengeReward> {
    return fetchService.fetchApi<ChallengeReward>(
      `/api/company/challenges/reward/${id}`,
      {
        method: 'PATCH',
        body: formService.convertObjectIntoFormData(form),
        headers:{}
      },
    );
  }

  getRewardConditions(
    filter: ChallengeRewardConditionFilter,
  ): Promise<ChallengeRewardCondition[]> {
    return fetchService
      .fetchApi<ChallengeRewardCondition[]>(
        '/api/company/challenges/conditions',
        {
          method: 'GET',
        },
      )
      .then(data => {
        return data.map(d => new ChallengeRewardCondition(d));
      });
  }

  getRewardCondition(id: string): Promise<ChallengeRewardCondition> {
    return fetchService.fetchApi<ChallengeRewardCondition>(
      `/api/company/challenges/condition/${id}`,
      {
        method: 'GET',
      },
    );
  }

  createRewardCondition(
    form: ChallengeRewardConditionForm,
  ): Promise<ChallengeRewardCondition> {
    return fetchService.fetchApi<ChallengeRewardCondition>(
      `/api/company/challenges/condition`,
      {
        method: 'POST',
        body: JSON.stringify(form),
      },
    );
  }

  updateRewardCondition(
    id: string,
    form: ChallengeRewardConditionForm,
  ): Promise<ChallengeRewardCondition> {
    return fetchService.fetchApi<ChallengeRewardCondition>(
      `/api/company/challenges/condition/${id}`,
      {
        method: 'PATCH',
        body: JSON.stringify(form),
      },
    );
  }

  validateChallengeForm(form: ChallengeForm) {
    const validations = {
      name: '',
      description: '',
      image: '',
      type: '',
      departments: '',
      goal: '',
      reward: '',
      rewardCondition: '',
      startDate: '',
      endDate: '',
      duration: ''
    };
    let isValid = true;
    if (!form.name || form.name.trim().length === 0) {
      validations.name = 'Name is required.';
      isValid = false;
    }
    if (!form.description || form.description.trim().length === 0) {
      validations.description = 'Description is required.';
      isValid = false;
    }
    if (!form.image) {
      validations.image = 'Image is required.';
      isValid = false;
    }
    if (!form.type) {
      validations.type = 'Type of challenge is required.';
      isValid = false;
    }
    if (form.type === ChallengeType.Department && !form.department_ids[0]) {
      validations.type = 'It is necessary to select a department.';
      isValid = false;
    }
    if (!form.challenge_goal_id) {
      validations.goal = 'Goal of challenge is required.';
      isValid = false;
    }
    if (!form.challenge_reward_id) {
      validations.reward = 'Reward is required.';
      isValid = false;
    }
    if (!form.challenge_reward_condition_id) {
      validations.rewardCondition = 'Reward condition is required.';
      isValid = false;
    }
    if (!form.ended_at) {
      validations.endDate = 'Select end date.';
      isValid = false;
    }
    if (!form.started_at) {
      validations.startDate = 'Select start date.';
      isValid = false;
    }
    return {isValid: isValid, errors: validations};
  }

  validateGoalForm(form: ChallengeGoalForm) {
    const validations = {
      value: '',
    };
    let isValid = true;
    if (!form.value) {
      validations.value = 'Value is required.';
      isValid = false;
    }
    return {isValid: isValid, errors: validations};
  }
  validateRewardForm(form: ChallengeRewardForm) {
    const validations = {
      name: '',
      description: '',
      image: '',
    };
    let isValid = true;
    if (!form.name || form.name.trim().length === 0) {
      validations.name = 'Name is required.';
      isValid = false;
    }
    if (!form.description || form.description.trim().length === 0) {
      validations.description = 'Description is required.';
      isValid = false;
    }
    if (!form.image) {
      validations.image = 'Image is required.';
      isValid = false;
    }
    return {isValid: isValid, errors: validations};
  }
}

const challengeService = new ChallengeService();
export default challengeService;
