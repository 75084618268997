export class ChallengeRewardCondition {
  id!: string;
  is_for_department!: boolean;
  is_for_team!: boolean;
  top_amount!: number;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;

  constructor(props: any = {}) {
    Object.assign(this, props);
  }

  getName() {
    return this.is_for_department
      ? 'Department'
      : this.is_for_team
      ? 'Team'
      : /* this.top_amount */'All';
  }
}

export class ChallengeRewardConditionFilter {}

export class ChallengeRewardConditionForm {
  is_for_department!: boolean;
  is_for_team!: boolean;
  top_amount!: number;

  constructor(props: any = {}) {
    Object.assign(this, props);
  }
}
