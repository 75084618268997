import React, {useCallback, useEffect, useState} from 'react';
import {
  ChallengeReward,
  ChallengeRewardFilter,
  ChallengeRewardForm,
} from '../../models/ChallengeReward';
import challengeService from '../../services/challenge.service';
import timeService from '../../../common/services/time.service';
import LoadingComponent from '../../../common/components/LoadingComponent/LoadingComponent';
import translationService from '../../../translation/services/translation.service';
import {TranslationCategories} from '../../../common/models/TranslationCategories';
import permissionService from '../../../auth/services/permissions.service';
import {Permissions} from '../../../auth/models/User';
import ButtonComponent from '../../../common/components/ButtonComponent';
import {ReactComponent as EditIcon} from '../../../../assets/iconset/edit-icon.svg';
import AuthInputField from '../../../auth/components/AuthInputField';
import SettingsIcon from '../../../../assets/iconset/settings-icon.svg';



const RewardFormComponent = () => {
  const [id, setId] = useState<string>();
  const [form, setForm] = useState<ChallengeRewardForm>(
    new ChallengeRewardForm(),
  );
  const [rewards, setRewards] = useState<ChallengeReward[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [errors, setErrors] = useState<{
    name: string;
    description: string;
    image: string;
  }>();
  const canUpdate = permissionService.checkUserPermission(
    Permissions.company_challenge_rewards_create,
  );

  const loadRewards = useCallback(() => {
    const rewardsFilter = new ChallengeRewardFilter();
    challengeService.getRewards(rewardsFilter).then(res => {
      setRewards(res);
      setLoading(false);
    });
  }, [loading]);

  useEffect(() => {
    loadRewards();
  }, [loadRewards, loading]);

  useEffect(() => {
    if (id) {
      challengeService.getReward(id).then(reward => {
        setForm(new ChallengeRewardForm(reward));
      });
    }
  }, [id, loading]);

  const changeValue = (field: string, event: any) => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.type === 'file'
        ? event.target.files[0]
        : event.target.value;
    setForm({
      ...form,
      [field]: value,
    });
  };

  const saveReward = () => {
    const validation = challengeService.validateRewardForm(form);
    if (validation.isValid) {
      (id
        ? challengeService.updateReward(id, form)
        : challengeService.createReward(form)
      )
        .then(() => {
          setId(undefined);
          setForm(new ChallengeRewardForm());
          setLoading(true);
        })
        .catch(error => {
          console.log('Could not save condition', id, form, error);
        });
    } else {
      setErrors(validation.errors);
    }
  };

  return (loading ? (
    <div className='h-screen w-full flex justify-center'>
      <LoadingComponent text={''} fullscreen={false} smooth={true} />
    </div>
  ) : (
    <div className='w-full flex flex-col items-center gap-8 md:flex-row md:items-start md:gap-16'>
      <div className={!canUpdate ? 'hidden' : 'w-full max-w-[642px] bg-white rounded-2xl p-3 md:p-7 shadow-xl'}>
        <p className='text-2xl font-semibold pb-3'>
          {translationService.translate(
            TranslationCategories.Challenges,
            'Create a Reward',
          )}
        </p>
        <AuthInputField
          icon={SettingsIcon}
          textInput={translationService.translate(
            TranslationCategories.Challenges,
            'Reward name',
          )}
          textContentType={'text'}
          error={errors?.name}
          value={form.name}
          onChange={value => changeValue('name', value)}
        />
        <AuthInputField
          icon={SettingsIcon}
          textInput={translationService.translate(
            TranslationCategories.Challenges,
            'Reward description',
          )}
          textContentType={'text'}
          error={errors?.description}
          value={form.description}
          onChange={value => changeValue('description', value)}
        />
        <div className='w-full pb-3'>
          <p className='text-sm font-semibold pb-2'>
            {translationService.translate(
              TranslationCategories.Challenges,
              'Add a picture',
            )}
          </p>
          <div className='w-full h-[100px] md:h-[150px] flex items-center justify-center bg-gainsboro-100 rounded-xl'>
            <label className="block">
              <input
                data-testid='input-image'
                onChange={(value: any) => changeValue('image', value)}
                type='file'
                accept="image/png, image/jpeg"
                className='block w-full text-sm text-slate-500
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-full file:border-0
                  file:text-sm file:font-semibold
                  file:bg-white file:text-deepGreen-50
                  hover:file:bg-deepGreen-50
                  hover:file:text-white'
              />
            </label>
            {errors?.image && (
              <p className='text-sm text-error'>
                {translationService.translate(
                  TranslationCategories.Errors,
                  errors?.image,
                )}
              </p>
            )}
          </div>
        </div>
        <div className='pt-3 w-full flex justify-center'>
          <ButtonComponent
            title={translationService.translate(
              TranslationCategories.Challenges,
              'Save challenge',
            )}
            onClick={e => saveReward()}
          />
        </div>
      </div>
      <div className='w-full bg-white p-3 md:p-6 rounded-xl shadow-xl md:order-1 overflow-auto'>
        <p className='p-3 text-2xl font-semibold'>
          {translationService.translate(
              TranslationCategories.Challenges,
              'Rewards',
            )}
        </p>
        <table className='w-full mt-3'>
          <thead>
            <tr>
              <th className='text-left text-sm font-semibold tracking-wide p-3'>
                {translationService.translate(
                  TranslationCategories.Challenges,
                  'Name',
                )}
              </th>
              <th className='text-left text-sm font-semibold tracking-wide p-3 '>
                {translationService.translate(
                  TranslationCategories.Challenges,
                  'Description',
                )}
              </th>
              <th className='text-left text-sm font-semibold tracking-wide p-3 '>
                {translationService.translate(
                  TranslationCategories.Challenges,
                  'Creation Date',
                )}
              </th>
              <th className='text-left text-sm font-semibold tracking-wide p-3'>
                {translationService.translate(
                  TranslationCategories.Challenges,
                  'Last Update',
                )}
              </th>
              <th className={!canUpdate ? 'hidden' : 'p-3'}/>
            </tr>
          </thead>
          <tbody>
            {rewards?.map((reward, idx) => (
              <tr className={idx%2 ===0 ? 'bg-white' : 'bg-deepGreen-100 bg-opacity-5'} key={idx}>
                <td className='w-full text-sm p-3'>{reward.name}</td>
                <td className='w-full text-sm p-3'>{reward.description}</td>
                <td className='w-full text-sm p-3'>
                  {reward.created_at
                  ? timeService.formatDateToString(reward.created_at)
                  : null}
                </td>
                <td className='w-full text-sm p-3'>
                  {reward.updated_at
                  ? timeService.formatDateToString(reward.updated_at)
                  : 'No updated'}
                </td>
                <td className={!canUpdate ? 'hidden' : 'w-full'}>
                  <EditIcon height={20} onClick={() => setId(reward.id)}/>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
  );
};

export default RewardFormComponent;
