import React, {useState} from 'react';
import LoadingComponent from '../../common/components/LoadingComponent/LoadingComponent';
import {Link, useParams} from 'react-router-dom';
import CreateChallengeFormComponent from '../components/CreateChallengeFormComponent/CreateChallengeFormComponent';
import GoalFormComponent from '../components/GoalFormComponent/GoalFormComponent';
import RewardFormComponent from '../components/RewardFormComponent/RewardFormComponent';
import translationService from '../../translation/services/translation.service';
import {TranslationCategories} from '../../common/models/TranslationCategories';

const ChallengesScreen = () => {
  const {param} = useParams();
  const [loading, setLoading] = useState<boolean>(false);

  interface NavType {
    title: string;
    param: string;
    component: any;
  }
  const navItems: NavType[] = [
    {
      title: translationService.translate(
        TranslationCategories.Challenges,
        'Create Challenges',
      ),
      param: 'new',
      component: CreateChallengeFormComponent,
    },
    // {
    //   title: translationService.translate(
    //     TranslationCategories.Challenges,
    //     'Goals',
    //   ),
    //   param: 'goals',
    //   component: GoalFormComponent,
    // },
    {
      title: translationService.translate(
        TranslationCategories.Challenges,
        'Awards & rewards',
      ),
      param: 'rewards',
      component: RewardFormComponent,
    },
  ];

  const data = navItems.find((item, index) => item.param === param);
  const TabComponent = data?.component;

  return (loading ? (
      <div className='h-screen w-full flex justify-center'>
        <LoadingComponent text={''} fullscreen={false} smooth={true} />
      </div>
    ) : param ? (
      <div className='flex flex-col items-center lg:items-start pt-8 md:pt-12 px-2'>
        <div className='w-full md:w-auto md:flex md:items-'>
          <h1 className='w-full md:w-auto text-3xl font-semibold mb-5 md:mb-10'>
            {translationService.translate(
              TranslationCategories.Challenges,
              'Challenges',
            )}
          </h1>
          <div className='w-full bg-white p-3 rounded-xl shadow mb-4
            md:flex md:items-center md:p-0 md:w-auto md:self-center md:mb-8 md:shadow-lg md:ml-36'
          >
            {navItems.map((item, index) => (
              <div
                className={item.param === param ? 
                  'bg-deepGreen-50 bg-opacity-20 p-2 rounded-xl md:px-8' : 'bg-white p-2 rounded-xl md:px-5'} 
                key={index}>
                <Link
                  to={`/challenges/${item.param}`}
                  className={item.param === param ? 'text-deepGreen-100 font-semibold md:text-xl' : 'text-steel font-semibold md:text-xl'}
                >
                  {item.title}
                </Link>
              </div>
            ))}
          </div>
        </div>
        <TabComponent />
      </div>
    ) : null
  );
};

export default ChallengesScreen;
