import React from 'react';
import InfoCarouselComponent from '../../common/components/InfoCarouselComponent/InfoCarouselComponent';
import {ExerciseComponentProps} from '../models/Props';
import LoadingComponent from '../../common/components/LoadingComponent/LoadingComponent';

const EducationExerciseComponent = (props: ExerciseComponentProps) => {
  const steps = props.activity.commands.map(command => {
    return {
      imagePath: command.image,
      text: command.instruction || command.text || '',
    };
  });



  return (
    <>
      {steps.length !== props.activity.commands.length ? (
        <LoadingComponent />
        ) : (
        <InfoCarouselComponent steps={steps} onEnd={props.onEnd}/>
      )}
    </>
  );
};

export default EducationExerciseComponent;
