class MediaService {
  private videoExtensions = ['mp4'];
  private imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
  // private audioExtensions = ['mp3', 'm4a', 'wav'];

  private static getExtension(path: string) {
    const clearQuery = path.split("?")[0];
    const parts = clearQuery.split('.');
    return parts[parts.length - 1];
  }

  isLocalFile(path: string | number) {
    return typeof path !== 'string';
  }

  isVideo(path: string | number) {
    if (this.isLocalFile(path)) {
      return null; // if require used, not sure now how to get file
    }
    if (!path || path === '') {
      return false;
    }
    const extension = MediaService.getExtension(path as string);
    return this.videoExtensions.includes(extension);
  }

  isImage(path: string | number) {
    if (this.isLocalFile(path)) {
      return null; // if require used, not sure now how to get file
    }
    if (!path || path === '') {
      return false;
    }
    const extension = MediaService.getExtension(path as string);
    return this.imageExtensions.includes(extension);
  }

  getFileType(path: string) {
    const types: {[key: string]: string} = {
      "mp4": "video/mp4",
      "wav": "audio/wav",
      "mp3": "audio/mp3",
    }
    const extension = MediaService.getExtension(path);
    return extension in types ? types[extension] : "audio/wav";
  }
}

const mediaService = new MediaService();
export default mediaService;
