import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import LoadingComponent from '../../../common/components/LoadingComponent/LoadingComponent';
import authService from '../../services/auth.service';

const LogoutComponent = () => {
  const navigate = useNavigate();

  useEffect(() => {
    authService
      .logout()
      .then(result => {
        navigate('/auth/login', {replace: true});
      })
      .catch(exception => {
        console.log('Error', exception);
      });
  });

  return <LoadingComponent text={''} fullscreen={true} smooth={true} />;
};

export default LogoutComponent;
