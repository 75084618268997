import React, { useState } from 'react';
import { TranslationCategories } from '../../common/models/TranslationCategories';
import translationService from '../../translation/services/translation.service';
import HiddenIcon from '../../../assets/iconset/hidden-eye-icon.svg';
import VisibleIcon from '../../../assets/iconset/visible-eye-icon.svg';

interface AuthInputFieldProps {
    icon: any;
    textInput: string;
    textContentType?: 'emailAddress' | 'text' | 'password' | 'number';
    error?: any;
    value: string;
    onChange: (value: any) => void;
}

const AuthInputField = (props: AuthInputFieldProps) => {
    const [showPassword, setShowPassword] = useState(false);

    const switchPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className='w-full mb-3'>
            <div className='flex items-center bg-paleGrey rounded-xl py-2 px-3'>
                <img src={props.icon} height={18} width={18}/>
                <input
                    className="
                        placeholder:italic w-full focus:outline-none bg-paleGrey pl-2 text-xs md:text-base"
                    placeholder={props.textInput}
                    type={showPassword ? 'text' : props.textContentType}
                    value={props.value}
                    onChange={value => props.onChange(value)}
                />
                {props.textContentType === 'password' && (
                    <div onClick={() => switchPassword()}>
                        <img src={showPassword ? HiddenIcon : VisibleIcon} height={18} width={18}/>
                    </div>
                )}
            </div>
            {props.error && (
                <p className='text-error'>
                    {translationService.translate(
                        TranslationCategories.Errors,
                        props.error,
                    )}
                </p>
            )}
        </div>
    )
}

export default AuthInputField
