import React, { useState } from 'react';
import ButtonComponent from '../../../common/components/ButtonComponent';
import { TranslationCategories } from '../../../common/models/TranslationCategories';
import translationService from '../../../translation/services/translation.service';
import AuthInputField from '../../components/AuthInputField';
import WeVoiceLogo from '../../../../assets/minimalist-logo-wevoice.png';
import { RestoreForm } from '../../models/RestoreForm';
import TokenIcon from '../../../../assets/iconset/communication-numbers-icon.svg';
import { useNavigate, useParams } from 'react-router-dom';
import authService from '../../services/auth.service';
import PasswordIcon from '../../../../assets/iconset/password-icon.svg';

const RestorePasswordScreen = () => {
  const navigate = useNavigate();
  const {token} = useParams<{token?: string}>();
  const [form, setForm] = useState(new RestoreForm('', token));
  const [errors, setErrors] = useState<{[name: string]: string[]}>({});

  const changeValue = (field: string, event: any) => {
    const value = event.target.value;
    setForm({
      ...form,
      [field]: value,
    });
  };

  const restore = () => {
    authService
    .restorePassword(form.token || '', form)
    .then(() => {
        navigate('/', {replace: true});
    }).catch(exception => {
        exception.errors
        ? setErrors(exception.errors) 
        : setErrors({
            email: translationService.translate(
              TranslationCategories.ResetPassword,
              'Something went wrong!',
            ),
        });
    });
  };
  
  return (
    <div className="bg-sunriseYellow-100 h-screen w-screen flex justify-center items-center">
      <div className='md:w-[642px] flex flex-col justify-center items-center'>
        <img src={WeVoiceLogo} alt='WeVoice logo' width={200} height={200}/>
        <h1 className='text-2xl font-semibold text-center mb-6'>
          {translationService.translate(
            TranslationCategories.Login,
            'Reinventing Workplace Wellness.'
          )}
        </h1>
        <div className='bg-white p-4 w-full rounded-2xl mb-3'>
          <h2 className='text-lg font-bold mb-3'>
            {translationService.translate(
              TranslationCategories.ResetPassword,
              'Create new password'
            )}
          </h2>
          <p className='mb-3'>
            {translationService.translate(
              TranslationCategories.ResetPassword,
              'Please enter your new password that you will use to access WeVoice.',
            )}
          </p>
          
          <AuthInputField
            icon={TokenIcon}
            textInput={'Token'}
            textContentType={'text'}
            error={errors.token}
            value={form.token ?? ''}
            onChange={value => changeValue('token', value)}
          />
          <AuthInputField
            icon={PasswordIcon}
            textInput={translationService.translate(
              TranslationCategories.ResetPassword,
              'New password',
            )}
            textContentType={'password'}
            error={errors.password}
            value={form.password}
            onChange={value => changeValue('password', value)}
          />
          <div className='w-full flex flex-col items-center pt-2'>
            <ButtonComponent title={
              translationService.translate(
                TranslationCategories.ResetPassword,
                'Change password',
              )}
              onClick={restore}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RestorePasswordScreen
