import React from 'react'

interface EmailTemplateComponentProps {
    refCode: string;
    company_id: string;
    department_id: string;
}
const EmailTemplateComponent = (props: EmailTemplateComponentProps) => {
  const API_URL = process.env.REACT_APP_API_URL;

  const text = `
  <div>
        <p>¡No alegramos de verte en WeVoice!<br/><br/>
            Tu organización entiende cómo el estrés puede afectar nuestras vidas personales y profesionales, y por esta razón, desean ayudarte.
            Para que puedas empezar fácilmente, te ofrecen acceso a WeVoice para aprender y gestionar el estrés, así como mejorar tu bienestar mental.<br /><br />
            Podrás hacerlo cómodamente a través de cursos, ejercicios e información relevante sobre tu bienestar. <br /><br />
            Existen dos formas de utilizar WeVoice:
        </p>
        <img src='${API_URL}/api/welcome/id:${props.company_id};id2:${props.department_id}' alt="WeVoice Welcome" height="1px" width="1px" />
        <p><span style="font-weight: bold;">Plataforma Web:</span> Puedes comenzar tu viaje tomando nuestro curso experto en Gestión del Estrés.</p>
        <p><span style="font-weight: bold;">Aplicación móvil de WeVoice (opcional):</span> La aplicación lleva todo al siguiente nivel, ayudándote a gestionar muchos aspectos de tu salud a través de:</p>
        <ul style="list-style-type: disc">
            <li>Ejercicios de respiración, meditaciones, seguimiento emocional, reflexiones.</li>
            <li>Desafíos en equipo.</li>
            <li>Si tienes un reloj inteligente: datos adicionales y más precisos.</li>
        </ul>
        <br/>
        <p><span style="font-weight: bold;">¡La Privacidad es lo primero!</span> Somos una empresa que nos tomamos muy en serio la privacidad de las personas. ¡Nunca compartimos información de los usuarios con la empresa! Los datos siempre están anonimizados (= nunca vinculados a ti) y se agregan por equipo (solo los datos de equipo son visibles para la empresa, nunca los datos de los usuarios).</p>
        <br /><br />
        <p style="font-weight: bold;">Cómo empezar</p>
        <ul style="list-style-type: decimal">
            <li>Por favor, accede <a href='https://dashboard.wevoice.ai/auth/register' target='_blank'>aquí</a> para registrarte en la plataforma WeVoice.</li>
            <li>Para descargar la aplicación móvil, selecciona la opción aplicable para tu caso <a href="https://apps.apple.com/es/app/wevoice-ai-sleep-stress-focus/id1577589862" target='_blank'>IOS</a> / <a href="https://play.google.com/store/apps/details?id=com.wevoicemobile&pli=1">Android</a>.</li>
            <li>Utiliza este código de referencia de la empresa para unirte a la cuenta de tu empresa:</li>
        </ul>
        <p style="font-weight: bold; text-align: center;">${props.refCode}</p>
        <br />
        <p>
            ¡Eso es todo! ¡Ahora estás en tu camino para rebajar tus niveles de estrés y mejorar tu bienestar mental y emocional, y de esta forma, disfrutar más de tu vida!
            <br />
            <br />
            Gracias,
            <br />
            El equipo de <a href='https://www.wevoice.ai/' target='_blank'>WeVoice</a>.

        </p>
    </div>
  `;

  const copyText = () => {
    const item = new ClipboardItem({
      "text/plain": new Blob(
        [text],
        { type: "text/plain" }
      ),
      "text/html": new Blob(
        [text],
        { type: "text/html" }
      ),
    });
    navigator.clipboard.write([item]);
  };

  return (
    <div className='p-9 bg-slate-100 rounded-xl max-w-screen-sm text-sm max-h-96 overflow-auto' onCopy={copyText}>
        <p>
            ¡No alegramos de verte en WeVoice!<br/><br/>
            Tu organización entiende cómo el estrés puede afectar nuestras vidas personales y profesionales, y por esta razón, desean ayudarte.
            Para que puedas empezar fácilmente, te ofrecen acceso a WeVoice para aprender y gestionar el estrés, así como mejorar tu bienestar mental.<br /><br />
            Podrás hacerlo cómodamente a través de cursos, ejercicios e información relevante sobre tu bienestar. <br /><br />
            Existen dos formas de utilizar WeVoice:
        </p>
        <p><span className='font-semibold'>Plataforma Web:</span> Puedes comenzar tu viaje tomando nuestro curso experto en Gestión del Estrés.</p>
        <p><span className='font-semibold'>Aplicación móvil de WeVoice (opcional):</span> La aplicación lleva todo al siguiente nivel, ayudándote a gestionar muchos aspectos de tu salud a través de:</p>
        <ul className='list-disc ml-12'>
            <li>Ejercicios de respiración, meditaciones, seguimiento emocional, reflexiones.</li>
            <li>Desafíos en equipo.</li>
            <li>Si tienes un reloj inteligente: datos adicionales y más precisos.</li>
        </ul>
        <br/>
        <p><span className='font-semibold'>¡La Privacidad es lo primero!</span> Somos una empresa que nos tomamos muy en serio la privacidad de las personas. ¡Nunca compartimos información de los usuarios con la empresa! Los datos siempre están anonimizados (= nunca vinculados a ti) y se agregan por equipo (solo los datos de equipo son visibles para la empresa, nunca los datos de los usuarios).</p>
        <br /><br />
        <p className='font-semibold'>Cómo empezar</p>
        <ul className='list-decimal ml-4 mb-3'>
            <li>Por favor, accede <a className='text-softBlue underline' href='https://dashboard.wevoice.ai/auth/register' target='_blank'>aquí</a> para registrarte en la plataforma WeVoice.</li>
            <li>Para descargar la aplicación móvil, selecciona la opción aplicable para tu caso <a href="https://apps.apple.com/es/app/wevoice-ai-sleep-stress-focus/id1577589862" target='_blank' className='text-softBlue underline'>IOS</a> / <a href="https://play.google.com/store/apps/details?id=com.wevoicemobile&pli=1" className='text-softBlue underline'>Android</a>.</li>
            <li>Utiliza este código de referencia de la empresa para unirte a la cuenta de tu empresa:</li>
        </ul>
        <p className='w-full text-center font-semibold text-md'>{props.refCode}</p>
        <br />
        <p>
            ¡Eso es todo! ¡Ahora estás en tu camino para rebajar tus niveles de estrés y mejorar tu bienestar mental y emocional, y de esta forma, disfrutar más de tu vida!
            <br />
            <br />
            Gracias,
            <br />
            El equipo de <a className='text-softBlue underline' href='https://www.wevoice.ai/' target='_blank'>WeVoice</a>.

        </p>
    </div>
  )
}

export default EmailTemplateComponent
