import {User} from '../models/User';
import {
  EmailRegistrationData,
  RegistrationForm,
} from '../models/RegistrationForm';
import {LoginForm, LoginResponse} from '../models/LoginForm';
import fetchService from '../../common/services/fetch.service';
import userStoreService from './user-store.service';
import {ResetForm} from '../models/ResetForm';
import {RestoreForm} from '../models/RestoreForm';
import {TermForm} from '../models/TermForm';
import trackerService from "../../tracking/services/tracker.service";
import {TrackerEventType} from "../../tracking/models/TrackerEventType";

class AuthService {
  private saveUser(user: User | null) {
    return userStoreService.setUser(user).then(() => (user));
  }

  refreshUser(): Promise<User | null> {
    return fetchService
      .fetchApi<User>('/api/auth/user', {
        method: 'GET',
      })
      .then(response => {
        return this.saveUser(response);
      })
      .catch(() => {
        this.clearUser();
        return null;
      });
  }

  register(form: RegistrationForm): Promise<LoginResponse> {
    return fetchService
      .fetchApi('/api/auth/register', {
        method: 'POST',
        body: JSON.stringify(form),
      })
      .then((response: any) => { //refactor with logn, same logic here!
        const result = this.setUser(response);
        trackerService.triggerEvent(TrackerEventType.SignUp, {
          method: form.provider.toString()
        });
        return result;
      }).catch(err => {
        trackerService.triggerEvent(TrackerEventType.SignUp, {
          method: form.provider.toString(),
          errors: 1
        });
        return Promise.reject(err);
      });
  }

  private setUser(response: any) {
    fetchService.setAuthorization(response.token);
    return this.saveUser(response.user).then(user => {
      return new LoginResponse(user && user.fullname ? user.fullname : '', true);
    });
  }

  acceptTerms(form: TermForm): Promise<LoginResponse> {
    return fetchService
      .fetchApi('/api/auth/terms', {
        method: 'POST',
        body: JSON.stringify(form),
      })
      .then((response: any) => {
        return this.setUser(response);
      });
  }

  login(form: LoginForm): Promise<any> {
    return fetchService
      .fetchApi<Response>('/api/company/auth/login', {
        method: 'POST',
        body: JSON.stringify(form),
      })
      .then((response: any) => {
        const result = this.setUser(response);
        trackerService.triggerEvent(TrackerEventType.Login, {method: form.provider.toString()});
        return result;
      }).catch(err => {
        trackerService.triggerEvent(TrackerEventType.Login, {
          method: form.provider.toString(),
          errors: 1,
        });
        return Promise.reject(err);
      });
  }

  logout() {
    return fetchService
      .fetchApi<Response>('/api/company/auth/logout', {
        method: 'POST',
        body: '',
      })
      .then(() => {
        return this.clearUser();
      });
  }

  resetPassword(form: ResetForm) {
    return fetchService.fetchApi('/api/auth/reset', {
      method: 'POST',
      body: JSON.stringify(form),
    });
  }

  restorePassword(token: string, form: RestoreForm): Promise<any> {
    return fetchService
      .fetchApi(`/api/auth/restore?token=${token}`, {
        method: 'POST',
        body: JSON.stringify(form),
      })
      .then((response: any) => {
        this.setUser(response);
      });
  }

  private clearUser() {
    fetchService.clearAuthorization();
    trackerService.clearDefaultValues();
    return this.saveUser(null);
  }

  validateRegistrationForm(form: RegistrationForm) {
    const validations = {
      fullname: '',
      email: '',
      password: '',
      referral: '',
      is_terms_accepted: '',
    };
    let isValid = true;
    if (!form.data.fullname || form.data.fullname.trim().length === 0) {
      validations.fullname = 'Name is required.';
      isValid = false;
    }
    if (
      !form.data.email ||
      form.data.email.trim().length === 0 ||
      (form.data.email && !/\S+@\S+\.\S+/.test(form.data.email))
    ) {
      validations.email = 'Email format must be as example@mail.com';
      isValid = false;
    }
    if (!form.data.password || form.data.password.trim().length < 8) {
      validations.password = 'Password should be at least 8 characters';
      isValid = false;
    }
    if (!form.referral || form.referral.trim().length === 0) {
      validations.referral = 'Referral code is required.';
      isValid = false;
    }
    if (!form.data.is_terms_accepted) {
      validations.is_terms_accepted = 'Accept terms and conditions.';
      isValid = false;
    }

    return {isValid: isValid, errors: validations};
  }
}

const authService = new AuthService();
export default authService;
