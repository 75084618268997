import PopupComponent from "../PopupComponent/PopupComponent";
import translationService from "../../../translation/services/translation.service";
import {TranslationCategories} from "../../models/TranslationCategories";
import React from "react";

export interface ConfirmComponentProps {
  header: string;
  description: string;
  onConfirm: (confirm: boolean) => any
}

const ConfirmComponent = (props: ConfirmComponentProps) => {
  return (
    <PopupComponent header={props.header} description={props.description}>
      <div className={'flex'}>
        <button
          className='bg-deepGreen-100 px-5 py-2 text-sm text-white rounded-xl mt-5 mx-2'
          onClick={() => props.onConfirm(false)}>
          {translationService.translate(
            TranslationCategories.Popup,
            'No',
          )}
        </button>
        <button
          className='bg-halloweenOrange-100 px-5 py-2 text-sm text-white rounded-xl mt-5 mx-2'
          onClick={() => props.onConfirm(true)}>
          {translationService.translate(
            TranslationCategories.Popup,
            'Yes',
          )}
        </button>
      </div>
    </PopupComponent>
  );
};

export default ConfirmComponent;
