import './LoadingComponent.scss';

export interface LoadingComponentProps {
  text?: string;
  fullscreen?: boolean;
  smooth?: true;
}

function LoadingComponent(props: LoadingComponentProps) {
  const {text = '', fullscreen = false, smooth = false} = props;

  return (
    <div
      data-testid='loading-component' 
      className={'loading-component' + (fullscreen ? ' fullscreen' : '')}>
      <div className="progress"></div>
    </div>
  );
}

export default LoadingComponent;
