import {TranslationCategories} from '../../common/models/TranslationCategories';
import {TranslationLanguage} from '../../common/models/TranslationLanguages';
import loadTexts from '../load_texts';
import supportedLanguages from '../supported_languages';

class TranslationService {
  private texts: {[key: string]: {[key: string]: string}} = {};
  private defaultLanguage = 'es';
  private _language = this.getSelectedLanguage();

  init() {
    return this.loadTextTranslation();
  }

  private loadTextTranslation() {
    console.log('language detected:', this._language);
    this.texts = loadTexts(this._language.prefix);
    return Promise.resolve();
  }

  translate(
    category: TranslationCategories,
    text: string,
    params: {[name: string]: string} = {},
  ): string | any {
    const categoryText = this.texts[category];
    let translatedText = categoryText?.[text] ?? text;
    Object.entries(params).forEach(param => {
      translatedText = translatedText.replace(`{${param[0]}}`, param[1]);
    });
    return translatedText;
  }

  getCategory(category: TranslationCategories): any {
    return this.texts[category];
  }

  getSelectedLanguage(): TranslationLanguage {
    const selectedLanguage = this.defaultLanguage; //For now user cant select language
    const language = this.getSupportedLanguages().find(
      l => l.prefix === selectedLanguage,
    );
    if (language) {
      return language;
    } else {
      return this.getSupportedLanguagesMap()[this.defaultLanguage];
    }
  }

  private getSupportedLanguagesMap(): {[key: string]: TranslationLanguage} {
    return supportedLanguages;
  }
  getSupportedLanguages(): TranslationLanguage[] {
    return Object.values(this.getSupportedLanguagesMap());
  }
}

const translationService = new TranslationService();

export default translationService;
