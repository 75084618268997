import React, {useState} from 'react'

interface ToggleComponentProps{
    status: boolean;
    onClick: (e: any) => void;
}
const ToggleComponent = (props: ToggleComponentProps) => {
  return (
    <div 
      onClick={(e) => props.onClick(e)}
      className={props.status ?
        'bg-deepGreen-50 w-9 h-4 rounded-xl px-1 py-2 bg-opacity-50 flex items-center justify-end duration-300 hover:border-2 hover:border-solid hover:border-softBlue hover:duration-75'
        : 'bg-steel w-9 h-4 rounded-xl px-1 py-2 bg-opacity-50 flex items-center justify-start duration-300 hover:border-2 hover:border-solid hover:border-softBlue hover:duration-75'}>
        <div className={props.status ?
        'w-3 h-3 bg-deepGreen-100 rounded-full'
        : 'w-3 h-3 bg-black rounded-full'}/>
    </div>
  )
}

export default ToggleComponent