import userStoreService from '../../auth/services/user-store.service';
import DepartmentsScreen from '../screens/DepartmentsScreen';

const routes = [
  {
    path: '/departments',
    component: DepartmentsScreen,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null,
  },
];

export default routes;
