import React from 'react';
import {ExerciseType} from '../models/Exercise';
import {ExerciseComponentProps} from '../models/Props';
import EducationExerciseComponent from './EducationExerciseComponent';
import EmotionalTrackingExerciseComponent from "./EmotionalTrackingExerciseComponent";
import BreathingExerciseComponent from "./BreathinExerciseComponent";

const ExerciseComponent = (props: ExerciseComponentProps) => {
  let Component: any = null;
  switch (props.activity.exercise_type) {
    case ExerciseType.Breathing:
      Component = BreathingExerciseComponent;
      break;
    case ExerciseType.EmotionalTracking:
      Component = EmotionalTrackingExerciseComponent;
      break;
    case ExerciseType.Education:
      Component = EducationExerciseComponent;
      break;
    default:
      throw new Error(
        `Don't know how to render ${props.activity.exercise_type}`,
      );
  }
  return <Component {...props} />;
};

export default ExerciseComponent;
