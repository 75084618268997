import {User} from '../models/User';
import storeService from '../../common/services/store.service';
import trackerService from "../../tracking/services/tracker.service";

const USER_STORAGE_KEY = 'user_info';
const BEARER_STORAGE_KEY = 'bearer';

interface RemoveUserChangeListener extends Function {}

class UserStoreService {
  private token: string | null;
  private user: User | null;

  constructor() {
    this.user = storeService.get(USER_STORAGE_KEY);
    this.token = storeService.get(BEARER_STORAGE_KEY);
  }

  getToken(): string | null {
    return this.token;
  }

  setToken(value: string | null) {
    value
      ? storeService.put(BEARER_STORAGE_KEY, value)
      : storeService.remove(USER_STORAGE_KEY);
    this.token = value;
  }

  getUser(): User | null {
    return this.user === null ? this.user : new User(this.user);
  }

  setUser(value: User | null) {
    value
      ? storeService.put(USER_STORAGE_KEY, value)
      : storeService.remove(USER_STORAGE_KEY);
    this.user = value;

    if (value !== null) {
      this.setupTrackingForUser(value);
    } else {
      trackerService.clearDefaultValues();
    }

    return new Promise((resolve) => {
      setTimeout(() => {
        this.onUserChangeCallbacks.forEach(callback => callback(this.user));
        resolve(true);
      });
    });
  }

  private setupTrackingForUser(user: User) { //maybe move to events
    const data: {[key: string]: string} = {};
    if (user.id) {
      data.user_id = user.id;
      data.internal_id = user.id;
    }
    if (user.company_id) {
      data.company_id = user.company_id;
    }
    trackerService.setUserProperties(data);
  }

  private onUserChangeCallbacks: Set<(user: User | null) => void> = new Set();

  onUserChange(callback: (user: User | null) => any): RemoveUserChangeListener {
    this.onUserChangeCallbacks.add(callback);

    return () => {
      this.onUserChangeCallbacks.delete(callback);
    };
  }
}

const userStoreService = new UserStoreService();
export default userStoreService;
