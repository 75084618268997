import {Error} from '../models/error';

class ErrorService {
  listener: {[key: string]: (error: Error) => void} = {};

  onError(key: string, handler: (error: Error) => void) {
    this.listener[key] = handler;
  }

  removeOnError(key: string) {
    delete this.listener[key];
  }

  addError(error: any) {
    if (Array.isArray(error)) {
      this.addError(error[0]);
      return;
    } else if (typeof error === 'string') {
      const processedError = new Error(error);
      this.raiseError(processedError);
    }
  }

  raiseError(error: Error) {
    Object.keys(this.listener).forEach(listenerKey => {
      const listener = this.listener[listenerKey];
      listener(error);
    });
  }
}

const errorService = new ErrorService();

export default errorService;
