import React, {useCallback, useEffect, useRef} from 'react';
import {SoundTherapy} from '../../models/SoundTherapy';
import mediaService from "../../../common/services/media.service";

export interface SoundPlayerComponentProps {
  sound: SoundTherapy;
  enableControls?: boolean;
  onNextClick?: () => void;
  onPrevClick?: () => void;
  onEnd?: (decline: boolean, duration: number) => void;
  onStart?: () => void;
  title?: string;
  children?: any;
  background?: boolean;
}
const SoundPlayerComponent = (props: SoundPlayerComponentProps) => {
  const videoElement = useRef<HTMLVideoElement | null>(null);

  const onEndHandler = useCallback(() => {
    videoElement.current &&
      props.onEnd &&
      props.onEnd(false, videoElement.current?.currentTime);
  }, [props]);
  const onStartHandler = useCallback(() => {
    videoElement.current && props.onStart && props.onStart();
  }, [props]);

  useEffect(() => {
    const videoRef = videoElement.current;
    if (videoRef) {
      videoRef.addEventListener('playing', onStartHandler, false);
      videoRef.addEventListener('ended', onEndHandler, false);
      return () => {
        videoRef.removeEventListener('playing', onStartHandler, false);
        videoRef.removeEventListener('ended', onEndHandler, false);
      };
    }
  }, [props.sound, videoElement, onEndHandler, onStartHandler]);

  return (
    <>
      {props.sound && (
        <div className={`w-full ${props.children ? 'hidden' : ''}`}>
          <h3 className='text-lg md:text-2xl font-semibold pb-3'>
            {props.title || props.sound.name}
          </h3>
          <video
            ref={videoElement}
            className='w-full md:w-5/6'
            controls
            controlsList="nodownload"
            autoPlay
            poster={
              props.sound.video_path ??
              props.sound.image_path ??
              require('../../../../assets/common/placeholder.png')
            }
            key={props.sound.id}
          >
            <source src={props.sound.file_path} type={mediaService.getFileType(props.sound.file_path)} />
            {'Your browser does not support the video tag.'}
          </video>
        </div>
      )}
      {props.children}
    </>
  );
};

export default SoundPlayerComponent;
