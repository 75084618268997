import React, { useState } from 'react'

interface CheckboxComponentProps{
    textLabel: string;
    checkBoxName: string;
    textLink?: string;
    linkUrl?: string;
    checked: boolean;
    defaultChecked: boolean;
    disabled?: boolean;
    onClick: (currentStatus: boolean) => void;
}
const CheckboxComponent = (props: CheckboxComponentProps) => {
    const [isChecked, setIsChecked] = useState(props.defaultChecked);

    const onClick = () => {
        const currentStatus = !isChecked;
        setIsChecked(currentStatus);
        props.onClick(currentStatus);
    }

    return (
        <div className="flex items-center mb-2">
            <input
                className="w-4 h-4 text-darkBlue bg-gray-100 border-steel rounded"
                checked={isChecked}
                type="checkbox"
                onChange={onClick}
                disabled={props.disabled ?? false}
                aria-label={props.checkBoxName}
                id={`checkbox-${props.checkBoxName}`}
            />
            <label htmlFor={`checkbox-${props.checkBoxName}`} className="ml-2 text-sm">
                {props.textLabel}{' '}
                {props.textLink &&
                    <a href={props.linkUrl} className="text-darkBlue hover:underline" target='_blank'>
                        {props.textLink}
                    </a>
                }
            </label>.
        </div>
  )
}

export default CheckboxComponent
