export class Department {
  id!: string;
  company_id!: string;
  name!: string;
  description!: string;
  is_active!: boolean;
  created_at!: Date;
  updated_at?: Date;
  deleted_at?: Date;
  employee_count?: number;
  code?: string;

  constructor(props: any) {
    Object.assign(this, props);
    this.created_at = new Date(props.created_at);
    this.updated_at = new Date(props.updated_at);
    this.deleted_at = new Date(props.deleted_at);
  }
}

export class Employee {
  id?: string;
  company_id?: string;
  firstname?: string;
  lastname?: string;
  email?: string;
  created_at!: Date;
  updated_at?: Date;
  deleted_at?: Date;

  constructor(props: any) {
    Object.assign(this, props);
    this.created_at = new Date(props.created_at);
    this.updated_at = new Date(props.updated_at);
    this.deleted_at = new Date(props.deleted_at);
  }
}

export class DepartmentForm {
  name: string = '';
  description: string = '';
  is_active: boolean = true;
}

export class EmployeeForm {
  email: string = '';
  firstname: string = '';
  lastname: string = '';
  department_id: string = '';
  role: string = '';
}
