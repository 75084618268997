import React, {useEffect, useState} from 'react';
import {Program, ProgramFilter} from '../../models/Program';
import programService from '../../services/ProgramService';
import Img from '../../../../components/Img/Img';
import {useNavigate} from 'react-router-dom';
import {UserProgram} from '../../models/UserProgram';
import {promiseThenHelper} from '../../../common/helpers/promise.helper';
import errorService from '../../../common/services/error.service';
import translationService from '../../../translation/services/translation.service';
import {TranslationCategories} from '../../../common/models/TranslationCategories';
import LoadingComponent from '../../../common/components/LoadingComponent/LoadingComponent';

function ProgramsScreen() {
  const [programs, setPrograms] = useState<Program[]>([]);
  const [userPrograms, setUserPrograms] = useState<UserProgram[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  // Fetch programs
  useEffect(() => {
    const getAndSetPrograms = async () => {
      const fetchData = () => {
        return Promise.all([
          programService.getPrograms(new ProgramFilter()),
          programService.getUserPrograms({with_journey: true}, null),
        ]).then(...promiseThenHelper) as Promise<
          [[Program[], UserProgram[]], null] | [null, object]
        >;
      };
      const [fetchedData, fetchError] = await fetchData();
      if (fetchError) {
        errorService.addError(fetchError);
        return;
      }
      const [fetchedPrograms, fetchedUserPrograms] = fetchedData;
      setPrograms(fetchedPrograms);
      setUserPrograms(fetchedUserPrograms);
      setLoading(false);
    };

    getAndSetPrograms();
  }, []);

  const onClick = (programId: string) => {
    navigate(`/programs/${programId}`);
  };

  const programsUI = programs.map((p, index) => {
    // Stil TODO when it's clear how `UserProgram` works
    const userProgram = userPrograms.find(up => up.journey.id === p.id);

    const completedSessions =
      userProgram?.sessions.reduce(
        (acc, session) => acc + (session.isCompleted() ? 1 : 0),
        0,
      ) ?? 0;

    const progress = userProgram === null || userProgram === undefined ? 0 : (completedSessions / userProgram.sessions.length) * 100;
    const programCover = p.image_path || require('../../../../assets/common/placeholder.png');
    return (
      <div
        key={index}
        className='flex flex-col rounded-3xl max-w-xs bg-white shadow-xl scale-90 hover:scale-95 duration-300'
        onClick={() => onClick(p.id)}
      >
        <Img
          draggable={false}
          src={programCover}
          placeholder={programCover}
          alt="course"
          className='rounded-t-3xl h-64 object-cover'
        />
        <div className='px-3 py-2 flex flex-col justify-center w-full'>
          <div className='text-lg font-semibold'>{p.name}</div>
          <div className='w-full flex'>
            {!userProgram?.isCompleted() && (
              <div className={`absolute rounded-full h-1 bg-deepGreen-100 my-1`} style={{width: `${progress}%`}}/>
            )}
            <div className={userProgram?.isCompleted() ? 'rounded-full h-1 w-full bg-deepGreen-100 my-1': 'rounded-full h-1 w-full bg-midnight6 my-1'}/>
          </div>
          <p className={userProgram?.isCompleted() ? 'text-sm text-deepGreen-100 font-bold' : 'text-sm'}>
            {translationService.translate(
              TranslationCategories.Programs,
              '{progress}% Completed',
              {
                progress: progress.toFixed(0)
              }
            )}
          </p>
        </div>
      </div>
    );
  });

  return (loading ? (
    <div className='h-screen w-full flex justify-center'>
      <LoadingComponent text={''} fullscreen={false} smooth={true}/>
    </div>
  ) : (
    <div className='flex flex-col items-center lg:items-start pt-8 md:pt-12 px-2'>
      <h2 className='text-3xl font-semibold mb-5 md:mb-10'>
        {translationService.translate(
          TranslationCategories.Programs,
          programsUI ?
          'Programs available for me' : 'There is no available programs',
        )}
      </h2>
      <div className="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5">
        {programsUI}
      </div>
    </div>
  ));
}

export default ProgramsScreen;
