import {UserRole} from '../../employees/models/employee';

export class User {
  id?: string;
  email?: string;
  fullname?: string;
  is_guest?: boolean;
  is_news_subscribed?: boolean;
  is_onboarded?: boolean;
  is_premium?: boolean;
  is_terms_accepted?: boolean;
  preferences?: any;
  referral_code_id?: string;
  role!: UserRole;
  permissions!: Permissions[];
  company_id?: string;

  getHomeUrl() {
    if (this.role === UserRole.employee) {
      return '/programs';
    } else {
      return '/departments';
    }
  }

  constructor(props: any) {
    Object.assign(this, props);
  }
}

export enum Permissions {
  company_update_company = 'company_update_company',
  company_list_departments = 'company_list_departments',
  company_create_departments = 'company_create_departments',
  company_update_departments = 'company_update_departments',
  company_delete_departments = 'company_delete_departments',
  company_add_department_employee = 'company_add_department_employee',
  company_list_employees = 'company_list_employees',
  company_create_employees = 'company_create_employees',
  company_update_employees = 'company_update_employees',
  company_delete_employees = 'company_delete_employees',
  company_challenge_rewards_read = 'company_challenge_rewards_read',
  company_challenge_rewards_create = 'company_challenge_rewards_create',
  company_challenge_rewards_update = 'company_challenge_rewards_update',
  company_challenge_goals_read = 'company_challenge_goals_read',
  company_challenge_goals_create = 'company_challenge_goals_create',
  company_challenge_goals_update = 'company_challenge_goals_update',
  company_challenge_conditions_read = 'company_challenge_conditions_read',
  company_challenge_conditions_create = 'company_challenge_conditions_create',
  company_challenge_conditions_update = 'company_challenge_conditions_update',
  company_challenge_read = 'company_challenge_read',
  company_challenge_create = 'company_challenge_create',
  company_challenge_update = 'company_challenge_update',
  company_dashboard_read = 'company_dashboard_read',
}
