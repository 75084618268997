import {AuthProvider} from './AuthProvider';
import {SocialData} from './SocialData';

export class LoginForm {
  constructor(
    public provider: AuthProvider,
    public data: EmailLoginData | SocialData,
  ) {}
}

export class EmailLoginData {
  constructor(public email: string, public password: string) {}
}

export class LoginResponse {
  constructor(public name: string, public firstLogin: boolean) {}
}
