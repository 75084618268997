import React, {useCallback, useEffect, useState} from 'react';
import CreateDepartmentComponent from '../components/CreateDepartmentComponent';
import companyService from '../services/company.service';
import AddEmpoyeeComponent from '../components/AddEmployeeComponent';
import timeService from '../../common/services/time.service';
import LoadingComponent from '../../common/components/LoadingComponent/LoadingComponent';
import {Department, DepartmentForm} from '../models/Department';
import translationService from '../../translation/services/translation.service';
import {TranslationCategories} from '../../common/models/TranslationCategories';
import permissionService from '../../auth/services/permissions.service';
import {Permissions} from '../../auth/models/User';
import ToggleComponent from '../../common/components/ToggleComponent';
import PopupComponent from '../../common/components/PopupComponent/PopupComponent';
import EmailTemplateComponent from '../components/EmailTemplateComponent';
import StatsDataItemComponent from "../../programs/components/StatsDataPointComponent/StatsDataItemComponent";
import {Company} from "../models/Company";

const DepartmentsScreen = () => {
  const [company, setCompany] = useState<Company>();
  const [departments, setDepartments] = useState<Department[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedDepId, setSelectedDepId] = useState<string | null>(null);
  const [selectedDepartment, setSelectedDepartment] = useState<Department>();

  const deparmentPermissions = permissionService.checkUserPermission(
      Permissions.company_create_departments,
  );
  const createEmployeePermission = permissionService.checkUserPermission(
    Permissions.company_create_employees,
  );

  const loadDepartments = useCallback(() => {
    companyService.getDepartments().then(deps => {
      setDepartments(deps);
      setLoading(false);
      setSelectedDepId(null);
    });
  }, [loading]);

  useEffect(() => {
    companyService.getCompany().then(setCompany);
  }, []);

  useEffect(() => {
    loadDepartments();
  }, [loadDepartments]);

  const updateDepartment = (dep: Department) => {
    setSelectedDepId(dep.id);

    const depForm = new DepartmentForm();
    depForm.name = dep.name;
    depForm.description = dep.description;
    depForm.is_active = !dep.is_active;

    companyService.updateDepartment(dep.company_id, dep.id, depForm).then(deps => {
      loadDepartments();
    });
  }

  return (loading ? (
    <div className='h-screen w-full flex justify-center items-center'>
      <LoadingComponent
        text={''}
        fullscreen={true}
        smooth={true}
      />
    </div>
  ) : (
    <div className='flex flex-col items-center lg:items-start pt-8 md:pt-12 px-2 pb-8'>
      <h2 className='text-3xl font-semibold mb-5 md:mb-10'>
        {translationService.translate(
          TranslationCategories.Dashboard,
          'Department Manager'
        )}
      </h2>
      <div className='w-full flex flex-col items-center gap-4 md:flex-row md:items-start md:gap-16'>
        <div className='flex flex-col md:place-self-end md:order-2'>
          <div className={!deparmentPermissions ? 'hidden mb-4 w-full' : 'mb-4 w-full'}>
            <CreateDepartmentComponent onCreate={() => setLoading(true)} />
          </div>
          <div className={!createEmployeePermission ? 'hidden mb-4 w-full' : 'mb-4 w-full'}>
            {departments ? (
              <AddEmpoyeeComponent
                departments={departments}
                onInvite={() => setLoading(true)}
              />
            ) : (
              <p>
                {translationService.translate(
                  TranslationCategories.Dashboard,
                  'You need to set up a department before adding users.',
                )}
              </p>
            )}
          </div>
        </div>

        <div>
          <div className='flex flex-row overflow-hidden rounded-xl shadow-xl bg-white mb-5 p-3'>
            <div style={{flex: '1'}}>
              <StatsDataItemComponent
                value={company?.employee_count || 0}
                name={translationService.translate(TranslationCategories.Dashboard, "Invited")}
                description={translationService.translate(TranslationCategories.Dashboard, "Total invited people")} />
            </div>
            <div style={{flex: '1'}}>
              <StatsDataItemComponent
                value={company?.user_count || 0}
                name={translationService.translate(TranslationCategories.ProgramDashboard, "Registered")}
                description={translationService.translate(TranslationCategories.ProgramDashboard, "Total registered people")} />
            </div>
          </div>

          <div className='w-full max-w-[642px] max-h-72 bg-white p-3 md:p-6 rounded-xl shadow-xl md:order-1 overflow-auto'>
            <table className='w-full'>
              <thead className=''>
              <tr>
                <th className='text-left text-xl font-semibold tracking-wide pb-3'>
                  {translationService.translate(
                    TranslationCategories.Dashboard,
                    'Departments'
                  )}
                </th>
                <th/>
                <th/>
                <th/>
                <th className={!deparmentPermissions ? 'hidden' : ''}/>
              </tr>
              </thead>
              <tbody>
              {departments?.map((dep, idx) => (
                <tr className={idx%2 ===0 ? 'bg-white' : 'bg-deepGreen-100 bg-opacity-5'} key={dep.id}>
                  <td className='py-2 flex items-center whitespace-nowrap w-full'>
                    <div className='w-8 h-8 rounded-lg flex items-center justify-center bg-deepGreen-100'>
                      <p className='text-sm text-white'>{dep.name.slice(0,2)}</p>
                    </div>
                    <div className='pl-2'>
                      <p className='text-sm font-semibold'>{dep.name}</p>
                      <p className='text-xs text-steel'>
                        {translationService.translate(
                          TranslationCategories.Dashboard,
                          'Since {date}',
                          {
                            date: dep.created_at
                              ? timeService.formatDateToString(dep.created_at)
                              : 'undefined'
                          }
                        )}
                      </p>
                    </div>
                  </td>
                  <td className='pl-6 text-sm text-steel w-full'>
                    {translationService.translate(
                      TranslationCategories.Dashboard,
                      '{users} users',
                      {
                        users: dep.employee_count?.toString() || ''
                      }
                    )}
                  </td>
                  <td className='py-2 flex flex-col whitespace-nowrap w-full'>
                    <p className='text-sm font-semibold underline text-softBlue cursor-pointer' onClick={() => setSelectedDepartment(dep)}>Invitar con ref. code</p>
                    <p className='text-xs text-steel'>{dep.code}</p>
                  </td>
                  <td className={!deparmentPermissions ? 'hidden' : 'py-2 pl-6 w-full whitespace-nowrap cursor-pointer'}>
                    {
                      selectedDepId === dep.id ? (
                        <svg className="mr-3 h-5 w-5 animate-spin text-deepGreen-50" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                      ) : (
                        <ToggleComponent status={dep.is_active} onClick={(e) => updateDepartment(dep)}/>
                      )
                    }
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {selectedDepartment && selectedDepartment.code && (
        <PopupComponent
          header={'Email invitation template'}
          description={'Use this template to invite a list of users to the same department. The reference code that appears in the email links the user to this department.'}
          onClose={() => setSelectedDepartment(undefined)}>
            <EmailTemplateComponent
              refCode={selectedDepartment.code}
              company_id={selectedDepartment.company_id}
              department_id={selectedDepartment.id} />
        </PopupComponent>
      )}
    </div>
  ));
};

export default DepartmentsScreen;
