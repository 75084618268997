import React, {useState} from 'react';
import TextComponent from '../TextComponent';
import {Assessment, AssessmentQuestionTypes} from '../../models/Assessment';
import RadioComponent from '../RadioComponent/RadioComponent';
import {ActivityResultForm} from '../../models/ActivityResultForm';
import {ActivityComponentProps} from '../../models/Props';
import RadioButtonComponent from "../RadioButtonComponent/RadioButtonComponent";
import CheckboxComponent from "../CheckboxComponent/CheckboxComponent";

const AssessmentComponent = (props: ActivityComponentProps) => {
  const [stepIdx, setStepIdx] = useState(0);
  const [questionIdx, setQuestionIdx] = useState(0);
  const [answers, setAnswer] = useState({});

  const activity = props.activity as Assessment;
  const step = activity.steps[stepIdx];
  let InputComponent = TextComponent;
  if (step) {
    switch (step.questions[questionIdx].type) {
      case AssessmentQuestionTypes.Checkbox:
        InputComponent = CheckboxComponent;
        break;
      case AssessmentQuestionTypes.RadioButton:
        InputComponent = RadioButtonComponent;
        break;
      case AssessmentQuestionTypes.Radio:
        InputComponent = RadioComponent;
        break;
      default:
        InputComponent = TextComponent;
    }
  }

  const onAnswer = (question: number, answer: any) => {
    const currentAnswers = {...answers, [question]: answer};
    setAnswer(currentAnswers);
    if (step && questionIdx + 1 === step.questions.length) {
      if (stepIdx + 1 === activity.steps.length) {
        const form = ActivityResultForm.fromObject(currentAnswers);
        props.onEnd(form);
      } else {
        setQuestionIdx(0);
        setStepIdx(stepIdx + 1);
      }
    } else {
      setQuestionIdx(questionIdx + 1);
    }
  };

  return (
    <>
      {step && (
        <div className='flex flex-col justify-center lg:pr-8'>
          <div className='flex items-center justify-between pb-5'>
            <h2 className='text-lg md:text-2xl font-semibold'>
              {props.activity.name}
            </h2>
            <h3 className='text-lg md:text-2xl font-semibold'>
              {questionIdx + 1}/{step.questions.length}
            </h3>
          </div>
          <InputComponent
            style={{}}
            question={step.questions[questionIdx]}
            onAnswer={onAnswer}
          />
        </div>
      )}
    </>
  );
};

export default AssessmentComponent;
