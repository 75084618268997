const supportedLanguages = {
  es: {
    language: 'Spanish',
    prefix: 'es',
    flag: '🇪🇸',
  },
  en: {
    language: 'English',
    prefix: 'en',
    flag: '🇬🇧',
  },
};
export default supportedLanguages;
