import userStoreService from './user-store.service';

class PermissionsService {
  checkUserPermission(permission: string) {
    const user = userStoreService.getUser();
    const result = user?.permissions
      ? user.permissions.some(perm => perm === permission)
      : false;
    return result;
  }
}

const permissionService = new PermissionsService();
export default permissionService;
