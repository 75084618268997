import {CButton, CContainer, CImage} from '@coreui/react';
import React, {useState} from 'react';
import translationService from '../../../translation/services/translation.service';
import {TranslationCategories} from '../../models/TranslationCategories';

export interface InfoCarouselComponentProps {
  steps: {imagePath?: string; text: string}[];
  onEnd: () => void;
  endButtonLoading?: boolean;
}

interface SlicePaginationProps {
  steps: {imagePath?: string; text: string}[];
  currentStep: number;
}
const SlicePagination = (props: SlicePaginationProps) => {
  return (
    <div className='flex pb-2'>
      {props.steps.length >= 2 &&
        props.steps.map((step, id) => (
          <div
            key={id}
            className='h-2 w-2 rounded-full m-2'
            style={
              props.currentStep === id
                ? {backgroundColor: 'black'}
                : {backgroundColor: 'rgba(80, 76, 76, 0.263)'}
            }
          />
        ))}
    </div>
  );
};

const InfoCarouselComponent = (props: InfoCarouselComponentProps) => {
  const [step, setStep] = useState(0);

  const endExercise = () => {
    props.onEnd();
  };
  const onClickBack = () => {
    setStep(step - 1);
  };
  const onClickNext = () => {
    setStep(step + 1);
  };

  return (
    <div className='flex flex-col items-center'>
      <SlicePagination steps={props.steps} currentStep={step} />
      <img
        draggable={false}
        src={props.steps[step].imagePath}
        alt={'slide number ' + step}
        className=' w-full p-2 max-w-3xl'
      />
      <div className='w-full p-3'>
        <p className='text-sm md:text-lg'>
          {props.steps[step].text}
        </p>
      </div>
      <div
        className='w-full flex justify-evenly items-center py-3'>
        {step !== 0 && (
          <button
            className='px-5 py-2 rounded-xl text-deepGreen-100 border-2 border-deepGreen-100 font-semibold'
            type="button"
            onClick={onClickBack}>
            {translationService.translate(
              TranslationCategories.Sessions,
              'Back',
            )}
          </button>
        )}
        {step === props.steps.length - 1 ? (
          <button
            className='bg-deepGreen-100 px-5 py-2 rounded-xl text-white font-semibold'
            type="button"
            onClick={endExercise}>
            {translationService.translate(
              TranslationCategories.Sessions,
              'Finish reading',
            )}
          </button>
        ) : (
          <button
            className='bg-deepGreen-100 px-5 py-2 rounded-xl text-white font-semibold'
            type="button"
            onClick={onClickNext}>
            {translationService.translate(
              TranslationCategories.Sessions,
              'Next',
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default InfoCarouselComponent;
