import LoginScreen from '../screens/LoginScreen/LoginScreen';
import userStoreService from '../../auth/services/user-store.service';
import RegisterScreen from '../screens/RegisterScreen/RegisterScreen';
import LogoutComponent from '../components/LogoutComponent/LogoutComponent';
import RestorePasswordScreen from '../screens/RestorePasswordScreen/RestorePasswordScreen';
import ResetPasswordScreen from '../screens/ResetPasswordScreen/ResetPasswordScreen';

const routes = [
  {
    path: '/auth/reset',
    component: ResetPasswordScreen,
    redirect: '/auth/restore',
    authCheck: () => userStoreService.getUser() === null,
  },
  {
    path: '/auth/restore/:token',
    component: RestorePasswordScreen,
    redirect: '/auth/login',
    authCheck: () => userStoreService.getUser() === null,
  },
  {
    path: '/auth/login',
    component: LoginScreen,
    redirect: () => {
      return userStoreService.getUser() !== null ? userStoreService.getUser()?.getHomeUrl() : '/departments'
    },
    authCheck: () => userStoreService.getUser() === null,
  },
  {
    path: '/auth/register',
    component: RegisterScreen,
    redirect: () => {
      return userStoreService.getUser() !== null ? userStoreService.getUser()?.getHomeUrl() : '/departments'
    },
    authCheck: () => userStoreService.getUser() === null,
  },
  {
    path: '/auth/logout',
    component: LogoutComponent,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null,
  },
];

export default routes;
