import {Activity, ActivityType} from './Activity';
import { ExerciseType } from './Exercise';

export class AssessmentResultLevel {
  min!: number;
  max!: number;
  name!: string;
  description!: string;

  constructor(props: any) {
    Object.assign(this, props);
  }
}

export class AssessmentResult {
  value!: number;
  max!: number;
  levels!: AssessmentResultLevel[];
  description!: string;
  activity!: {
    name: string;
    description: string;
    id: string;
  };

  constructor(props: any) {
    Object.assign(this, props);
  }
}

export class ProfilingResult {
  activity!: Activity;
  results!: {
    name: string;
    value: number;
    max: number;
    levels: any;
    description: string;
    category: string;
  }[];
  constructor(props: any) {
    Object.assign(this, props);
  }
}

export class AssessResult {
  name!: string;
  value!: number;
  max!: number;
  levels!: any;
  description!: string;
  category!: string;

  constructor(props: any) {
    Object.assign(this, props);
  }
}

export enum CycleType {
  Focus = 'FOCUS',
  Distract = 'DISTRACT',
  Reset = 'RESET',
}

export class BreathingResult {
  start!: number;
  end!: number;
  cycle_type!: CycleType;

  constructor(props: any) {
    Object.assign(this, props);
  }

  getTime() {
    return this.end - this.start;
  }
}

export class ActivityResult {
  activity!: Activity;
  results!: AssessResult[] | BreathingResult[];
  constructor(props: any) {
    Object.assign(this, props);
    if (this.activity.type === ActivityType.Assessment) {
      this.results = props.results.map((res: any) => new AssessResult(res));
    } else if (this.activity.exercise_type === ExerciseType.Breathing) {
      this.results = props.results.map((res: any) => new BreathingResult(res));
    }
  }

  private calculateTime(result: BreathingResult[]) {
    return result.reduce((total, item) => {
      return total + item.getTime();
    }, 0);
  }
  get_total_time() {
    if (this.activity.exercise_type === ExerciseType.Breathing) {
      return this.calculateTime(this.results as BreathingResult[]);
    }
    return 0;
  }

  get_cycle_time(type: CycleType) {
    if (this.activity.exercise_type === ExerciseType.Breathing) {
      const res = (this.results as BreathingResult[]).filter(
        (result: BreathingResult) => result.cycle_type === type,
      ) as BreathingResult[];
      return this.calculateTime(res);
    }
    return 0;
  }
}
