import React, {useState} from 'react';

interface ImgProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  /**
   * URL of the image to load
   */
  src?: string;

  /**
   * URL of the image to load if the URL provided in src prop fails
   */
  placeholder?: string;
}

/**
 * Component wrapper to load images.
 * Allows to set a placeholder in case the specified image in the src prop fails to load.
 */
function Img(props: ImgProps) {
  const {src, placeholder, onError} = props;

  const [imgSource, setImgSource] = useState(src);

  const onImgError = () => {
    if (typeof placeholder === 'string') {
      setImgSource(placeholder);
    }
  };

  return (
    <img
      {...props}
      alt=""
      src={imgSource}
      onError={event => {
        onError?.(event);
        onImgError();
      }}
    />
  );
}

export default Img;
