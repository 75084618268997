import errorService from '../../services/error.service';
import {useEffect, useState} from 'react';
import {Error} from '../../models/error';
import PopupComponent from '../PopupComponent/PopupComponent';
import translationService from '../../../translation/services/translation.service';
import { TranslationCategories } from '../../models/TranslationCategories';

function ErrorComponent() {
  const [errors, setErrors] = useState<Error | null>(null);

  useEffect(() => {
    let timer: any | null = null;
    const key = '';
    const handler = (error: Error): void => {
      setErrors(error);
      timer = setTimeout(() => {
        setErrors(null);
      }, 5000);
    };
    errorService.onError(key, handler);
    return () => {
      errorService.removeOnError(key);
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  return errors ? (
    <PopupComponent
      header={translationService.translate(TranslationCategories.Errors, 'Information')}
      description={
        translationService.translate(TranslationCategories.Errors, errors.text)
      }
    />
  ) : null;
}

export default ErrorComponent;
