export enum TrackerEventType {
  RecommendationSelected = 'RecommendationSelected',
  SoundItemSelected = 'SoundItemSelected',
  SoundListFiltered = 'SoundListFiltered',
  ScreenView = 'screen_view',
  SoundStart = 'sound_start',
  SoundEnd = 'sound_end',
  RateSound = 'RateSound',
  EmoCheckup = 'EmoCheckup',
  SignUp = 'sign_up',
  Login = 'login',
  OnboardingComplete = 'OnboardingComplete',
  AssessmentStarted = 'AssessmentStarted',
  AssessmentEnd = 'AssessmentEnd',
  FeatureRequest = 'FeatureRequest',
  IntegrationEnabled = 'IntegrationEnabled',
  IntegrationDisabled = 'IntegrationDisabled',
  SubscriptionRequest = 'SubscriptionRequest',
  PlanItemSelected = 'PlanItemSelected',
  SessionStarted = 'SessionStarted',
  SessionEnded = 'SessionEnded',
  ActivityStart = 'ActivityStart',
  ActivityEnded = 'ActivityEnded',
  StreakStatsToggle = 'StreakStatsToggle',
  ReflectionStart = 'ReflectionStart',
  ReflectionRecorded = 'ReflectionRecorded',
  ResetRecord = 'ResetRecord',
  PlayRecord = 'PlayRecord',
  SaveRecord = 'SaveRecord',
  TutorialStarted = 'TutorialStarted',
  TutorialEnded = 'TutorialEnded',
  ProfilingStarted = 'ProfilingStarted',
  ProfilingEnded = 'ProfilingEnded',
  OnboardingStarted = 'OnboardingStarted',
  OnboardingEnded = 'OnboardingEnded',
  SetUserProperty = 'user_properties',
}
