import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import translationService from '../../../translation/services/translation.service';
import { TranslationCategories } from '../../models/TranslationCategories';
import userStoreService from '../../../auth/services/user-store.service';
import WeVoiceLogo from '../../../../assets/minimalist-logo-wevoice.png';
import MenuTabs from '../../../../routes/menuTabs';
import {ReactComponent as LogoutIcon} from '../../../../assets/iconset/logout-icon.svg';
import companyService from "../../../company/services/company.service";
import {Company} from "../../../company/models/Company";
import textService from '../../services/text.service';

const Sidebar = () => {
  const location = useLocation();
  const user = userStoreService.getUser();
  const name = user?.fullname ?? user?.email;
  const [company, setCompany] = useState<Company>();

  useEffect(() => {
    companyService.getCompany().then(setCompany);
  }, []);

  return (
    <div className='h-full pl-8 pr-10 pt-10 hidden lg:flex'>
      <div className='h-fit bg-white w-80 rounded-3xl p-10 flex flex-col shadow-xl justify-between'>
        <div className='w-full flex flex-col items-center justify-center'>
          <img src={company?.logo_path || WeVoiceLogo} className='w-40 h-w-40 mb-2'/>
          <p className='text-lg text-steel mb-1'>
            {translationService.translate(
              TranslationCategories.Menu,
              'Welcome back'
            )},
          </p>
          <p className='text-xl font-bold'>
            {name}.
          </p>
        </div>
        <div className='w-full flex flex-col items-center justify-center'>
          <p className='font-semibold text-6xl'>
            {company?.employee_count || 0}
          </p>
          <p className='text-lg text-steel mb-1'>
            {translationService.translate(
              TranslationCategories.Menu,
              'invited users'
            )}
          </p>
        </div>
        <div className='w-full'>
          {MenuTabs.filter(item => (item.permission())).map((nav, index) => {
            const Icon = nav.icon;
            return(
              <Link key={index} className={location.pathname.startsWith(nav.href) ? 'flex items-center p-4 bg-paleGrey rounded-2xl' : 'flex items-center p-4 hover:bg-background rounded-2xl'} to={nav.href}>
                <Icon width={20} height={20}/>
                <p className={'pl-2 font-semibold'}>
                    {translationService.translate(
                      TranslationCategories.Menu,
                      nav.ui
                    )}
                </p>
              </Link>
            )
          })}
        </div>
        <Link to={'/auth/logout'}>
        <div className='flex items-center justify-center p-4 hover:bg-background rounded-2xl cursor-pointer'>
            <p className={`font-semibold pr-4`}>
              {translationService.translate(
                TranslationCategories.Menu,
                  'Log out'
              )}
            </p>
            <LogoutIcon width={20} height={20}/>
        </div>
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
