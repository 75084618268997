import userStoreService from '../../auth/services/user-store.service';
import ProgramsScreen from '../screens/ProgramsScreen/ProgramsScreen';
import ProgramScreen from '../screens/ProgramScreen/ProgramScreen';
import SessionScreen from '../screens/SessionScreen/SessionScreen';
import ProgramsDashboardScreen from '../screens/ProgramsDashboardScreen/ProgramsDashboardScreen';

const routes = [
  {
    path: '/programs',
    component: ProgramsScreen,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null,
  },
  {
    path: '/programs/:id',
    component: ProgramScreen,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null,
  },
  {
    path: '/programs/session/:id',
    component: SessionScreen,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null,
  },
  {
    path: '/dashboard/programs',
    component: ProgramsDashboardScreen,
    redirect: '/',
    authCheck: () => userStoreService.getUser() !== null,
  },
];

export default routes;
