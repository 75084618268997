export interface StatsDataItemComponentProps {
  name: string;
  description: string;
  value: string | number;
  icon?: string;
}

const StatsDataItemComponent = (props: StatsDataItemComponentProps) => {
  return (
    <div className='bg-gainsboro-100 rounded-xl flex flex-col items-center w-[160px] h-[160px] m-2 p-2' data-testid={`stats-item-${props.name}`}>
      <h4 className='text-xl font-semibold m-2'>{props.name}</h4>
      <div className='text-center flex flex-1 items-center justify-center opacity-30'><p>{props.description}</p></div>
      <h5 className='text-xl font-semibold'>{props.value}</h5>
    </div>
  );
};

export default StatsDataItemComponent;
